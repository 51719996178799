import React from "react";
import {unsecuredCopyToClipboard} from "../../../services/utility";
import {contractReducer} from "../contract/ContractReducer";

const ContractColumn = ({contract, worker, setOverlayState, handleHide}) => {
    const isFired = worker.fired;

    if (contract) {
        const [contractTitle, contractDateStart, contractDateEnd, contractHireDate, aneksDateEnd, contractColor, aneksColor] = contractReducer(contract);

        return (<td>
            {contractDateStart && contractDateEnd && (<div style={{color: aneksColor}}
                                                           onClick={(event) => unsecuredCopyToClipboard(event, contractDateStart + " - " + contractDateEnd, setOverlayState, handleHide)}>
                {contractDateStart}{" - "}{aneksDateEnd ? aneksDateEnd : contractDateEnd}
            </div>)}

            {contractHireDate && (<span
                onClick={(event) => unsecuredCopyToClipboard(event, contractHireDate, setOverlayState, handleHide)}>
            {contractHireDate} {" / "}
            </span>)}

            {isFired && (<span
                className={"expired-color-text"}
                onClick={(event) => unsecuredCopyToClipboard(event, worker.firedDate, setOverlayState, handleHide)}>
                {worker.firedDate}
            </span>)}
        </td>);
    } else {
        return <td></td>
    }


};

export default ContractColumn;
