import React, {useRef, useState} from "react";
import {Table} from "react-bootstrap";
import WorkerRow from "./WorkerRow";
import {OverlayFun} from "../../Overlay";

export const WorkerTable = ({
                                workers, perPage, page, navigate, companySlug
                            }) => {

    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    return (<>
            <Table className={'workers'}>
                <thead>
                <tr>
                    <th></th>
                    <th>Imię Nazwisko</th>
                    <th>Pesel</th>
                    <th>Umowa o pracę</th>
                    <th>A1</th>
                    <th>Działania</th>
                </tr>
                </thead>
                <tbody>
                {workers.map((worker, index) => (<WorkerRow
                    key={worker.id}
                    worker={worker}
                    perPage={perPage}
                    currentPage={page}
                    navigate={navigate}
                    companySlug={companySlug}
                    setOverlayState={setOverlayState} handleHide={handleHide}
                />))}
                </tbody>
            </Table>

            <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
        </>

    )
}