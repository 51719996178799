import React, {useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import {checkDateExpiration, unsecuredCopyToClipboard} from "../../../services/utility";

const Visa = ({visa}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    const visaExpirationColor = checkDateExpiration(visa.visaEndDate);

    const renderVisaDocument = () => visa.visaNumber !== null && (<div className="worker-card visa-document">
        <label>Visa/Karta Pobytu</label>
        <span onClick={(event) => unsecuredCopyToClipboard(event, visa.visaNumber, setOverlayState, handleHide)}>
                    {visa.visaNumber}
                </span>
    </div>);

    const renderVisaDate = () => visa.visaStartDate !== null && visa.visaEndDate && (
        <div className="worker-card visa-date">
            <label>Okres ważności</label>
            <span
                style={{color: visaExpirationColor}}
                onClick={(event) => unsecuredCopyToClipboard(event, `${visa.visaStartDate} - ${visa.visaEndDate}`, setOverlayState, handleHide)}
            >
                    {`${visa.visaStartDate} - ${visa.visaEndDate}`}
                </span>
        </div>);

    return (<>
        <div className="visa-worker">
            {renderVisaDocument()}
            {renderVisaDate()}
        </div>
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </>);
};

export default Visa;
