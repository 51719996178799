import Col from "react-bootstrap/Col";
import {useParams} from "react-router-dom";
import React from "react";
import DocumentsList from "../../components/documents/DocumentsList";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import withAuth from "../../services/hooks/withAuth";


const DocumentsPage = () => {
    const {workerSlug} = useParams();

    let workerId = null;
    if (workerSlug) {
        workerId = workerSlug.split('-').pop();
    }

    return (
        <Col style={{paddingLeft: "65px", marginTop: "45px", marginBottom: "100px"}}>
            <DndProvider backend={HTML5Backend}>
                <DocumentsList workerId={workerId}/>
            </DndProvider>
        </Col>
    );
};

export default withAuth(DocumentsPage);