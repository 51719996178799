import React, {useEffect} from "react";
import {useDrag, useDrop} from "react-dnd";
import Config from "../../config";
import Col from "react-bootstrap/Col";
import DownloadButton from "../buttons/DownloadButton";
import Row from "react-bootstrap/Row";
import DocumentSquare from "../square/document/DocumentSquare";

const DocumentBlock = ({document, customFileName, editMode, index, arrangeMode, onDocumentDrop, setDraggingMode}) => {
    let isDragging = false;
    let isTargetDragging = false;
    let allowDrop = false;
    let draggedItem = null;

    const [{ isDragging: isDraggingSource }, drag] = useDrag(() => ({
        type: "DOCUMENT_BLOCK",
        item: { document, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    // Use useEffect to update setDraggingMode with isDragging state
    useEffect(() => {
        if (setDraggingMode) {
            setDraggingMode(isDraggingSource);
        }
    }, [isDraggingSource]);

    const [, drop] = useDrop(() => ({
        accept: "DOCUMENT_BLOCK",
        hover: (item, monitor) => {
            if (item.index !== index) {
                isDragging = true;
                draggedItem = item;
                isTargetDragging = monitor.isOver();

                const timer = setTimeout(() => {
                    if (isDragging && arrangeMode) {
                        allowDrop = true;
                    }
                }, 200);

                return () => clearTimeout(timer);
            }
        },
        canDrop: () => allowDrop, drop: () => {
            isDragging = false;
            allowDrop = false;

            if (draggedItem) {
                const hoveredDocumentId = document.id;
                onDocumentDrop(draggedItem.document.id, hoveredDocumentId);
            }
        },
    }));


    const style = {
        opacity: isTargetDragging || isDraggingSource ? 0.5 : 1, cursor: allowDrop ? "move" : "default",
    };

    return (<div
        ref={(node) => {
            drag(node);
            drop(node);
        }}
        className={"mb-4 mt-4"}
        style={style}
    >
        <Row className={"animate__animated animate__bounceIn"}>
            <DocumentSquare
                link={`${Config.URL.BASE}${document.linkToDownload}`}
                linkPreview={`${Config.URL.BASE}${document.linkToPreview}`}
                filename={document.filename}
                documentId={document.id}
                isVertical={document.vertical}
            />
            <Col>
                <Row>
                    <Col xs={12} md={12}>
                        <div className={"certification-text"}>
                            <div>
                                <h5>Nazwa</h5>
                                <p>{editMode ? customFileName : document.filename}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={5} md={5}>
                        <div className="certification-text">
                            <div>
                                <h5>Data dodania</h5>
                                <p>{document.dateAdding}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={7} md={7}>
                        <div className="certification-text">
                            <div>
                                <h5>Rozmiar</h5>
                                <p>{document.fileSize}</p>
                            </div>
                        </div>
                    </Col>
                    {!editMode && !arrangeMode && (<Col xs={12} md={12}>
                        <DownloadButton
                            link={`${Config.URL.BASE}${document.linkToDownload}`}
                            filename={document.filename}
                        />

                        <svg xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>
                            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130" id="check">
                                <polyline points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                            </symbol>
                        </svg>
                    </Col>)}
                </Row>
            </Col>
        </Row>
    </div>);
};

export default DocumentBlock;
