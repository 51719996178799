import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import DatePickerWithYear from "../../date/datePicker/DatePickerWithYear";
import DatePickerWithRange from "../../datePickerWithRange/DatePickerWithRange";
import {transformDate} from "../../../services/utility";

const ContractForm = ({worker, setWorker}) => {
    const [termless, setTermless] = useState(false);
    const [hireDate, setHireDate] = useState(null);
    const [contractTitle, setContractTitle] = useState(null);
    const [contractDateStart, setContractDateStart] = useState(null);
    const [contractDateEnd, setContractDateEnd] = useState(null);

    const updateWorkerContract = (contractData) => {
        setWorker({
            ...worker,
            contract: {
                ...worker.contract,
                ...contractData,
            },
        });
    };

    const handleTitleChange = (event) => {
        setContractTitle(event.target.value);
        updateWorkerContract({contractTitle: event.target.value});
    };

    const handleHireDateChange = (date) => {
        setHireDate(date);
        updateWorkerContract({hireDate: transformDate(date)});
    };

    const handleTermlessChange = (state) => {
        setTermless(state);
        updateWorkerContract({isTermless: state});
    };

    const handleContractDateStartChange = (date) => {
        setContractDateStart(date);
        updateWorkerContract({
            contractDateStart: transformDate(date),
            contractDateEnd: transformDate(contractDateEnd),
        });
    };

    const handleContractDateEndChange = (date) => {
        setContractDateEnd(date);
        updateWorkerContract({
            contractDateStart: transformDate(contractDateStart),
            contractDateEnd: transformDate(date),
        });
    };

    const handleContractRangeChange = (dateStart, dateEnd) => {
        setContractDateStart(dateStart);
        setContractDateEnd(dateEnd);
        updateWorkerContract({
            contractDateStart: transformDate(dateStart),
            contractDateEnd: transformDate(dateEnd),
        });
    };

    return (
        <>
            <Form.Group as={Col} md="4" controlId="formMotherFirstName">
                <Form.Label className="create-worker-label">Rodzaj umowy</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder={"Umowa o pracę / Umowa zlecenia"}
                    value={contractTitle}
                    onChange={handleTitleChange}
                />
                <Form.Control.Feedback type="invalid">
                    Please enter a valid first name.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="2" style={{width: "160px"}}>
                <Form.Label className={"create-worker-label"}>Data zawarcia</Form.Label>
                <DatePickerWithYear startDate={hireDate} setStartDate={handleHireDateChange}/>
            </Form.Group>
            <Form.Group as={Col} md={"4"}>
                <Form.Label className={"create-worker-label"}>Okres umowy</Form.Label>
                {termless ? (
                    <div style={{display: "flex", alignItems: "center"}}>
                        <DatePickerWithYear startDate={contractDateStart} setStartDate={handleContractDateStartChange}/>
                        -
                        <Form.Control
                            style={{textAlign: "center"}}
                            type="text"
                            className={"input-field"}
                            placeholder={"Nieokreślony"}
                            onChange={handleTitleChange}
                            disabled={true}
                        />
                    </div>

                ) : (
                    <DatePickerWithRange
                        startDate={contractDateStart}
                        setStartDate={handleContractDateStartChange}
                        endDate={contractDateEnd}
                        setEndDate={handleContractDateEndChange}
                        setRangeChange={handleContractRangeChange}
                    />
                )}
            </Form.Group>
            <Form.Group as={Col} md="2" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "35px",
                marginLeft: "-10px"
            }}>
                <div className={"checkbox-wrapper"}>
                    <div className="checkbox-wrapper-57">
                        <label className="container">
                            <input type="checkbox" checked={termless}
                                   onChange={(e) => handleTermlessChange(!termless)}/>
                            <div className="checkmark"></div>
                        </label>
                    </div>
                    <span className={termless ? "wrapper-active" : "wrapper-disabled"}>Nieokreślony</span>
                </div>
            </Form.Group>
        </>
    );
};

export default ContractForm;
