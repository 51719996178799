import React from 'react';

const Note = ({note}) => {
    return (<div className="note">
        <h4>{note.key}</h4>
        <div>
            {note.value.trim()}
        </div>
    </div>);
};

export default Note;
