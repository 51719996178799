import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import {distractRequestHeaders} from "../../../services/utility";
import Config from "../../../config";
import AuthService from "../../../services/auth.service";

const RemoveModal = ({componentId, workerId, item, show, success, setSuccess, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();

    useEffect(() => {

        const handleSuccess = async () => {
            if (success) {

                let requestPath = '';
                if ('certificate' in item) {
                    requestPath = `${Config.URL.BASE}/api/workers/certifications?certificateId=${componentId}&workerId=${workerId}&username=${currentUser.username}`;
                } else if ('vacation' in item) {
                    requestPath = `${Config.URL.BASE}/api/workers/vacations?vacationId=${componentId}&workerId=${workerId}&username=${currentUser.username}`;
                } else if ('document' in item) {
                    requestPath = `${Config.URL.BASE}/api/workers/documents?documentId=${componentId}&workerId=${workerId}`;
                } else if ('aneks' in item) {
                    requestPath = `${Config.URL.BASE}/api/workers/contracts/aneks?aneksId=${componentId}&workerId=${workerId}&username=${currentUser.username}`;
                }
                try {
                    await fetch(requestPath, {
                        method: 'DELETE', headers: distractRequestHeaders(currentUser),
                    });
                } catch (error) {
                    console.error(error);
                } finally {
                    handleClose();
                    window.location.reload()
                }
            }
        };

        handleSuccess();
    }, [success]);

    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Usunąć</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className={'modal-form-text'}>Czy na pewno chcesz usunąć ten element?</p>
            </Modal.Body>

            <Modal.Footer>
                <button className="button-reject" onClick={handleClose}>
                    Anuluj
                </button>
                <button className="button-submit glow-on-hover" onClick={() => setSuccess(true)}>
                    Usuń
                </button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default RemoveModal;
