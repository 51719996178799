import React from "react";
import {Form} from "react-bootstrap";
import {getSelectValueFromLocal, saveSelectValueToLocal} from "../../../services/SortingService";
import {ReactComponent as Global} from "../../../assets/svgs/global.svg";
import {handleFirstnameAndLastname} from "../../../services/SearchService";

export const WorkerSearchBar = ({
                                    isFullSearch,
                                    navigate,
                                    companySlug,
                                    setFullSearch,
                                    fired,
                                    firstnameAndLastname,
                                    setFirstnameAndLastname
                                }) => {
    const handleSelectChange = (event) => {
        const newSelectValue = event.target.value;
        saveSelectValueToLocal(companySlug, newSelectValue);

        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('page', 1);
        urlSearchParams.set('sortedBy', newSelectValue); // Update the sorting parameter
        navigate(`?${urlSearchParams.toString()}`);
    };

    const handleCheckboxChange = (event) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("page", 1);
        if (!event.target.checked) {
            urlSearchParams.delete("fired");
        } else {
            urlSearchParams.set("fired", event.target.checked);
        }

        navigate(`?${urlSearchParams.toString()}`);
    };


    const handleSwitchChange = () => {
        setFullSearch(prevState => !prevState)
    };

    return (<div className="search-bar py-2">
            {isFullSearch && <div className={"message_block animate__animated animate__bounceIn"}>
                <span style={{marginRight: "6px"}}><Global width={"26px"} height={"26px"}/></span> Global search
            </div>}

            <div className={"search-block"}>
                <div className="toggle-switch-container">
                    <input type="checkbox" id="switch1" checked={isFullSearch} onChange={handleSwitchChange}/>
                    <label htmlFor="switch1">Toggle global search</label>
                </div>
                <div>
                    <input className="search-input"
                           type="text"
                           name="username"
                           value={firstnameAndLastname}
                           onChange={event => handleFirstnameAndLastname(event, setFirstnameAndLastname, navigate)}
                           placeholder={isFullSearch ? "Wyszukaj wszystkie firmy 🔎" : "Znajdź pracownika 🚀"}
                           required>
                    </input>
                </div>
                <div className={"checkbox-wrapper"}>
                    <div className="checkbox-wrapper-57">
                        <label className="container">
                            <input type="checkbox"
                                   checked={fired}
                                   onChange={handleCheckboxChange}
                            />
                            <div className="checkmark"></div>
                        </label>
                    </div>
                    <span className={fired ? "wrapper-active" : "wrapper-disabled"}>Zwolnieni</span>
                </div>
            </div>
            <div className={"search-filter-options"}>
                <Form.Select onChange={handleSelectChange} value={getSelectValueFromLocal(companySlug)}
                             style={{width: "120px"}}>
                    <option value="">Default</option>
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="A-Z">A-Z</option>
                    <option value="Z-A">Z-A</option>
                    <option value="last_updated">Last Updated</option>
                </Form.Select>
            </div>
        </div>

    )
}