import React, {useEffect, useState} from 'react';
import {Col, Form, Modal, Row} from 'react-bootstrap';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../../../services/auth.service';
import Config from '../../../../config';
import {
    distractRequestGetOptions,
    distractRequestHeaders,
    stringToDate,
    transformDate,
} from '../../../../services/utility';
import '../style.css';
import DatePickerWithYear from '../../../date/datePicker/DatePickerWithYear';
import SquareWithTextCertification from "../../../square/SquareWithTextCertification";

const CertificationModal = ({componentId, workerId, show, success, setSuccess, handleClose}) => {
        setSuccess(false)
        const currentUser = AuthService.getCurrentUser();

        const [certificateDateStart, setCertificateDateStart] = useState(new Date());
        const [certificateDateEnd, setCertificateDateEnd] = useState(new Date());
        const [certificateNumber, setCertificateNumber] = useState('');
        const [isLastOne, setLastOne] = useState(false);
        const [validated, setValidated] = useState(false);
        const [isCheckingCertificate, setIsCheckingCertificate] = useState(false);

        useEffect(() => {
            const fetchData = async () => {
                if (componentId !== undefined) {
                    const response = await axios.get(`${Config.URL.BASE}/api/workers/certifications/${componentId}`, distractRequestGetOptions(currentUser));
                    const certificate = response.data;

                    setCertificateNumber(certificate.certificateNumber);
                    setCertificateDateStart(stringToDate(certificate.certificateDateStart));
                    setCertificateDateEnd(stringToDate(certificate.certificateDateEnd));
                    setLastOne(certificate.isLastOne);
                }
            };
            fetchData();
        }, []);

        const noSpaceBetweenRegex = /^(\S+)$/;

        useEffect(() => {
            const handleSuccess = async () => {
                if (success) {

                    const certificateDTO = {
                        "certificateDateStart": transformDate(certificateDateStart),
                        "certificateDateEnd": transformDate(certificateDateEnd),
                        "certificateNumber": certificateNumber,
                        "categoryLevel": "A1",
                        "isLastOne": isLastOne
                    }

                    if (componentId !== undefined) {
                        try {
                            await fetch(`${Config.URL.BASE}/api/workers/certifications?username=${currentUser.username}&certificateId=${componentId}&workerId=${workerId}`, {
                                method: 'PUT',
                                body: JSON.stringify(certificateDTO),
                                headers: distractRequestHeaders(currentUser),
                            })
                                .then(response => {
                                    if (response.ok) {
                                        handleClose();
                                        window.location.reload();
                                    } else {
                                        response.json().then(errorData => {
                                            if (errorData.message) {
                                                toast.error(errorData.message);
                                                handleClose();
                                            } else {
                                                toast.error('An error occurred. Please try again.');
                                            }
                                        });
                                    }
                                })
                                .catch(() => {
                                    toast.error('An error occurred. Please check your internet connection and try again.');
                                });

                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        try {
                            await fetch(`${Config.URL.BASE}/api/workers/certifications?username=${currentUser.username}&workerId=${workerId}`, {
                                method: 'POST',
                                body: JSON.stringify(certificateDTO),
                                headers: distractRequestHeaders(currentUser),
                            }).then(response => {
                                if (response.ok) {
                                    handleClose();
                                    window.location.reload();
                                } else {
                                    response.json().then(errorData => {
                                        if (errorData.message) {
                                            toast.error(errorData.message);
                                            handleClose();
                                            setSuccess(false)
                                        } else {
                                            toast.error('An error occurred. Please try again.');
                                            handleClose();
                                            setSuccess(false)
                                        }
                                    });
                                }
                            }).catch((error) => {
                                toast.error('An error occurred. Please check your internet connection and try again.');
                            });
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            };

            handleSuccess();
        }, [success]);

        const handleSubmit = async (event) => {
            event.preventDefault();
            event.stopPropagation();

            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                setValidated(true);
                return;
            }

            if (certificateDateStart > certificateDateEnd) {
                setSuccess(false);
                toast.error('Data rozpoczęcia nie może być późniejsza niż data zakończenia');
                return;
            }

            const minDate = new Date('1900-01-01');
            if (certificateDateStart < minDate || certificateDateEnd < minDate) {
                setSuccess(false);
                toast.error('Daty nie mogą być wcześniejsze niż 1900');
                return;
            }

            if (isCheckingCertificate) {
                return;
            }

            if (componentId === undefined) {
                setIsCheckingCertificate(true);
                try {
                    if (!noSpaceBetweenRegex.test(certificateNumber)) {
                        setSuccess(false);
                        toast.error('Brak spacji między literami i cyframi');
                    } else {
                        const response = await fetch(`${Config.URL.BASE}/api/workers/certifications/exists?certificateNumber=${certificateNumber}`, distractRequestGetOptions(currentUser));
                        if (response.ok) {
                            if (await response.json() === true) {
                                setSuccess(false);
                                toast.error('Numer certyfikatu już istnieje');
                            } else {

                                setSuccess(true);
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsCheckingCertificate(false);
                }
            } else {
                setSuccess(true);
            }
        };

        const handleCheckboxClick = () => {
            setLastOne(prevState => !prevState);
        };

        const differenceInDays = Math.floor((certificateDateEnd - new Date()) / (1000 * 60 * 60 * 24));

        return (<>
                <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
                    <Modal.Header closeButton style={{paddingRight: '22px'}}>
                        <Modal.Title className="modal-form-title"> {componentId ? "Zaktualizuj A1" : "Dodaj A1"}</Modal.Title>
                    </Modal.Header>

                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row style={{padding: '10px', marginLeft: '20px'}}>
                                <Col md="3" style={{width: '125px', marginRight: '35px', marginTop: '10px'}}>
                                    <SquareWithTextCertification
                                        differenceInDays={differenceInDays}
                                        text={"A1"}
                                        isLastOne={isLastOne}
                                    />
                                </Col>
                                <Col md="9">
                                    <Row>
                                        <Form.Group className="mb-4 mt-1" as={Col} md="6" controlId="formCompanyName">
                                            <Form.Label className="create-worker-label">Numer</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="235689745645"
                                                value={certificateNumber}
                                                className="input-field"
                                                onChange={(event) => setCertificateNumber(event.target.value)}
                                                disabled={!(componentId === undefined)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a 9-digit certificate number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" className="mt-1"
                                                    controlId="formCompanyName">
                                            <Form.Label className="create-worker-label">Ważność od:</Form.Label>
                                            <DatePickerWithYear startDate={certificateDateStart}
                                                                setStartDate={setCertificateDateStart}/>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" className="mt-1"
                                                    controlId="formCompanyName">
                                            <Form.Label className="create-worker-label">Ważność do:</Form.Label>
                                            <DatePickerWithYear startDate={certificateDateEnd}
                                                                setStartDate={setCertificateDateEnd}/>
                                        </Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            className={'search-checkbox'}
                                            style={{marginLeft: "11px"}}
                                            id="toggleLastOneCertification"
                                            label={<span
                                                className="search-checkbox-label">Skrócona</span>}
                                            checked={isLastOne}
                                            onChange={handleCheckboxClick}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="button-reject" onClick={handleClose}>
                                Zamknij
                            </button>
                            <button className="button-submit glow-on-hover" type="submit">
                                Zapisz
                            </button>
                        </Modal.Footer>
                    </Form>
                    <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false}/>
                </Modal>
            </>
        );
    }
;

export default CertificationModal;
