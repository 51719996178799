import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import {toast} from "react-toastify";
import {showToast, validatePesel} from "../../../services/utility";

const PeselForm = ({worker, setWorker}) => {
    const {peselNumber} = worker;
    const [toastId, setToastId] = useState(null);

    const setPeselNumber = (value) => {

        if (!/^\d+$/.test(value) && value.length > 0) {
            showToast("Pesel should contain only numbers", toastId, setToastId);

        }

        if (value.length === 11) {
            if (validatePesel(value.trim())) {
                toast.success("Pesel is valid");
            } else {
                showToast("Pesel in not valid", toastId, setToastId);
            }
        } else if (value.length > 11) {
            showToast("Pesel cannot exceed 11 chars", toastId, setToastId);
            return;
        }

        setWorker({
            ...worker,
            peselNumber: value.trim(),
        });
    };

    return (
        <Form.Group
            as={Col}
            md="5"
            className={'mt-4'}
            controlId="formCompanyName">
            <Form.Label className={"create-worker-label"}>Pesel</Form.Label>
            <Form.Control type="text"
                          placeholder="12345678903"
                          value={peselNumber}
                          className={"input-field"}
                          onChange={(event) => setPeselNumber(event.target.value)}/>
            <Form.Control.Feedback type="invalid">Please enter a passport number.</Form.Control.Feedback>
        </Form.Group>
    );
};

export default PeselForm;
