import React, {useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import './assets/css/contacts.css';
import {unsecuredCopyToClipboard} from "../../../services/utility";

const Contacts = ({contacts}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    return (<>
        {contacts.email && (<div className="worker-card relative-block">
            <label>Email</label>
            <span
                onClick={(event) => unsecuredCopyToClipboard(event, contacts.email, setOverlayState, handleHide)}>{contacts.email}</span>
        </div>)}
        <div className="contacts-phones">
            {contacts.phones.map((phone, index) => (<div key={index} className="worker-card contact-block">
                <label>Telefon</label>
                <span
                    onClick={(event) => unsecuredCopyToClipboard(event, phone, setOverlayState, handleHide)}>{phone}</span>
            </div>))}
        </div>
        {contacts.telegram && (<div className="worker-card relative-block">
            <label>Telegram</label>
            <span
                onClick={(event) => unsecuredCopyToClipboard(event, contacts.telegram, setOverlayState, handleHide)}>{contacts.telegram}</span>
        </div>)}
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </>);
};

export default Contacts;
