import React from "react";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import "./style.css";

function InputPhonesForm({values, setValues}) {

    function handleChange(index, event) {
        const newValues = [...values]; // Create a shallow copy of the values array
        newValues[index] = event.target.value; // Replace the value at the specified index with the phone number
        setValues(newValues);
    }

    function addInput() {
        const newValues = [...values, '']; // Add an empty string to the values array
        setValues(newValues);
    }

    function removeInput(index) {
        const newValues = values.filter((_, i) => i !== index);
        setValues(newValues);
    }

    return (
        <div>
            {values.map((value, index) => {
                const {number} = value;
                const displayIndex = index + 1;
                return (
                    <div className={"mb-3"} key={index}>
                        <Row>
                            <Form.Label className="create-worker-label">
                                Telefon {displayIndex}
                            </Form.Label>
                            <Form.Group as={Col} md="4" controlId="formCompanyName">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="+380 097 57 69 928"
                                    value={number}
                                    onChange={(event) => handleChange(index, event)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a passport number.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Col>
                                <button
                                    className={"remove-button-style"}
                                    type="button"
                                    onClick={() => removeInput(index)}
                                >
                                    -
                                </button>
                            </Col>
                        </Row>
                    </div>
                );
            })}
            <button className={"add-button-style mb-3"} type="button" onClick={addInput}>
                +
            </button>
        </div>
    );
}

export default InputPhonesForm;
