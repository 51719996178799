import React, {useEffect, useState} from 'react';
import AuthService from "../../services/auth.service";
import {distractRequestGetOptions} from "../../services/utility";
import Config from "../../config";
import "./assets/css/vacations.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VacationModal from "../forms/modals/strategies/VacationModal";
import DeletePlace from "../placesToDrag/DeletePlace";
import AddComponent from "../forms/modals/AddComponent";
import EditPlace from "../placesToDrag/EditPlace";
import VacationUpdateModal from "../forms/modals/strategies/VacationUpdateModal";
import {useNavigate} from "react-router-dom";
import DraggableVacationBlock from "./DraggableVacationBlock";
import NotFound from "../../pages/404page/NotFound";

function VacationsFilter({workerSlug, companySlug}) {
    const currentUser = AuthService.getCurrentUser();

    const urlParams = new URLSearchParams(window.location.search);
    const year = urlParams.get('year');

    let workerId = null;
    if (workerSlug) {
        workerId = workerSlug.split('-').pop();
    }


    const [loading, setLoading] = useState(false)
    const [vacations, setVacations] = useState([]);
    const [availableYears, setAvailableYears] = useState([]);
    const [currentYearOfFiltering, setCurrentYearOfFiltering] = useState(0);
    const navigate = useNavigate();
    const [is404, setIs404] = useState(false);


    useEffect(() => {
        setLoading(true)

        const fetchVacationsFiltered = async () => {
            const response = await fetch(`${Config.URL.BASE}/api/workers/vacations/filter?workerId=${workerId}&endYear=${year}`, distractRequestGetOptions(currentUser)).catch(v => {
                if (v.response.data.statusCode === 404) {
                    setIs404(true);
                }
            });

            const data = await response.json();

            setVacations(data.vacations);
            setCurrentYearOfFiltering(data.currentYearOfFiltering);
            setAvailableYears(data.availableEndYears);

            if (year && data.vacations.length === 0) {
                navigate(`/${companySlug}/workers/${workerSlug}/vacations`)
            }

            setLoading(false)
        }

        const fetchVacations = async () => {
            const response = await fetch(`${Config.URL.BASE}/api/workers/vacations/filter?workerId=${workerId}`, distractRequestGetOptions(currentUser)).catch(v => {
                if (v.response.data.statusCode === 404) {
                    setIs404(true);
                }
            });
            ;const data = await response.json();

            setVacations(data.vacations);

            setAvailableYears(data.availableEndYears);
            setCurrentYearOfFiltering(data.currentYearOfFiltering);
            setLoading(false)
        };

        if (year) {
            fetchVacationsFiltered();
        } else {
            fetchVacations();
        }


    }, [year]);

    const changeYearParam = (year) => {
        navigate(`/${companySlug}/workers/${workerSlug}/vacations?year=${year}`);
    }


    if (is404) {
        return <NotFound/>;
    }

    return (<div>
        {vacations.length === 0 && !loading &&
            <div className={"worker-empty-content animate__animated animate__fadeIn"}>
                <p>No vacations were found. Let's add a new one! ⛱️️</p>
                <div>
                    <AddComponent workerId={workerId} modal={VacationModal}/>
                </div>
            </div>}
        {vacations.length > 0 && !loading && <>
            <div className={"mb-5"} style={{marginLeft: "-12px"}}>
                <h3 className={"mb-4 worker-page-title"}>Urlopy</h3>
                {availableYears.map((year) => {
                    return <button
                        key={year}
                        onClick={() => changeYearParam(year)}
                        className={(' ') + (currentYearOfFiltering === year ? 'selected' : 'select-year-btn')}>{year}
                    </button>
                })}
            </div>
            <div className={"animate__animated animate__fadeIn"} style={{marginLeft: "-12px"}}>
                <Row>
                    {vacations.map((vacation) => <Col id={vacation.id} xs={4} md={4}>
                        <DraggableVacationBlock key={vacation.id} vacation={vacation}/>
                    </Col>)}
                    <Col xs={4} md={4} className="mb-3">
                        <div>
                            <AddComponent workerId={workerId} modal={VacationModal}/>
                        </div>
                    </Col>
                </Row>
                <Col className="mt-5" style={{display: "flex", gap: "15px"}}>
                    <div className={"mt-5"}>
                        <EditPlace modal={VacationUpdateModal} workerId={workerId}/>
                    </div>
                    <div className={"mt-5"}>
                        <DeletePlace workerId={workerId}/>
                    </div>
                </Col>
            </div>
        </>}
    </div>);
}

export default VacationsFilter;
