export const handleFirstnameAndLastname = (event, setFirstnameAndLastname, navigate) => {
    let firstnameAndLastname = event.target.value;
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("page", 1);
    if (firstnameAndLastname.trim().length === 0) {
        urlSearchParams.delete("filterValue");
        setFirstnameAndLastname('')
    } else {
        setFirstnameAndLastname(firstnameAndLastname)
        urlSearchParams.set("filterValue", event.target.value.trim());
    }

    navigate(`?${urlSearchParams.toString()}`);
};

export const handlePageChange = (page, navigate) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("page", page);
    navigate(`?${urlSearchParams.toString()}`);
};

export const handlePerPageChange = (event, navigate) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("page", 1);
    urlSearchParams.set("perPage", event.target.value);
    navigate(`?${urlSearchParams.toString()}`);
};