import React, {useEffect, useState} from "react";
import "./assets/css/company.css";
import Config from "../../config";
import {distractRequestGetOptions} from "../../services/utility";
import AuthService from "../../services/auth.service";

const CompanyWorkerDisplayBlock = ({company}) => {
    const currentUser = AuthService.getCurrentUser();

    const [loading, setLoading] = useState(true);
    const [logoData, setLogoData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                fetch(`${Config.URL.BASE}/api/companies/companyLogo/${company.id}`, distractRequestGetOptions(currentUser))
                    .then((response) => response.arrayBuffer())
                    .then((data) => setLogoData(data))
                    .catch((error) => console.error(error));
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        };

        fetchData();
    }, [company.slug]);

    const logoUrl = `data:image/png;base64,${btoa(new Uint8Array(logoData).reduce((data, byte) => data + String.fromCharCode(byte), ""))}`;

    const isLogoPresent = (logoData) => {
        return !(logoData === null || logoData.byteLength === 0);
    };

    return (!loading && (<div
        className="company-display active">
        <div className="company-container">
            <div className="company-logo-container">
                {isLogoPresent(logoData) ?
                    <img className="company-logo" src={logoUrl} alt={`Logo for ${company.companyName}`}/> :
                    <div className={'square-logo'}>
                        <p>{company.companyName.slice(0, 2)}</p>
                    </div>
                    // <div className="dummy-company-logo">{company.companyName.slice(0, 1)}</div>
                }
            </div>
            <div className="company-text-container">
                <p>{company.companyName}</p>
            </div>
        </div>
    </div>));
};

export default CompanyWorkerDisplayBlock;

