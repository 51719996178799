import {format, parse} from "date-fns";
import {toast} from "react-toastify";
import {Pagination} from "react-bootstrap";
import {PaginationFunction} from "../components/workerComponents/workersList/PaginationFunction";
import React from "react";
import {DateTime, Interval} from 'luxon';
import Config from "../config";

export function distractRequestGetOptions(currentUser) {
    currentUser.csrfToken = undefined;
    return {
        method: 'GET', headers: {
            'X-XSRF-TOKEN': currentUser.csrfToken,
            'Authorization': `Bearer ${currentUser.token}`
        },
    };
}

export function distractRequestGetPDFOptions(currentUser) {
    return {
        method: 'GET', headers: {
            'Content-Type': 'application/pdf',
            'X-XSRF-TOKEN': currentUser.csrfToken,
            'Authorization': `Bearer ${currentUser.token}`,
            'Cookie': `XSRF-TOKEN=${currentUser.csrfToken}`,
        }, redirect: 'follow',
    };
}

export function distractRequestHeadersForCompany(currentUser) {
    return {
        'X-XSRF-TOKEN': currentUser.csrfToken, 'Authorization': `Bearer ${currentUser.token}`,
    };
}

export const getPolishMonthName = (month) => {
    const polishMonths = [
        'Styczeń', // January
        'Luty',    // February
        'Marzec',  // March
        'Kwiecień',// April
        'Maj',     // May
        'Czerwiec',// June
        'Lipiec',  // July
        'Sierpień',// August
        'Wrzesień',// September
        'Październik', // October
        'Listopad',    // November
        'Grudzień'     // December
    ];

    // Ensure the month value is within a valid range (1-12)
    const index = Math.max(0, Math.min(11, month - 1));

    return polishMonths[index];
};


export function distractRequestHeaders(currentUser) {
    return {
        'X-XSRF-TOKEN': currentUser.csrfToken,
        'Authorization': `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json'
    };
}

export function formatBillNumber(inputValue) {
    // Remove any non-numeric characters
    const formattedValue = inputValue.replace(/\D/g, '');

    // Split the formattedValue into groups of 4 digits after the first two digits
    const firstTwoDigits = formattedValue.slice(0, 2);
    const remainingDigits = formattedValue.slice(2);
    const groups = [];

    for (let i = 0; i < remainingDigits.length; i += 4) {
        groups.push(remainingDigits.slice(i, i + 4));
    }

    // Join the groups with spaces, including the first two digits
    return `${firstTwoDigits} ${groups.join(' ')}`;
}
export function findValidCertificate(data) {
    let certifications = data.certifications;
    if (certifications) {
        let certification = certifications.find(cert => cert.stateOfCertification === "VALID")

        if (!certification) {
            certification = certifications.find(cert => cert.stateOfCertification === "EXPIRING_SOON");
        }

        if (!certification) {
            certification = (certifications.reduce((prev, current) => {
                const prevDiff = Math.abs(new Date(prev.certificateDateEnd) - new Date());
                const currentDiff = Math.abs(new Date(current.certificateDateEnd) - new Date());
                return prevDiff < currentDiff ? prev : current;
            }));
        }
        return certification;
    }
}

export function formatTimePeriod(datetimeString) {
    const datetime = DateTime.fromISO(datetimeString, { zone: 'Europe/Warsaw' });
    const currentTime = DateTime.now().setZone('Europe/Warsaw');

    const diff = Interval.fromDateTimes(datetime, currentTime);
    const minutes = diff.length('minutes');
    const hours = diff.length('hours');
    const days = diff.length('days');

    if (minutes < 60) {
        return `${minutes.toFixed(0)} mins ago`;
    } else if (hours < 24) {
        return `${hours.toFixed(0)} hours ago`;
    } else {
        return `${days.toFixed(0)} days ago`;
    }
}

export function formatDatetime(datetimeString) {
    const datetime = DateTime.fromISO(datetimeString, { zone: 'Europe/Warsaw' });
    const formattedDate = datetime.toFormat('dd.MM.yyyy/HH:mm');

    return formattedDate;
}

export function transformDate(date) {
    if (!date) {
        return "";
    }
    return format(date, "dd.MM.yyyy");
}

export function showToast(message, toastId, setToastId) {
    if (toastId) {
        toast.dismiss(toastId);
    }
    const id = toast.error(message);
    setToastId(id);
}

export function stringToDate(dateString) {
    if (dateString && dateString.length > 0) {
        const [day, month, year] = dateString.split(".");
        const formattedDateString = `${month}/${day}/${year}`;
        return new Date(formattedDateString);
    }
    return null;
}

export function getFlagEmoji(countryCode) {
    return [...countryCode.toUpperCase()].map(char => String.fromCodePoint(127397 + char.charCodeAt(0))).reduce((a, b) => `${a}${b}`);
}


export function validatePesel(pesel) {
    if (pesel.length !== 11) return false;

    const arr = pesel.split("");
    const multipliers = [1, 3, 7, 9];
    let sum = 0;

    for (let i = 0; i < arr.length - 1; i++) {
        sum += Number(arr[i]) * multipliers[i % 4];
    }

    const modulo = sum % 10;
    const lastD = Number(pesel[10]);

    return (modulo === 0 && lastD === 0) || lastD === 10 - modulo;
}

export function distractedPutWorkerBody(worker) {
    return JSON.stringify({
        "id": worker.id,
        "passport": {
            "id": worker.passport.id,
            "passportNumber": worker.passport.passportNumber,
            "passportStartDate": worker.passport.passportStartDate,
            "passportEndDate": worker.passport.passportEndDate,
            "countryId": worker.passport.country.id
        },
        "visa": {
            "id": worker.visa.id,
            "visaNumber": worker.visa.visaNumber,
            "visaStartDate": worker.visa.visaStartDate,
            "visaEndDate": worker.visa.visaEndDate
        },
        "peselNumber": worker.peselNumber,
        "personDetails": {
            "id": worker.personDetails.id,
            "firstName": worker.personDetails.firstName,
            "lastName": worker.personDetails.lastName,
            "speciality": worker.personDetails.speciality,
            "birthday": worker.personDetails.birthday,
            "countryId": worker.personDetails.country.id,
            "region": worker.personDetails.region,
            "city": worker.personDetails.city,
            "address": worker.personDetails.address,
            "motherFirstName": worker.personDetails.motherFirstName,
            "motherLastName": worker.personDetails.motherLastName,
            "fatherFirstName": worker.personDetails.fatherFirstName,
            "fatherLastName": worker.personDetails.fatherLastName
        },
        "bills": worker.bills,
        "phones": worker.phones,
        "email": worker.email,
        "telegram": worker.telegram,
        "certifications": worker.certifications,
        "vacations": worker.vacations,
        "insurance": {
            "id": worker.insurance.id,
            "ekuzDateEnd": worker.insurance.ekuzDateEnd,
            "cfrDateEnd": worker.insurance.cfrDateEnd,
            "badanieStart": worker.insurance.badanieStart,
            "badanieEnd": worker.insurance.badanieEnd
        },
        "contract": {
            "id": worker.contract.id,
            "hireDate": worker.contract.hireDate,
            "contractDateStart": worker.contract.contractDateStart,
            "contractDateEnd": worker.contract.contractDateEnd
        },
        "isFired": worker.isFired,
        "companyId": worker.companyId,
    });
}

export function printDateDuration(issueDate) {

    const expirationDate = stringToDate(issueDate); // construct a Date object from the matched date string
    const today = new Date(); // get today's date

    const daysDifference = Math.round((expirationDate - today) / (1000 * 60 * 60 * 24)); // calculate the difference in days
    if (daysDifference === 1) {
        return `1 day left`;
    } else if (daysDifference === -1) {
        return `1 day ago`;
    } else if (daysDifference > 0) {
        return `${daysDifference} days left`
    } else {
        return `${-daysDifference} days ago`;
    }
}

export function formatTimestamp(inputTimestamp) {
    // Split the inputTimestamp into date and time parts
    const [datePart, timePart] = inputTimestamp.split('T');

    // Extract the date and time components
    const [year, month, day] = datePart.split('-');
    const [time, timeZone] = timePart.split('+');

    // Split the time component into hours, minutes, and seconds
    const [hours, minutes, seconds] = time.split(':');

    // Create the formatted string
    const formattedString = `${day}.${month}.${year}/${hours}:${minutes}`;

    return formattedString;
}


export const getIsActive = (path, company) => {
    return path === `/${company.slug}` ? window.location.pathname === path : window.location.pathname.startsWith(path);
};

export const getLogoURL = (logoData) => {
    return `data:image/png;base64,${btoa(new Uint8Array(logoData).reduce((data, byte) => data + String.fromCharCode(byte), ""))}`;
}


export const isLogoPresent = (logoData) => {
    return !(logoData === null || logoData.byteLength === 0);
};

export const getPaginationFunction = (handlePageChange, currentPage, totalPages, perPage, handlePerPageChange, PAGE_SIZES, totalElements) => {
    return <PaginationFunction
        totalElements={totalElements}
        onClick={() => handlePageChange(1)}
        onClick1={() => handlePageChange(currentPage - 1)}
        currentPage={currentPage}
        totalPages={totalPages}
        callbackfn={(_, i) => (<Pagination.Item
            key={i}
            active={i + 1 === currentPage}
            onClick={() => handlePageChange(i + 1)}
        >
            {i + 1}
        </Pagination.Item>)}
        onClick2={() => handlePageChange(currentPage + 1)}
        onClick3={() => handlePageChange(totalPages)}
        value={perPage}
        onChange={handlePerPageChange}
        numbers={PAGE_SIZES}
        show={false}
        callbackfn1={(size) => (<option key={size} value={size}>
            {size}
        </option>)}
    />
}

export function checkAneksExpiration(endDate) {
    const expirationDate = parse(endDate, "dd.MM.yyyy", new Date());
    const today = new Date();
    const expirationTime = Date.UTC(expirationDate.getUTCFullYear(), expirationDate.getUTCMonth(), expirationDate.getUTCDate());
    const todayTime = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());

    if (expirationTime < todayTime) {
        return "#bd0404"; // Passport is expired
    } else {
        return "#2766c7"; // Passport is not expired and will not expire within 10 days
    }
}


export function checkDateExpiration(endDate) {
    const expirationDate = parse(endDate, "dd.MM.yyyy", new Date());
    const today = new Date();
    const expirationTime = Date.UTC(expirationDate.getUTCFullYear(), expirationDate.getUTCMonth(), expirationDate.getUTCDate());
    const todayTime = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
    const timeDiff = expirationTime - todayTime;
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (expirationTime < todayTime) {
        return "#bd0404";
    } else if (daysDiff <= 10) {
        return "#ec9c3d";
    } else {
        return "black";
    }
}

export const unsecuredCopyToClipboard = (event, text, setOverlayState, handleHide) => {
    setOverlayState({show: true, target: event.target});

    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
    handleHide();
}

export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};

export async function updateDocumentOrder(newOrderedDocuments, currentUser) {
    try {
        // Make an HTTP request to your backend API
        const response = await fetch(`${Config.URL.BASE}/api/workers/documents/update-documents-order`, {
            method: "POST", headers: distractRequestHeaders(currentUser), body: JSON.stringify(newOrderedDocuments), // Send the updated order data
        });
    } catch (error) {
        console.error("Error updating document order", error);
    }
}