import React from "react";
import {Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const UploadedDocument = ({ file, index, handleFileNameChange, fileNames, removeFile }) => {
    return (
        <div className={"mt-2 mb-2"} key={index} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div style={{ minWidth: "12px", fontSize: "18px", color: "#8a8a8a", marginTop: "4px" }}>
                {index + 1}
            </div>

            <Form.Group as={Col} md="5" controlId="fileNameInput">
                <Form.Control
                    type="text"
                    value={fileNames[file.name] || file.name}
                    onChange={(e) => {
                        const newName = e.target.value;
                        handleFileNameChange(file, newName);
                    }}
                    className={"input-field"}
                />
            </Form.Group>
            <div>
                <button
                    style={{ marginLeft: "0" }}
                    className="remove-button-style"
                    type="button"
                    onClick={() => removeFile(index)} // Pass the index to removeFile
                >
                    -
                </button>
            </div>
        </div>
    );
};

export default UploadedDocument;
