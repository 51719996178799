import React, {useEffect, useRef, useState} from "react";
import "./assets/css/company.css";
import {NavLink} from "react-router-dom";
import Config from "../../config";
import {distractRequestGetOptions, distractRequestHeadersForCompany} from "../../services/utility";
import AuthService from "../../services/auth.service";
import CustomContextMenu from "./CustomContextMenu";
import {toast} from "react-toastify";

const Company = ({company, handlerShowModal, handleOrderChange}) => {
    const currentUser = AuthService.getCurrentUser();

    const [loading, setLoading] = useState(true);
    const [logoData, setLogoData] = useState(null);
    const [activeContextMenu, setActiveContextMenu] = useState(false); // Update to a single active context menu
    const contextMenuRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                fetch(`${Config.URL.BASE}/api/companies/companyLogo/${company.id}`, distractRequestGetOptions(currentUser))
                    .then((response) => response.arrayBuffer())
                    .then((data) => setLogoData(data))
                    .catch((error) => console.error(error));
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        };

        fetchData();
    }, [company.slug]);

    const logoUrl = `data:image/png;base64,${btoa(new Uint8Array(logoData).reduce((data, byte) => data + String.fromCharCode(byte), ""))}`;

    const getIsActive = (path) => {
        return path === `/${company.slug}` ? window.location.pathname === path : window.location.pathname.startsWith(path);
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        setActiveContextMenu(true);
    };

    const handleEdit = () => {
        handlerShowModal(company.id);
        handleCloseContextMenu();
    };

    const handleCloseContextMenu = () => {
        setActiveContextMenu(false);
    };

    const handleUpOrDown = async (increment) => {
        const newOrderIndex = company.orderIndex + increment;

        try {
            const response = await fetch(`${Config.URL.BASE}/api/companies/order-index?companyId=${company.id}&orderIndex=${newOrderIndex}`, {
                method: "POST",
                headers: distractRequestHeadersForCompany(currentUser)
            });

            if (response.ok) {
                handleOrderChange();
                handleCloseContextMenu();
            } else {
                toast.error("There was an error.");
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        // Add a click event listener to close the context menu when clicked outside
        const handleOutsideClick = (e) => {
            if (activeContextMenu && contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
                handleCloseContextMenu();
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [activeContextMenu]);

    const isLogoPresent = (logoData) => {
        return !(logoData === null || logoData.byteLength === 0);
    };

    return (
        <div>
            {!loading && (
                <NavLink
                    exact
                    to={`/${company.slug}`}
                    className="company-menu-button animate__animated animate__bounceIn"
                    activeClassName="active"
                    isActive={() => getIsActive(`/${company.slug}`)}
                    onContextMenu={handleContextMenu}
                >
                    <div className="company-container">
                        <div className="company-logo-container">
                            {isLogoPresent(logoData) ? (
                                <img className="company-logo" src={logoUrl} alt={`Logo for ${company.companyName}`}/>
                            ) : (
                                <div className="square-logo">
                                    <p>{company.companyName.slice(0, 2)}</p>
                                </div>
                            )}
                        </div>
                        <div className="company-text-container">
                            <p>{company.companyName}</p>
                        </div>
                    </div>
                </NavLink>
            )}

            {activeContextMenu && (
                <CustomContextMenu
                    onEdit={handleEdit}
                    onUp={() => handleUpOrDown(-1)}
                    onDown={() => handleUpOrDown(1)}
                    onClose={handleCloseContextMenu}
                    ref={contextMenuRef}
                />
            )}
        </div>
    );
};

export default Company;
