import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import Container from "react-bootstrap/Container";

import './assets/css/navbar.css'
import {Col} from "react-bootstrap";
import {ReactComponent as Notification} from './assets/directnotification.svg';

const Navbar = () => {
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            setCurrentUser(user);
        }

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);

    const logOut = () => {
        AuthService.logout();
        setCurrentUser(undefined);
        window.location.href = '/login';
    };

    return (<Container style={{maxWidth: "1470px"}}>
        <nav className={'navigation'}>

            <Link to='/' style={{textDecoration: 'none', cursor: "pointer"}}>
                <Col style={{display: "flex", gap: "18px"}}>
                    <div className={'logo-nav no-select'}>
                        <div className={'square-logo nav-logo'}>
                            <p>CG</p>
                        </div>
                    </div>
                    <div className={'logo-title-nav no-select'}>
                        Callidus Group
                    </div>
                </Col></Link>


            {currentUser ? (<ul className="nav-links no-select">
                <nav className="menu circle" style={{textAlign: 'center'}}>
                    <li><Link className={'nav-link'} style={{marginRight: "-25px"}} to='/vacations'>Urlopy</Link></li>
                    <li><Link className={'nav-link'} to='/'>Pracownicy</Link></li>

                    <Link className={'nav-link'} to='/notifications'>
                        <Notification width={"28px"} height={"28px"}/>
                    </Link>

                    <li className="services" style={{zIndex: '100'}}>

                        <div className="circle-profile no-select">
                            <p className="circle-inner">{currentUser.username.slice(0, 2).toUpperCase()}</p>
                        </div>

                        <ul className="dropdown">
                            {currentUser.roles.includes("ROLE_ADMIN") ?
                                <li className=" nav-item mt-2"><Link className={'dropdown-link'}
                                                                     to='/users'>Users</Link></li> : ''}

                            <li className=" nav-item mt-2"><Link className={'dropdown-link'}
                                                                 to='/create-company'>Create Company</Link>
                            </li>

                            <li className=" nav-item"><Link onClick={logOut} to='/login'
                                                            style={{
                                                                color: 'red', textDecoration: 'none'
                                                            }}>LogOut</Link></li>
                        </ul>
                    </li>
                </nav>
            </ul>) : (<ul className="nav-links">
                <div className="menu">
                    <li><Link to={"/login"}>Login</Link></li>
                </div>
            </ul>)}
        </nav>
    </Container>);
}


export default Navbar;