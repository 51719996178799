export const reorderDocuments = (draggedDocumentId, hoverDocumentId, setDocuments) => {
    setDocuments((prevDocuments) => {
        // Create a copy of the previous state
        const updatedDocuments = [...prevDocuments];

        // Find the dragged document and hover document by their IDs
        const draggedDocument = updatedDocuments.find(
            (doc) => doc.id === draggedDocumentId
        );
        const hoverDocument = updatedDocuments.find(
            (doc) => doc.id === hoverDocumentId
        );

        // Ensure both documents are found
        if (!draggedDocument || !hoverDocument) {
            return prevDocuments; // Return the previous state if documents are not found
        }

        // Swap the documents
        const dragIndex = updatedDocuments.indexOf(draggedDocument);
        const hoverIndex = updatedDocuments.indexOf(hoverDocument);

        // Create a new array with the correct order of documents
        const newDocuments = [...updatedDocuments];
        newDocuments[dragIndex] = hoverDocument;
        newDocuments[hoverIndex] = draggedDocument;

        // Return the new state
        return newDocuments;
    });
};