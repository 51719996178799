import React, {useCallback, useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import './assets/css/bill.css';
import {unsecuredCopyToClipboard} from "../../../services/utility";

const Bill = ({bill}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = useCallback(() => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    }, []);

    return (<>
        <div className="worker-card rachunek-card">
            <label>Rachunek {bill.kindOfCurrency}</label>
            <span onClick={(event) => unsecuredCopyToClipboard(event, bill.number, setOverlayState, handleHide)}>
                    {bill.number}
                </span>
        </div>
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </>);
};

export default Bill;
