import React, {useEffect, useState} from 'react';
import {Form, Modal, Row} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../../../../services/auth.service';
import '../../style.css';
import {useDropzone} from 'react-dropzone';
import {ReactComponent as DocumentUpload} from '../../../../../assets/svgs/document-upload.svg';
import UploadedDocument from "./UploadedDocument";
import DocumentLoader from "../loader/DocumentLoader";
import Config from "../../../../../config";

const DocumentModal = ({workerId, show, setSuccess, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();
    const [files, setFiles] = useState([]); // Store multiple files in state
    const [loading, setLoading] = useState(false);
    const [fileNames, setFileNames] = useState({}); // Store edited file names

    useEffect(() => {
        const handleKeyboardEvent = (event) => {

            if (event.key === 'Enter') {
                // Submit the form when Enter is pressed
                if (!loading) {
                    handleSubmit(event);
                } else {
                    toast.info("Sorry, but you need to wait till every document will be uploaded")
                }

            } else if (event.key === 'Escape') {
                // Close the modal when Escape is pressed
                handleClose();
            }
        };

        // Attach the event listener when the modal is shown
        if (show) {
            window.addEventListener('keydown', handleKeyboardEvent);
        }

        // Clean up the event listener when the modal is hidden
        return () => {
            window.removeEventListener('keydown', handleKeyboardEvent);
        };
    }, [show, loading, handleClose, files, fileNames]);

    const onDrop = (acceptedFiles) => {
        // Combine the existing files with the newly acceptedFiles
        const updatedFiles = [...files, ...acceptedFiles];

        // Process the combined files
        setFiles(updatedFiles);
    };

    const handleFileNameChange = (file, newName) => {
        setFileNames((prevFileNames) => ({
            ...prevFileNames, [file.name]: newName,
        }));
    };


    const handleSubmit = async (event) => {

        event.preventDefault();
        event.stopPropagation();

        if (files.length !== 0) {
            setLoading(true)
            // Process and send each file separately
            for (let file of files) {
                try {
                    const formData = new FormData();
                    const newFileName = fileNames[file.name] || file.name; // Set the new file name

                    // Check if the new filename already contains the correct extension
                    const correctExtension = `.${file.type.split('/')[1]}`;
                    const hasCorrectExtension = newFileName.endsWith(correctExtension);

                    // Remove everything after the last dot to ensure correct extension
                    let finalFileName;
                    if (hasCorrectExtension) {
                        finalFileName = newFileName;
                    } else {
                        const lastDotIndex = newFileName.lastIndexOf('.');
                        if (lastDotIndex !== -1) {
                            finalFileName = newFileName.substring(0, lastDotIndex) + correctExtension;
                        } else {
                            finalFileName = newFileName + correctExtension;
                        }
                    }

                    // Create a new File object with the desired name and contents
                    const newFile = new File([file], finalFileName, {type: file.type});

                    // Append the newFile to the FormData
                    formData.append('file', newFile);
                    formData.append('workerId', workerId);

                    const headers = {
                        'X-XSRF-TOKEN': currentUser.csrfToken, Authorization: 'Bearer ' + currentUser.token,
                    };

                    const response = await fetch(`${Config.URL.BASE}/api/workers/documents`, {
                        method: 'POST', headers: headers, body: formData,
                    });

                    if (response.ok) {
                        toast.success(`Document ${newFileName} is added successfully!`, {autoClose: 2000});
                    } else {
                        const errorData = await response.json();
                        if (errorData.message) {
                            toast.error(errorData.message);
                        } else {
                            toast.error('An error occurred. Please try again.');
                        }
                    }
                } catch (error) {
                    toast.error('An error occurred. Please check your internet connection and try again.');
                }
            }

            setLoading(false)

            setFiles([]); // Clear the files after processing
            setFileNames({})
            setSuccess(true); // Set success to true when all files are processed
            handleClose();
            window.location.reload();
        } else {
            toast.info("Firstly, you need to add documents")
        }

    };

    // Use the useDropzone hook to enable drag and drop functionality
    const {getRootProps, getInputProps} = useDropzone({
        onDrop, accept: '.pdf, .png, .jpg', // Specify accepted file types
    });

    const removeFile = (fileIndexToRemove) => {
        const updatedFiles = files.filter((_, index) => index !== fileIndexToRemove);
        setFiles(updatedFiles);
    };


    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Dodaj dokument</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Row>
                        {loading && <div style={{height: "250px", marginLeft: "-20px"}} className={"empty-content"}>
                            <DocumentLoader/>
                        </div>}

                        {!loading && (<div className={"px-5 py-4"}>
                            {/* Dropzone for multiple files */}
                            <div
                                {...getRootProps()}
                                className={'edit-place mb-3'}
                                style={{paddingTop: '45px', paddingBottom: '40px', textAlign: 'center'}}
                            >
                                <input {...getInputProps()} />
                                <DocumentUpload width={'45px'} height={'45px'}/>
                                <p className={'mt-2'}>Przeciągnij i upuść tutaj pliki PDF, PNG lub JPG lub kliknij, aby
                                    wybrać pliki</p>
                            </div>

                            {/* Display the selected files */}
                            {files.length > 0 && (<div>
                                <h5 className={"mt-4 mb-3"}>Przesłane pliki {files.length}:</h5>
                                {files.map((file, index) => (<UploadedDocument
                                    file={file}
                                    index={index}
                                    handleFileNameChange={handleFileNameChange}
                                    fileNames={fileNames}
                                    removeFile={removeFile}
                                />))}
                            </div>)}

                        </div>)}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-reject" onClick={handleClose}>
                        Anuluj
                    </button>
                    <button className="button-submit glow-on-hover" type="submit">
                        Zapisz
                    </button>
                </Modal.Footer>
            </Form>
            <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false}/>
        </Modal>
    </>);
};

export default DocumentModal;
