import React, {useEffect, useState} from 'react';
import AuthService from '../auth.service';
import api from "../api";

const withAuth = (WrappedComponent) => {
    return (props) => {
        const [isLoading, setIsLoading] = useState(true);
        const [isAuthenticated, setIsAuthenticated] = useState(false);

        useEffect(() => {
            const checkAuthStatus = async () => {
                const currentUser = AuthService.getCurrentUser();

                if (currentUser) {
                    try {
                        const response = await api.get(`/users/check-user-exists/${currentUser.id}`);
                        if (response.data === true) {
                            setIsAuthenticated(true);
                        }
                    } catch (error) {
                        // Handle the error if needed
                    }
                }

                setIsLoading(false);
            };

            checkAuthStatus();
        }, []);


        if (isAuthenticated) {
            return <WrappedComponent {...props} />;
        } else if (!isLoading && !isAuthenticated) {
            AuthService.logout();
            window.location.href = "/login"
        }
    };
};

export default withAuth;
