import React from 'react';

const CertificateEdit = ({certificate}) => {
    if (!certificate) {
        return null; // Return null if certificate is null
    }

    return (
        <>
            <div className="contract-edit">
                <h4>A1</h4>
                <div>
                    <h5>Okres</h5>
                    <p>
                        {certificate.certificateDateStart + ' - ' + certificate.certificateDateEnd}
                    </p>
                </div>
                <div>
                    <h5>Numer</h5>
                    <p>{certificate.certificateNumber}</p>
                </div>
            </div>
        </>
    );
};

export default CertificateEdit;
