import React, {useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {Pagination} from 'react-bootstrap';
import './assets/css/workersList.css';
import './assets/css/switch-btn.css';
import './assets/css/check-box-btn.css';
import {useNavigate, useParams} from 'react-router-dom';
import {PaginationFunction} from './PaginationFunction';
import Config from '../../../config';
import AuthService from '../../../services/auth.service';
import {fetchAllWorkersAPI, fetchWorkersAPI} from "../../../services/api-calls";
import {distractRequestGetOptions} from "../../../services/utility";
import {WorkerSearchBar} from "./WorkerSearchBar";
import {getSelectValueFromLocal} from "../../../services/SortingService";
import {handlePageChange, handlePerPageChange} from "../../../services/SearchService";
import {WorkerTable} from "./WorkerTable";

const WorkersList = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);
    const [workers, setWorkers] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZES = [10, 20, 30, 45];
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(true);
    const [isOnlyFiredExist, setOnlyFiredExist] = useState(false);
    const [isFullSearch, setFullSearch] = useState(false);

    const filterValue = urlParams.get("filterValue");
    const [firstnameAndLastname, setFirstnameAndLastname] = useState('');

    const fired = urlParams.get("fired");

    const {companySlug} = useParams();
    const page = parseInt(urlParams.get("page"), 10) || 1;
    const sortedBy = urlParams.get("sortedBy");
    const perPage = parseInt(urlParams.get("perPage"), 10) || 10;

    const [prevCompanySlug, setPrevCompanySlug] = useState(companySlug);


    useEffect(() => {
        if (filterValue !== null && filterValue !== undefined) {
            setFirstnameAndLastname(filterValue)
        } else {
            setFirstnameAndLastname('')
        }
    }, [filterValue])


    useEffect(() => {
        setLoading(true);

        const fetchWorkersByCompanyId = async () => {
            let companyId;

            if (companySlug) {
                companyId = companySlug.split('-').pop();
                const selectValueFromLocal = getSelectValueFromLocal(companySlug);

                try {
                    const data = await fetchWorkersAPI(currentUser, companyId, page, perPage, filterValue, fired, selectValueFromLocal);
                    setTotalElements(data.totalElements);
                    setWorkers(data.content);
                    setTotalPages(data.totalPages);

                    const isEmpty = data.content.length === 0;
                    setIsCompanyEmpty(isEmpty);

                    if (isEmpty) {
                        const responseCompanySize = await axios.get(`${Config.URL.BASE}/api/companies/${companyId}`, distractRequestGetOptions(currentUser));
                        let isEmptyCompany = responseCompanySize.data.workersCount === 0;
                        setIsCompanyEmpty(isEmptyCompany);
                        setOnlyFiredExist(!isEmptyCompany);
                    } else {
                        setOnlyFiredExist(false);
                    }

                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            }
        };

        const fetchAllWorkers = async () => {
            if (companySlug) {
                const selectValueFromLocal = getSelectValueFromLocal(companySlug);
                try {
                    const data = await fetchAllWorkersAPI(currentUser, page, perPage, filterValue, fired, selectValueFromLocal);
                    setTotalElements(data.totalElements);
                    setWorkers(data.content);
                    setTotalPages(data.totalPages);

                    const isEmpty = data.content.length === 0;
                    setIsCompanyEmpty(isEmpty);

                    if (isEmpty) {
                        const responseCompanySize = await axios.get(`${Config.URL.BASE}/api/workers/total-workers`, distractRequestGetOptions(currentUser));
                        let isEmptyCompany = responseCompanySize.data === 0;
                        setIsCompanyEmpty(isEmptyCompany);
                        setOnlyFiredExist(!isEmptyCompany);
                    } else {
                        setOnlyFiredExist(false);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            }

        };

        if (isFullSearch && companySlug === prevCompanySlug) {
            // Set isFullSearch to false when companySlug changes
            fetchAllWorkers();
        } else {
            fetchWorkersByCompanyId();
            setFullSearch(false);
        }

        setPrevCompanySlug(companySlug)
    }, [page, perPage, firstnameAndLastname, isFullSearch, companySlug, fired, sortedBy]);

    const handleNavigationWrapper = (page) => {
        handlePageChange(page, navigate)
    }

    const handlePerPageChangeWrapper = (event) => {
        handlePerPageChange(event, navigate)
    }

    return (<>
        {(!isCompanyEmpty || filterValue) && (<div>
            <WorkerSearchBar
                isFullSearch={isFullSearch}
                navigate={navigate}
                companySlug={companySlug}
                setFullSearch={setFullSearch}
                fired={fired}
                setFirstnameAndLastname={setFirstnameAndLastname}
                firstnameAndLastname={firstnameAndLastname}
            />

            {isOnlyFiredExist &&
                <div style={{height: "200px"}} className={"empty-content animate__animated animate__fadeIn"}>
                    <p>Wygląda na to, że istnieje tylko zwolniony pracowniki 🥲</p>
                </div>}

            {!isOnlyFiredExist && <WorkerTable workers={workers} perPage={perPage} page={page} navigate={navigate}
                                               companySlug={companySlug}/>}

            <PaginationFunction
                totalElements={totalElements}
                onClick={handleNavigationWrapper}
                onClick1={() => handlePageChange(1, navigate)}
                onClick2={() => handlePageChange(page - 1, navigate)}
                currentPage={page}
                totalPages={totalPages}
                onClick3={() => handlePageChange(page + 1, navigate)}
                onClick4={() => handlePageChange(totalPages, navigate)}
                value={perPage}
                onChange={handlePerPageChangeWrapper}
                numbers={PAGE_SIZES}
                show={true}
                link={`/create-worker/${companySlug}`}
                callbackfn1={(size) => (<option key={size} value={size}>
                    {size}
                </option>)}
            />

        </div>)}
        {!loading && isCompanyEmpty && !filterValue && (
            <div className={"empty-content animate__animated animate__fadeIn"}>
                <p>It looks like there are no workers here 🫤</p>
                <button className="button-submit glow-on-hover mt-1"
                        onClick={() => window.location.href = `/create-worker/${companySlug}`}>
                    Create worker
                </button>
            </div>)}
    </>);
};

export default WorkersList;
