import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Companies from "../../components/companies/Companies";
import WorkersList from "../../components/workerComponents/workersList/WorkersList";
import AuthService from "../../services/auth.service";
import {distractRequestGetOptions} from "../../services/utility";
import NotFound from "../404page/NotFound";
import Config from "../../config";
import withAuth from "../../services/hooks/withAuth";

const Home = () => {
    const currentUser = AuthService.getCurrentUser();
    const [showAdminButton, setShowAdminButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState(null);
    const {companySlug} = useParams();

    let companyId = null;
    if (companySlug) {
        companyId = companySlug.split('-').pop();
    }

    const [is404, setIs404] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                setShowAdminButton(currentUser.roles.includes("ROLE_ADMIN"));
            }
        };

        const fetchCompanies = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Config.URL.BASE}/api/companies`, distractRequestGetOptions(currentUser));
                setCompanies(response.data);
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        };

        fetchUserData();
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (companies !== null && companyId) {
            setIs404(!companies.some((obj) => obj.id.toString() === companyId));
        }
    }, [companies, companySlug]);

    const navigate = useNavigate();

    const getInitialCompanySlug = () => {
        if (companies === null) {
            return null;
        }
        return companies?.length > 0 ? companies[0].slug : null;
    };

    const renderWorkers = () => {
        const initialCompanySlug = getInitialCompanySlug();

        if (companyId === null && initialCompanySlug) {
            navigate(`/${initialCompanySlug}`);
        } else if (!initialCompanySlug) {
            return <div className={"empty-content"}>
                <p>Oops, there are no companies. Let's try creating a new one 🌱</p>
                <button className="button-submit glow-on-hover mt-1"
                        onClick={() => window.location.href = `/create-company`}>
                    Create company
                </button>
            </div>;
        }

        const initialCompanyId = initialCompanySlug.split('-').pop();
        const selectedCompanyId = companyId || initialCompanyId;
        const companyIdExists = companies.some((company) => company.id == selectedCompanyId);

        return <WorkersList companySlug={companySlug}
                            companyId={companyIdExists ? selectedCompanyId : initialCompanyId}/>;
    };

    if (is404) {
        return <NotFound/>;
    }

    return (<Container className="mt-4" style={{maxWidth: "1470px"}}>
        <div style={{display: "flex", gap: "15px"}}>
            <div>
                <Companies showAdminButton={showAdminButton} companySlug={companySlug}/>
            </div>
            <div style={{width: "100%", marginBottom: '140px'}}>
                {!loading && renderWorkers()}
            </div>
        </div>
    </Container>);
};

export default withAuth(Home);
