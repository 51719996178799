import React, {useEffect, useRef, useState} from "react";
import './assets/css/notification.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ReactComponent as Expired} from './assets/svgs/EXPIRED.svg';
import {ReactComponent as Info} from './assets/svgs/INFO.svg';
import {ReactComponent as Warning} from './assets/svgs/WARNING.svg';
import CustomNotificationContextMenu from "./CustomNotificationContextMenu";
import AuthService from "../../services/auth.service";
import Config from "../../config";
import {printDateDuration} from "../../services/utility";
import {handleApiRequest} from "../../services/api-calls";

function Notification(props) {
    const {notification} = props;

    const currentUser = AuthService.getCurrentUser();
    const [activeContextMenus, setActiveContextMenus] = useState({});
    const contextMenuRef = useRef(null);

    const [isSeen, setSeen] = useState(props.isSeen);

    const handleContextMenu = (e) => {
        e.preventDefault();
        handleCloseContextMenu();
        setActiveContextMenus(prevContextMenus => ({
            ...prevContextMenus, [notification.id]: true,
        }));
    };

    const handleOutsideClick = (e) => {
        if ((e.button === 0 || e.button === undefined) && Object.values(activeContextMenus).some(isActive => isActive) && contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
            setActiveContextMenus(prevContextMenus => {
                const updatedContextMenus = {...prevContextMenus};
                Object.keys(updatedContextMenus).forEach(companyId => {
                    updatedContextMenus[companyId] = false;
                });
                return updatedContextMenus;
            });
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [activeContextMenus, isSeen]);

    const handleSeen = () => {
        handleApiRequest(`${Config.URL.BASE}/api/notifications/seen?userId=${currentUser.id}&notificationId=${notification.id}`, 'POST', currentUser);
        handleCloseContextMenu();
        setSeen(true)
    };

    const handleUnseen = () => {
        handleApiRequest(`${Config.URL.BASE}/api/notifications/unseen?userId=${currentUser.id}&notificationId=${notification.id}`, 'POST', currentUser);
        handleCloseContextMenu();
        setSeen(false)
    };

    const handleRemove = () => {
        handleApiRequest(`${Config.URL.BASE}/api/notifications?notificationId=${notification.id}`, 'DELETE');
        handleCloseContextMenu();
    };

    const handleCloseContextMenu = () => {
        setActiveContextMenus(prevContextMenus => ({
            ...prevContextMenus, [notification.id]: false,
        }));
    };

    notification.cause = notification.cause.replaceAll("_", " ").toUpperCase()

    return (<div className={isSeen ? 'seen' : 'unseen'}>
        <div style={{paddingRight: '20px'}}
             onContextMenu={handleContextMenu}>
            <Row onClick={handleSeen}>
                <Col xs={1} md={1} style={{marginRight: '0px', width: '90px'}}>
                    <div style={{padding: "13px"}}>
                        {notification.state === "EXPIRED" ? (
                            <Expired className={!isSeen ? '' : 'greyscale-svg'} width="60px"
                                     height="60px"/>) : notification.state === "WARNING" ? (
                            <Warning className={!isSeen ? '' : 'greyscale-svg'} width="60px" height="60px"/>) : (
                            <Info className={!isSeen ? '' : 'greyscale-svg'} width="60px" height="60px"/>)}
                    </div>
                </Col>
                <Col xs={8} md={8} className={"notification-message"}>
                    <div className={"notification-message-title"}>
                        {notification.state === "EXPIRED" ?
                            `${notification.cause} is expired` : (notification.state === "WARNING" ?
                                `${notification.cause} is about to expire` : `${notification.cause}`)}
                    </div>
                    <div className={"notification-message-subtitle"}>
                        <a href={`/${notification.companySlug}/workers/${notification.workerSlug}/info`}>{notification.message}</a>
                    </div>
                </Col>
                <Col xs={2} md={2} className={"notification-duration"}>
                    <div>
                        {printDateDuration(notification.issueDate)}
                    </div>
                </Col>
                <Col xs={1} md={1}>
                    <div className={"notification-time-sent"}>
                        {notification.sendTime}
                    </div>
                </Col>
            </Row>

        </div>
        {activeContextMenus[notification.id] && (<CustomNotificationContextMenu
            notificationId={notification.id}
            currentUserId={currentUser.id}
            currentUser={currentUser}
            onRemove={handleRemove}
            onUnseen={handleUnseen}
            isSeenAlready={isSeen}
            onClose={handleCloseContextMenu}
            ref={contextMenuRef}
        />)}
    </div>);
}

export default Notification;
