import api from "./api";
import TokenService from "./token.service";

const register = (username, email, password) => {
    return api.post("/auth/signup", {
        username,
        email,
        password
    });
};

const login = (username, password) => {
    return api
        .post("/auth/signin", {
            username,
            password
        })
        .then((response) => {
            if (response.data.token) {
                TokenService.setUser(response.data);
            }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("isAuthenticated")
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
};

export default AuthService;