import React, {useEffect, useState} from 'react';
import {checkDateExpiration, showToast} from '../../../../services/utility';

const VisaEdit = ({worker, setWorker, setIsValidToUpdate}) => {
    const visa = worker.visa || {};

    const [visaNumber, setVisaNumber] = useState(visa.visaNumber || '');
    const [visaStartDate, setVisaStartDate] = useState(visa.visaStartDate || '');
    const [visaEndDate, setVisaEndDate] = useState(visa.visaEndDate || '');
    const [toastId, setToastId] = useState(null);

    const [visaNumberValid, setVisaNumberValid] = useState(true);
    const [visaStartDateValid, setVisaStartDateValid] = useState(true);
    const [visaEndDateValid, setVisaEndDateValid] = useState(true);

    useEffect(() => {
        const dateFormatRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/; // Regular expression to match dd.MM.yyyy format

        let isAllFieldsValid = true;

        if (visaNumber.length > 64) {
            showToast('Visa number cannot exceed 64 characters', toastId, setToastId);
            setVisaNumberValid(false);
            isAllFieldsValid = false;
        } else {
            setVisaNumberValid(true);
        }

        let startDateToPut = visaStartDate;
        let endDateToPut = visaEndDate;
        if (visaStartDate && visaEndDate) {
            if (!dateFormatRegex.test(visaStartDate)) {
                showToast('Visa start date should be in dd.MM.yyyy format', toastId, setToastId);
                setVisaStartDateValid(false);
                isAllFieldsValid = false;
            } else {
                setVisaStartDateValid(true);
            }

            if (!dateFormatRegex.test(visaEndDate)) {
                showToast('Visa end date should be in dd.MM.yyyy format', toastId, setToastId);
                setVisaEndDateValid(false);
                isAllFieldsValid = false;
            } else {
                setVisaEndDateValid(true);
            }


            // Check if the start date is not ahead of the end date
            const startTimestamp = new Date(visaStartDate).getTime();
            const endTimestamp = new Date(visaEndDate).getTime();
            if (startTimestamp > endTimestamp) {
                showToast('Passport start date cannot be ahead of the end date', toastId, setToastId);
                setVisaStartDateValid(false);
                setVisaEndDateValid(false);
                isAllFieldsValid = false;
            }
        } else {
            startDateToPut = null;
            endDateToPut = null;
        }


        if (isAllFieldsValid) {
            setWorker({
                ...worker,
                visa: {
                    ...visa,
                    visaNumber: visaNumber.length === 0 ? null : visaNumber,
                    visaStartDate: startDateToPut,
                    visaEndDate: endDateToPut,
                },
            });
            setIsValidToUpdate(true);
        } else {
            setIsValidToUpdate(false);
        }
    }, [visaNumber, visaStartDate, visaEndDate]);

    const handleVisaNumberChange = (e) => {
        setVisaNumber(e.target.innerText.trim());
    };

    const handleVisaStartDateChange = (e) => {
        setVisaStartDate(e.target.innerText.trim());
    };

    const handleVisaEndDateChange = (e) => {
        setVisaEndDate(e.target.innerText.trim());
    };

    const visaExpirationColor = checkDateExpiration(visaEndDate);

    return (
        <>
            <div className="visa-worker">
                <div className={`worker-card visa-document`}>
                    <label>Visa/Karta Pobytu</label>
                    <div contentEditable={true} onBlur={handleVisaNumberChange}
                         className={!visaNumberValid ? 'invalid' : ''}>
                        {visaNumber}
                    </div>
                </div>
                <div className="worker-card visa-date">
                    <label>Okres ważności</label>
                    <span style={{color: visaExpirationColor}}>
                        <div
                            style={{width: '109px', display: 'inline-block', marginLeft: '3px'}}
                            className={!visaStartDateValid ? 'invalid' : ''}
                            contentEditable={true}
                            onBlur={handleVisaStartDateChange}
                            suppressContentEditableWarning={true}
                        >
                          {visaStartDate}
                        </div>
                        {' - '}
                        <div
                            style={{width: '109px', display: 'inline-block', marginLeft: '3px'}}
                            className={!visaEndDateValid ? 'invalid' : ''}
                            contentEditable={true}
                            onBlur={handleVisaEndDateChange}
                            suppressContentEditableWarning={true}>
                            {visaEndDate}
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
};

export default VisaEdit;
