import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Config from "../../../config";
import AuthService from "../../../services/auth.service";

const RemoveUserModal = ({componentId, show, success, setSuccess, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();

    useEffect(() => {
        const handleSuccess = async () => {
            if (currentUser.id === componentId) {
                handleClose();
                toast.error("Current user cannot remove itself")
            } else {
                if (success) {
                    const headers = {
                        'X-XSRF-TOKEN': currentUser.csrfToken,
                        Authorization: 'Bearer ' + currentUser.token,
                        'Content-Type': 'application/json',
                    };

                    try {
                        await fetch(`${Config.URL.BASE}/api/users/${componentId}`, {
                            method: 'DELETE', headers: headers,
                        });
                    } catch (error) {
                        console.error(error);
                    } finally {
                        handleClose();
                        window.location.href = '/users';
                    }
                }
            }
            ;
        }

        handleSuccess();
    }, [success]);

    const handleRemove = async () => {
        setSuccess(true);
    };

    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Usuń potwierdzenie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p style={{paddingLeft: '10px'}}>Czy na pewno chcesz usunąć ten element?</p>
            </Modal.Body>

            <Modal.Footer>
                <button className="button-reject" onClick={handleClose}>
                    Anulować
                </button>
                <button className="button-submit glow-on-hover" onClick={handleRemove}>
                    Usunąć
                </button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default RemoveUserModal;
