import React, {useEffect, useMemo, useState} from "react";
import AuthService from "../../services/auth.service";
import {Table} from "react-bootstrap";
import {PaginationFunction} from "../../components/workerComponents/workersList/PaginationFunction";
import User from "./User";
import {fetchUsers} from "../../services/api-calls";

const UserFilter = () => {
    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);

    const [users, setUsers] = useState([]);
    const [username, setUsername] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const PAGE_SIZES = [5, 10, 20, 30];

    useEffect(() => {
        setLoading(true);
        fetchUsers(perPage, page, username, currentUser)
            .then((data) => {
                setUsers(data.content);
                setTotalElements(data.totalElements);
                setTotalPages(data.totalPages);
            })
            .finally(() => setLoading(false));
    }, [page, perPage, username]);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        setPage(1);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerPageChange = (event) => {
        setPerPage(event.target.value);
        setPage(1);
    };

    return (<>
        <div className={'search animate__animated animate__fadeIn'}
             style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <label>
                    <div className="input-container">
                        <input
                            type="text"
                            id="passportNumber"
                            className={'search-input'}
                            placeholder="Znajdź pracownika"
                            value={username}
                            onChange={handleUsernameChange}
                        />
                    </div>
                </label>
            </div>
        </div>

        {!loading && (<>
            <Table className={"users"}>
                <thead>
                <tr>
                    <th></th>
                    <th>Username</th>
                    <th>Imię</th>
                    <th>Nazwisko</th>
                    <th>Rola</th>
                    <th>Czas autoryzacji</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user) => <User key={user.id} user={user}/>)}
                </tbody>
            </Table>

            <PaginationFunction
                totalElements={totalElements}
                onClick={handlePageChange}
                onClick1={() => handlePageChange(1)}
                onClick2={() => handlePageChange(page - 1)}
                currentPage={page}
                totalPages={totalPages}
                onClick3={() => handlePageChange(page + 1)}
                onClick4={() => handlePageChange(totalPages)}
                value={perPage}
                onChange={handlePerPageChange}
                numbers={PAGE_SIZES}
                link={"/register-user"}
                show={true}
                callbackfn1={(size) => (<option key={size} value={size}>
                    {size}
                </option>)}
            />

        </>)}
    </>);
};

export default UserFilter;
