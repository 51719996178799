import React, {useEffect, useState} from 'react';

const RelativesEdit = ({worker, setWorker, setIsValidToUpdate}) => {
    const [relatives, setRelatives] = useState({
        motherFirstName: worker.personDetails.motherFirstName,
        motherLastName: worker.personDetails.motherLastName,
        fatherFirstName: worker.personDetails.fatherFirstName,
        fatherLastName: worker.personDetails.fatherLastName,
    });

    const handleRelativeChange = (field) => (e) => {
        const updatedRelatives = {...relatives, [field]: e.target.innerText};
        setRelatives(updatedRelatives);
    };

    useEffect(() => {
        setWorker({
            ...worker, personDetails: {
                ...worker.personDetails,
                motherFirstName: relatives.motherFirstName,
                motherLastName: relatives.motherLastName,
                fatherFirstName: relatives.fatherFirstName,
                fatherLastName: relatives.fatherLastName,
            },
        });
        setIsValidToUpdate(true);
    }, [relatives]);

    return (<div className="passport-worker">
        <div className="worker-card contact-block">
            <label>Mama</label>
            <span style={{color: "#7e7d7d"}}>Imię:
                    <div
                        contentEditable={true}
                        onBlur={handleRelativeChange("motherFirstName")}
                        suppressContentEditableWarning={true}
                        style={{color: '#000'}}
                    >
                        {relatives.motherFirstName}
                    </div>
                </span>

            <span style={{color: "#7e7d7d"}}>Nazwisko:
                    <div
                        contentEditable={true}
                        onBlur={handleRelativeChange("motherLastName")}
                        suppressContentEditableWarning={true}
                        style={{color: '#000'}}
                    >
                        {relatives.motherLastName}
                    </div>
                </span>
        </div>
        <div className="worker-card contact-block">
            <label>Ojciec</label>
            <span style={{color: "#7e7d7d"}}>Imię:
                    <div
                        contentEditable={true}
                        onBlur={handleRelativeChange("fatherFirstName")}
                        suppressContentEditableWarning={true}
                        style={{color: '#000'}}
                    >
                        {relatives.fatherFirstName}
                    </div>
                </span>
            <span style={{color: "#7e7d7d"}}>Nazwisko:
                    <div
                        contentEditable={true}
                        onBlur={handleRelativeChange("fatherLastName")}
                        suppressContentEditableWarning={true}
                        style={{color: '#000'}}
                    >
                        {relatives.fatherLastName}
                    </div>
                </span>
        </div>
    </div>);
};

export default RelativesEdit;
