import React from 'react';
import '../../../assets/workerMenu.css';
import {NavLink, useNavigate, useParams} from 'react-router-dom';

import {ReactComponent as BackArrow} from '../../../assets/svgs/BackArrow.svg';

import {ReactComponent as PersonDisable} from '../../../assets/svgs/worker-menu/person/PersonDisable.svg';
import {ReactComponent as PersonActive} from '../../../assets/svgs/worker-menu/person/PersonActive.svg';
import {ReactComponent as VacationDisable} from '../../../assets/svgs/worker-menu/vacation/CalendarDisable.svg';
import {ReactComponent as VacationActive} from '../../../assets/svgs/worker-menu/vacation/CalendarActive.svg';
import {
    ReactComponent as CertificationDisable
} from '../../../assets/svgs/worker-menu/certification/CertificationDisable.svg';
import {
    ReactComponent as CertificationActive
} from '../../../assets/svgs/worker-menu/certification/CertificationActive.svg';
import {ReactComponent as DocumentDisable} from '../../../assets/svgs/worker-menu/document/DocumentDisable.svg';
import {ReactComponent as DocumentActive} from '../../../assets/svgs/worker-menu/document/DocumentActive.svg';
import {
    ReactComponent as NotificationDisable
} from '../../../assets/svgs/worker-menu/notification/notificationDisable.svg';
import {
    ReactComponent as NotificationActive
} from '../../../assets/svgs/worker-menu/notification/notificationActive.svg';
import {ReactComponent as AneksDisable} from '../../../assets/svgs/worker-menu/aneks/aneksDisable.svg';
import {ReactComponent as AneksActive} from '../../../assets/svgs/worker-menu/aneks/aneksActive.svg';

const WorkerMenu = () => {
    const {companySlug, workerSlug} = useParams();

    const decodeURL = (url) => {
        return decodeURIComponent(url);
    };

    const getIsActive = (path) => {
        const currentPath = decodeURL(window.location.pathname);
        const targetPath = decodeURL(path);
        return currentPath === targetPath;
    };

    const navigate = useNavigate();

    return (
        <div className="menu-bar">
            <NavLink
                exact
                to={`/${companySlug}`}
                className="back-arrow-button"
                activeClassName="active"
                isActive={() => getIsActive(`/${companySlug}/workers/${workerSlug}/info`)}
            >
                <BackArrow height="25px" width="25px"/>
            </NavLink>
            <NavLink
                exact
                to={`/${companySlug}/workers/${workerSlug}/info`}
                className="menu-button "
                activeClassName="active"
                isActive={() => getIsActive(`/${companySlug}/workers/${workerSlug}/info`)}
            >
                <span className="menu-button-icon">
                    {getIsActive(`/${companySlug}/workers/${workerSlug}/info`) ? (
                        <PersonActive height="25px" width="25px"/>
                    ) : (
                        <PersonDisable height="25px" width="25px"/>
                    )}
                </span>
                <span className="menu-button-text ">Dane osobowe</span>
            </NavLink>
            <NavLink
                exact
                to={`/${companySlug}/workers/${workerSlug}/vacations`}
                className="menu-button"
                activeClassName="active"
                isActive={() => getIsActive(`/${companySlug}/workers/${workerSlug}/vacations`)}
            >
                <span className="menu-button-icon">
                    {getIsActive(`/${companySlug}/workers/${workerSlug}/vacations`) ? (
                        <VacationActive height="28px" width="28px"/>
                    ) : (
                        <VacationDisable height="28px" width="28px"/>
                    )}
                </span>
                <span className="menu-button-text">Urlopy</span>
            </NavLink>
            <NavLink
                exact
                to={`/${companySlug}/workers/${workerSlug}/certifications`}
                className="menu-button"
                activeClassName="active"
                isActive={() => getIsActive(`/${companySlug}/workers/${workerSlug}/certifications`)}
            >
                <span className="menu-button-icon" style={{marginLeft: '1px'}}>
                    {getIsActive(`/${companySlug}/workers/${workerSlug}/certifications`) ? (
                        <CertificationActive height="26px" width="26px"/>
                    ) : (
                        <CertificationDisable height="26px" width="26px"/>
                    )}
                </span>
                <span className="menu-button-text">A1</span>
            </NavLink>
            <NavLink
                exact
                to={`/${companySlug}/workers/${workerSlug}/documents`}
                className="menu-button"
                activeClassName="active"
                isActive={() => getIsActive(`/${companySlug}/workers/${workerSlug}/documents`)}
            >
                <span className="menu-button-icon" style={{marginLeft: '3px'}}>
                    {getIsActive(`/${companySlug}/workers/${workerSlug}/documents`) ? (
                        <DocumentActive height="24px" width="24px"/>
                    ) : (
                        <DocumentDisable height="24px" width="24px"/>
                    )}
                </span>
                <span className="menu-button-text">Dokumenty</span>
            </NavLink>
            <NavLink
                exact
                to={`/${companySlug}/workers/${workerSlug}/notifications`}
                className="menu-button"
                activeClassName="active"
                isActive={() => getIsActive(`/${companySlug}/workers/${workerSlug}/notifications`)}
            >
                <span className="menu-button-icon" style={{marginLeft: '1px'}}>
                    {getIsActive(`/${companySlug}/workers/${workerSlug}/notifications`) ? (
                        <NotificationActive height="27px" width="27px"/>
                    ) : (
                        <NotificationDisable height="27px" width="27px"/>
                    )}
                </span>
                <span className="menu-button-text">Powiadomienia</span>
            </NavLink>
            <NavLink
                exact
                to={`/${companySlug}/workers/${workerSlug}/contracts`}
                className="menu-button"
                activeClassName="active"
                isActive={() => getIsActive(`/${companySlug}/workers/${workerSlug}/contracts`)}
            >
                <span className="menu-button-icon" style={{marginLeft: '1px'}}>
                    {getIsActive(`/${companySlug}/workers/${workerSlug}/contracts`) ? (
                        <AneksActive height="25px" width="25px"/>
                    ) : (
                        <AneksDisable height="25px" width="25px"/>
                    )}
                </span>
                <span className="menu-button-text">Aneksy</span>
            </NavLink>
        </div>
    );
};

export default WorkerMenu;

