import React from 'react';
import {checkAneksExpiration, checkDateExpiration, stringToDate} from "../../../services/utility";
import {contractReducer} from "./ContractReducer";

const ContractLivePreview = ({contract}) => {

    const [contractTitle,
        contractDateStart,
        contractDateEnd,
        contractHireDate,
        aneksDateEnd,
        contractColor,
        aneksColor] = contractReducer(contract);

    let aneksDateToDisplay = null;
    const expired = contract.expired;
    const termless = contract.isTermless;
    let color;

    if (expired || termless ) {
        // Filter aneks array to exclude last ones and those with end date after today
        const filteredAnekses = contract.anekses.filter(aneks => !aneks.lastOne && stringToDate(aneks.aneksEndDate) > new Date());

        if (filteredAnekses.length > 0) {
            // Find the minimum aneks by end date
            const minAneks = filteredAnekses.reduce((min, aneks) => (aneks.aneksEndDate < min.aneksEndDate ? aneks : min), filteredAnekses[0]);

            if (minAneks) {
                aneksDateToDisplay = minAneks.aneksEndDate;
                color = checkAneksExpiration(minAneks.aneksEndDate);
            }
        } else {
            const filteredLastAnekses = contract.anekses.filter(aneks => aneks.lastOne);
            if (filteredLastAnekses.length > 0) {
                aneksDateToDisplay = "nieokreślony";
                color = "#2766c7";
            } else {
                const minAneksInAll = contract.anekses.reduce((min, aneks) => {
                    if (!aneks.lastOne && aneks.aneksEndDate > new Date()) {
                        return (!min || aneks.aneksEndDate < min.aneksEndDate) ? aneks : min;
                    }
                    return min;
                }, null);

                if (minAneksInAll !== null) {
                    aneksDateToDisplay = minAneksInAll.aneksEndDate;
                    color = checkDateExpiration(minAneksInAll.aneksEndDate);
                }

            }
        }
    } else {
        color = checkDateExpiration(contractDateEnd);
    }


    return (<>
        {(contract.hireDate !== null || contractDateStart !== null || contractDateEnd !== null) &&
            <div className="contract">
                <h4>{contractTitle}</h4>
                {contract.hireDate && (<div>
                    <h5>Data zawarcia</h5>
                    <p>
                        {contract.hireDate}
                    </p>
                </div>)}
                {contractDateStart && contractDateEnd && (<div>
                    <h5>Okres:</h5>
                    <p style={{color: contractColor}}>
                        {contractDateStart + ' - ' + contractDateEnd}
                    </p>
                </div>)}
                {contract.anekses.length > 0 && <div>
                    <h5>Aneksy: {contract.anekses.length}</h5>
                    <p style={{color: color}}>{aneksDateToDisplay}</p>
                </div>}
            </div>}
    </>);
};

export default ContractLivePreview;
