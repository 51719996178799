import {checkAneksExpiration, checkDateExpiration} from "../../../services/utility";

export const contractReducer = (contract) => {
    let contractTitle = contract.contractTitle;
    if (contractTitle === '') {
        contractTitle = "Umowa o pracę"
    }

    let contractDateStart = contract.contractDateStart;
    let contractHireDate = contract.hireDate;
    let contractDateEnd = contract.contractDateEnd;
    let aneksDateEnd = null;

    const expired = contract.expired;
    const termless = contract.isTermless;

    let contractColor;
    if (!termless) {
        contractColor = checkDateExpiration(contractDateEnd);
    }

    let aneksColor = "";
    if (expired || termless) {
        if (termless) {
            contractDateEnd = "nieokreślony"
        }

        const currentAneks = contract.anekses.find((aneks) => contract.currentAneksId === aneks.id);

        if (currentAneks) {
            if (currentAneks.lastOne) {
                aneksDateEnd = "nieokreślony";
                aneksColor = "#2766c7";
            } else {
                aneksDateEnd = currentAneks.aneksEndDate;
                aneksColor = checkAneksExpiration(currentAneks.aneksEndDate);
            }
        } else if (!termless) {
            aneksColor = checkDateExpiration(contractDateEnd)
        }
    } else if (!termless) {
        aneksColor = checkDateExpiration(contractDateEnd)
    }

    return [
        contractTitle,
        contractDateStart,
        contractDateEnd,
        contractHireDate,
        aneksDateEnd,
        contractColor,
        aneksColor
    ];
};

