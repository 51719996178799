import React from 'react';
import './squareStyle.css'

const SquareWithTextDisabled = ({text}) => {

    return (<div
        style={{
            width: '125px',
            height: '125px',
            background: '#eaeaea',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transitionDuration: '0.3s'
        }}
    >
        <div style={{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: "20px",
            fontSize: "18px",
            color: "#c9c9c9"
        }}>
            {text}
        </div>
    </div>);
}

export default SquareWithTextDisabled;