import React, {useEffect} from 'react';
import AuthService from "./services/auth.service";

const InactivityLogout = ({children}) => {

    let inactivityTimer;

    function resetInactivityTimer() {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(logoutUser, 4 * 60 * 60 * 1000); // 4 hours in milliseconds
    }

    function logoutUser() {
        AuthService.logout();
        window.location.reload();
    }

    useEffect(() => {
        // Attach event listeners and start the inactivity timer
        document.addEventListener('mousemove', resetInactivityTimer);
        document.addEventListener('keydown', resetInactivityTimer);

        // Start the timer when the component mounts
        resetInactivityTimer();

        return () => {
            // Clean up event listeners when the component unmounts
            document.removeEventListener('mousemove', resetInactivityTimer);
            document.removeEventListener('keydown', resetInactivityTimer);

            // Clear the timer to avoid any lingering timers
            clearTimeout(inactivityTimer);
        };
    }, []);

    return <>{children}</>;
}

export default InactivityLogout;