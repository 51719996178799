import {Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import DatePickerWithYear from "../../date/datePicker/DatePickerWithYear";
import DatePickerWithRange from "../../datePickerWithRange/DatePickerWithRange";
import {transformDate} from "../../../services/utility";

const InsuranceForm = ({worker, setWorker}) => {
    const [ekuzDateEnd, setEkuzDateEnd] = useState(worker.insurance.ekuzDateEnd || null);
    const [ekuzNumber, setEkuzNumber] = useState(worker.insurance.ekuzNumber || null);
    const [cfrDateStart, setCfrDateStart] = useState(worker.insurance.cfrDateStart || null);
    const [cfrDateEnd, setCfrDateEnd] = useState(null);
    const [badanieStart, setBadanieStart] = useState(worker.insurance.badanieStart || null);
    const [badanieEnd, setBadanieEnd] = useState(worker.insurance.badanieEnd || null);

    useEffect(() => {
        if (cfrDateStart) {
            const startDate = new Date(cfrDateStart);
            const endDate = new Date(startDate);
            endDate.setFullYear(endDate.getFullYear() + 1);

            // Now, update the cfrDateEnd state with the new endDate
            setCfrDateEnd(transformDate(endDate));
            setWorker({
                ...worker, insurance: {
                    ...worker.insurance,
                    cfrDateEnd: transformDate(endDate)
                }
            })
        }
    }, [cfrDateStart]);

    const handleEkuzNumberChange = (event) => {
        setEkuzNumber(event.target.value)
        setWorker({
            ...worker, insurance: {
                ...worker.insurance,
                ekuzNumber: event.target.value
            }
        })
    };

    const handleEkuzDateEndChange = (date) => {
        setEkuzDateEnd(date)
        setWorker({
            ...worker, insurance: {
                ...worker.insurance,
                ekuzDateEnd: transformDate(date)
            }
        })
    };

    const handleCfrStartChange = (date) => {

        setCfrDateStart(date)
        setWorker({
            ...worker, insurance: {
                ...worker.insurance,
                cfrDateStart: transformDate(date)
            }
        })
    };

    const handleBadanieStartChange = (date) => {
        setBadanieStart(date)

        setWorker({
            ...worker,
            insurance: {
                ...worker.insurance,
                badanieStart: transformDate(date),
                badanieEnd: transformDate(badanieEnd)
            }
        });
    };

    const handleBadanieEndChange = (date) => {
        setBadanieEnd(date)
        setWorker({
            ...worker,
            insurance: {
                ...worker.insurance,
                badanieStart: transformDate(badanieStart),
                badanieEnd: transformDate(date)
            }
        });

    };

    const handleBadanieRangeChange = (dateStart, dateEnd) => {
        setBadanieStart(dateStart)
        setBadanieEnd(dateEnd)
        setWorker({
            ...worker,
            insurance: {
                ...worker.insurance,
                badanieStart: transformDate(dateStart),
                badanieEnd: transformDate(dateEnd)
            }
        });
    };

    return (
        <>
            <Form.Group as={Col} md="3" controlId="formMotherFirstName">
                <Form.Label className="create-worker-label">Ekuz number</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder={"131646986"}
                    value={ekuzNumber}
                    onChange={handleEkuzNumberChange}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="2" style={{width: "160px"}}>
                <Form.Label className={"create-worker-label"}>EKUZ</Form.Label>
                <DatePickerWithYear startDate={ekuzDateEnd} setStartDate={handleEkuzDateEndChange}/>
            </Form.Group>
            <Form.Group as={Col} md="2" style={{width: "160px"}}>
                <Form.Label className={"create-worker-label"}>CFR</Form.Label>
                <DatePickerWithYear startDate={cfrDateStart} setStartDate={handleCfrStartChange}/>
            </Form.Group>
            {cfrDateStart && <Form.Group as={Col} md="2"  className={"animate__animated animate__tada mb-3"} style={{width: "160px"}}>
                <Form.Label className={"create-worker-label"}>CFR Date End</Form.Label>
                <div className={"custom-datepicker"} style={{paddingTop: "10px", background: "#e5e5e5", border: "none"}}>
                    {cfrDateEnd}
                </div>
            </Form.Group>}

            <Form.Group as={Col} md="4">
                <Form.Label className={"create-worker-label"}>Badanie</Form.Label>
                <DatePickerWithRange
                    startDate={badanieStart}
                    setStartDate={handleBadanieStartChange}
                    endDate={badanieEnd}
                    setEndDate={handleBadanieEndChange}
                    setRangeChange={handleBadanieRangeChange}
                />
            </Form.Group>
        </>
    );
};

export default InsuranceForm;
