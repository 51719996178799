import React from "react";
import {Modal} from "react-bootstrap";

const ConfirmationModal = ({show, handleClose, handleConfirm, message}) => {
    return (
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Confirm action</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className={'modal-form-text'}>{message}</p>
            </Modal.Body>

            <Modal.Footer>
                <button className="button-reject" onClick={handleClose}>
                    Cancel
                </button>
                <button className="button-submit glow-on-hover" onClick={handleConfirm}>
                    Confirm
                </button>
            </Modal.Footer>
        </Modal>

    );
};

export default ConfirmationModal;
