import withAuth from "../../services/hooks/withAuth";
import AuthService from "../../services/auth.service";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import Config from "../../config";
import {distractRequestGetOptions} from "../../services/utility";
import {toast} from "react-toastify";
import NotFound from "../404page/NotFound";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Col from "react-bootstrap/Col";
import AddComponent from "../../components/forms/modals/AddComponent";
import DeletePlace from "../../components/placesToDrag/DeletePlace";
import Row from "react-bootstrap/Row";
import AneksBlock from "./AneksBlock";
import AneksModal from "../../components/forms/modals/strategies/AneksModal";

const Contracts = () => {
    const currentUser = AuthService.getCurrentUser();

    const [contract, setContract] = useState(null);
    const [loading, setLoading] = useState(false)
    const [is404, setIs404] = useState(false);
    const {workerSlug, companySlug} = useParams();

    let workerId = null;
    if (workerSlug) {
        workerId = workerSlug.split('-').pop();
    }

    useEffect(() => {
        setLoading(true)
        const fetchCertifications = async () => {
            try {
                const response = await axios.get(`${Config.URL.BASE}/api/workers/contracts/${workerId}`, distractRequestGetOptions(currentUser)).catch(v => {
                    if (v.response.data.statusCode === 404) {
                        setIs404(true);
                    }
                });
                setContract(response.data);
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoading(false)
            }
        };

        fetchCertifications();
    }, [workerId]);


    if (is404) {
        return <NotFound/>;
    }

    return (<DndProvider backend={HTML5Backend}>
        <Col style={{paddingLeft: "65px", marginTop: "45px", marginBottom: "100px"}}>
            {contract === '' && <div className={"worker-empty-content animate__animated animate__fadeIn"}>
                <p>It looks like you don't have a Umowa o pracę for this worker, you need to implement one first! 👀️</p>
                <button className="button-submit glow-on-hover mt-2"
                        onClick={() => window.location.href = `/${companySlug}/workers/${workerSlug}/info/true`}>
                    Go to edit worker
                </button>
            </div>}
            {contract !== null && contract.anekses !== undefined && contract.anekses.length === 0 && !loading &&
                <div className={"worker-empty-content animate__animated animate__fadeIn"}>
                    <p>No existing anekses were found. Let's introduce a new one! 🧠️</p>
                    <div>
                        <AddComponent workerId={workerId} modal={AneksModal}/>
                    </div>
                </div>}
            {contract !== null && contract.anekses !== undefined && contract.anekses.length > 0 && !loading && <>
                <h3 className="mb-4 worker-page-title">
                    Aneksy
                </h3>
                <Row
                    className="">
                    {contract.anekses.map((aneks) => (<Col xs={4} md={4} className="mb-3" key={aneks.id}>
                        <AneksBlock aneks={aneks}/>
                    </Col>))}
                    <Col xs={4} md={4} className="mb-3">
                        <div>
                            <AddComponent workerId={workerId} modal={AneksModal}/>
                        </div>
                    </Col>
                </Row>

                <Col className="mt-5">
                    <div>
                        <DeletePlace workerId={workerId}/>
                    </div>
                </Col>
            </>}
        </Col>
    </DndProvider>);
};

export default withAuth(Contracts);
