import React, {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import {useNavigate} from "react-router-dom";
import {Col, Form, Row,} from "react-bootstrap";
import {scrollToTop, updateDocumentOrder} from "../../services/utility";
import AddComponent from "../forms/modals/AddComponent";
import DocumentModal from "../forms/modals/strategies/documents/DocumentModal";
import DocumentUpdateModal from "../forms/modals/strategies/documents/DocumentUpdateModal";
import DocumentLoader from "../forms/modals/strategies/loader/DocumentLoader";
import DocumentBlock from "./DocumentBlock";
import EditPlace from "../placesToDrag/EditPlace";
import DeletePlace from "../placesToDrag/DeletePlace";
import {ReactComponent as Arrange} from "../../assets/svgs/arrangeElements.svg";
import "./css/documents-list.css";
import {fetchDocumentsByWorkerIdAPI} from "../../services/api-calls";
import {reorderDocuments} from "./DocumentUtility";

const DocumentsList = ({workerId}) => {
    const currentUser = AuthService.getCurrentUser();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const filterValue = urlParams.get("filterValue");
    const sortedBy = urlParams.get("sortedBy");

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [arrangeMode, setArrangeMode] = useState(false);
    const [filename, setFilename] = useState(filterValue);
    const [draggingMode, setDraggingMode] = useState(false);

    useEffect(() => {
        if (!arrangeMode) {
            setLoading(true);
            fetchDocumentsByWorkerIdAPI(currentUser, workerId, filterValue, sortedBy)
                .then((data) => {
                    setDocuments(data);
                })
                .finally(() => setLoading(false));
        }
    }, [filename, sortedBy, workerId, arrangeMode]);


    const handleArrangeModeClick = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        setLoading(true);
        fetchDocumentsByWorkerIdAPI(currentUser, workerId, filterValue, "")
            .then((data) => {
                setDocuments(data);
            })
            .finally(() => setLoading(false));

        urlSearchParams.delete("sortedBy");
        setFilename("");
        scrollToTop();
        setArrangeMode(true);
        navigate(`?${urlSearchParams.toString()}`);
    };

    const handleFileNameChange = (event) => {
        let newFilename = event.target.value;
        const urlSearchParams = new URLSearchParams(window.location.search);

        if (newFilename.trim().length === 0) {
            urlSearchParams.delete("filterValue");
            setFilename("");
        } else {
            setFilename(newFilename);
            urlSearchParams.set("filterValue", newFilename.trim());
        }

        navigate(`?${urlSearchParams.toString()}`);
    };

    const handleSelectChange = (event) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (event.target.value === "") {
            urlSearchParams.delete("sortedBy");
        } else {
            urlSearchParams.set("sortedBy", event.target.value);
        }

        navigate(`?${urlSearchParams.toString()}`);
    };

    const handleDocumentReorder = (draggedDocumentId, hoverDocumentId) => {
        reorderDocuments(draggedDocumentId, hoverDocumentId, setDocuments)
    }


    return (<div style={{marginLeft: "-12px"}}>

        <h3 className={"mb-4 worker-page-title"}>Dokumenty</h3>
        <div className={"search-bar"}>
            <div className={"search-block"}>
                <div className="input-container">
                    <input
                        type="text"
                        id="setFirstnameAndLastname"
                        className={"search-input"}
                        placeholder="Znajdź dokument"
                        value={filename}
                        onChange={handleFileNameChange}
                        disabled={arrangeMode}
                    />
                </div>
            </div>
            <div className={"search-filter-options"}>
                <Form.Select
                    onChange={handleSelectChange}
                    value={!arrangeMode ? sortedBy : "Default"}
                    style={{width: "120px"}}
                    disabled={arrangeMode}
                >
                    <option value="">Default</option>
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="A-Z">A-Z</option>
                    <option value="Z-A">Z-A</option>
                </Form.Select>
            </div>
        </div>

        {loading && (<div
            style={{height: "550px", marginLeft: "-100px"}}
            className={"empty-content"}>
            <DocumentLoader/>
        </div>)}

        {!loading && <>
            {documents.length === 0 && (
                <div className={"worker-empty-content animate__animated animate__fadeIn"}>
                    <p>Let's add a new document to the worker 📎</p>
                    <div>
                        <AddComponent workerId={workerId} modal={DocumentModal}/>
                    </div>
                </div>)}

            {documents.length > 0 && (<Row style={{paddingTop: "10px", paddingLeft: "12px"}}>
                {!arrangeMode && documents.map((document, index) => (<Col md={6} key={document.id}>
                    <DocumentBlock
                        document={document}
                        editMode={false}
                        arrangeMode={false}
                        index={index}
                        onDocumentDrop={handleDocumentReorder}
                        setDraggingMode={setDraggingMode}
                    />
                </Col>))}
                {arrangeMode && documents.map((document, index) => (<Col md={6} key={document.id}>
                    <DocumentBlock
                        document={document}
                        editMode={false}
                        arrangeMode={true}
                        index={index}
                        onDocumentDrop={handleDocumentReorder}
                        setDraggingMode={setDraggingMode}
                    />
                </Col>))}
                <Col md={6} className="my-3">
                    <AddComponent workerId={workerId} modal={DocumentModal}/>
                </Col>
            </Row>)}

            {!arrangeMode && <Col
                className={"mt-5 place-fixed-position animate__animated " + (draggingMode ? "animate__bounceIn" : "animate__bounceOut")}
                style={{display: "flex", gap: "20px"}}>
                <div>
                    <EditPlace workerId={workerId} modal={DocumentUpdateModal}/>
                </div>
                <div>
                    <DeletePlace workerId={workerId}/>
                </div>
            </Col>}
            {arrangeMode && (<div className={"document-arrange-mode-fixed-bth"}>
                <button
                    className={"button-submit glow-on-hover"}
                    onClick={() => {
                        setLoading(true);
                        updateDocumentOrder(documents, currentUser).finally(() => {
                            setLoading(false);
                            setArrangeMode(false);
                        });
                    }}
                >
                    Save
                </button>
                <button
                    className={"button-reject"}
                    onClick={() => {
                        setLoading(false);
                        setArrangeMode(false);
                    }}
                >
                    Exit
                </button>
            </div>)}

            {!draggingMode && !arrangeMode && <Col className={"mt-5 animate__animated animate__bounceIn"}
                                                   style={{display: "flex", gap: "20px"}}>
                <div>
                    <EditPlace workerId={workerId} modal={DocumentUpdateModal}/>
                </div>
                <div>
                    <DeletePlace workerId={workerId}/>
                </div>
                <div>
                    {!arrangeMode && (<button
                        style={{width: "125px"}}
                        className={"place-style bold-place"}
                        onClick={handleArrangeModeClick}
                    >
                        <Arrange width={"28px"} height={"28px"}/>
                    </button>)}
                </div>
            </Col>}
        </>}
    </div>)
};

export default DocumentsList;
