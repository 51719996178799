import {Form, Modal} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import React, {useState} from "react";
import DatePickerWithYear from "../../../date/datePicker/DatePickerWithYear";
import Config from "../../../../config";
import {distractRequestHeaders, transformDate} from "../../../../services/utility";

const FiredDateModal = ({show, handleClose, workerId, currentUser}) => {
    const [dateToFire, setDateToFire] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the form from submitting

        const minDate = new Date('1900-01-01');
        if (dateToFire === null) {
            toast.error('Date to be fired is empty');
            return;
        }
        if (dateToFire < minDate) {
            toast.error('Daty nie mogą być wcześniejsze niż 1900');
            return;
        }

        const queryParams = [];

        queryParams.push(`workerId=${workerId}`);
        queryParams.push(`workerStatus=${true}`);
        queryParams.push(`firedDate=${transformDate(dateToFire)}`);

        const queryString = queryParams.join('&');
        try {
            const response = await fetch(`${Config.URL.BASE}/api/workers/status${queryString ? `?${queryString}` : ''}`, {
                method: 'PUT', headers: distractRequestHeaders(currentUser)
            });

            if (response.ok) {
                window.location.reload();
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (<>
        <Modal show={show} onHide={handleClose} size="md" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Zwolnij pracownika</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        width: '300px', margin: '0 auto', // Center align horizontally
                        padding: '20px', textAlign: 'center', // Center align the content inside
                    }}
                >
                    <Form.Label className="create-worker-label">Data zwolnienia:</Form.Label>
                    <DatePickerWithYear
                        startDate={dateToFire}
                        setStartDate={setDateToFire}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="button-reject" onClick={handleClose}>
                    Anuluj
                </button>
                <button className="button-submit glow-on-hover" onClick={handleSubmit}>
                    Zapisz
                </button>
            </Modal.Footer>
            <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false}/>
        </Modal>
    </>);
};

export default FiredDateModal;
