import React, {useState} from "react";

import {OverlayFun} from "../../components/Overlay";
import CertificationBlock from "./CertificationBlock";

const DraggableCertificationBlock = ({certificate}) => {
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const [targetCopyMessage, setTargetCopyMessage] = useState(null);

    const handleCopyClick = (event, text) => {
        const target = event.target.getBoundingClientRect();
        setTargetCopyMessage({top: target.top, left: target.left, text});
        setShowCopyMessage(true);
        setTimeout(() => {
            setShowCopyMessage(false);
        }, 2000);
    };

    const handleCopyHide = () => {
        setShowCopyMessage(false);
    };

    return (<div style={{marginLeft: "-12px", paddingRight: "25px"}}>
        <CertificationBlock
            certificate={certificate}
            handleCopyClick={handleCopyClick}
            handleCopyHide={handleCopyHide}
        />

        <OverlayFun show={showCopyMessage} target={targetCopyMessage}/>
    </div>);
};

export default DraggableCertificationBlock;
