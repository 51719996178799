import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import './assets/css/workerPage.css';
import Config from '../../config';
import {distractRequestGetOptions, distractRequestHeaders} from '../../services/utility';
import AuthService from '../../services/auth.service';
import NotFound from '../404page/NotFound';
import WorkerView from './WorkerView';
import WorkerEdit from './WorkerEdit';
import classnames from 'classnames';
import {toast} from "react-toastify";
import TransmissionModal from "../../components/forms/TransmissionModal";
import ConfirmationModal from "../../components/forms/modals/strategies/ConfirmationModal";
import withAuth from "../../services/hooks/withAuth";
import FiredDateModal from "../../components/forms/modals/strategies/FiredDateModal";
import DuplicateModal from "../../components/forms/DuplicateModal";

const Worker = () => {
    const currentUser = AuthService.getCurrentUser();
    const {companySlug, workerSlug, editable} = useParams();

    const [loading, setLoading] = useState(true);
    const [worker, setWorker] = useState(null);
    const [certificate, setCertificate] = useState(null);
    const [editing, setEditing] = useState(editable === 'true');
    const [is404, setIs404] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalTransmission, setShowModalTranssmision] = useState(false);
    const [showModalDuplicate, setShowModalDuplicate] = useState(false);
    const [countries, setCountries] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showFiredDateModal, setShowFiredDateModal] = useState(false);
    const navigate = useNavigate();

    let workerId = null;
    if (workerSlug) {
        workerId = workerSlug.split('-').pop();
    }

    useEffect(() => {
        setLoading(true);
        const fetchWorkers = async () => {
            try {
                const response = await axios.get(`${Config.URL.BASE}/api/workers/${workerId}`, distractRequestGetOptions(currentUser)).catch(v => {
                    if (v.response.data.statusCode === 404) {
                        setIs404(true);
                    }
                });
                setWorker(response.data);

                const countriesResponse = await axios.get(`${Config.URL.BASE}/api/countries`, distractRequestGetOptions(currentUser));
                const fetchedCountries = countriesResponse.data.map((row) => ({
                    id: row.id, iso: row.iso.trim().replace(/\s/g, ""), name: row.name.trim().replace(/\s/g, ""),
                }));
                setCountries(fetchedCountries);

                setCertificate(response.data.certificate);

            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchWorkers();
    }, [workerId]);

    const handleEdit = () => {
        setEditing(true);
    };

    const transmigrateHandlerFunction = async () => {
        setShowModalTranssmision(true)
    };

    const duplicateHandlerFunction = async () => {
        setShowModalDuplicate(true)
    }

    const handleDiscard = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false)
    };

    const handleCloseTransimissionModal = () => {
        setShowModalTranssmision(false);
    };

    const handleCloseDuplicateModal = () => {
        setShowModalDuplicate(false);
    };

    const handleStatusFunction = async (status) => {
        const queryParams = [];

        queryParams.push(`workerId=${workerId}`);
        queryParams.push(`workerStatus=${status}`);
        const queryString = queryParams.join('&');

        if (status) {
            setShowFiredDateModal(true)
        } else {
            try {
                const response = await fetch(`${Config.URL.BASE}/api/workers/status${queryString ? `?${queryString}` : ''}`, {
                    method: 'PUT', headers: distractRequestHeaders(currentUser)
                });
            } catch (error) {
                console.error(error);
            } finally {
                window.location.reload();
            }
        }
    };


    const handleRemove = async () => {
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const headers = {
                'X-XSRF-TOKEN': currentUser.csrfToken,
                Authorization: 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json',
            };
            await fetch(`${Config.URL.BASE}/api/workers/${workerId}`, {
                method: 'DELETE', headers: headers,
            });
            toast.success('Worker deleted successfully!', {autoClose: 2000}); // Adjust the autoClose duration as needed
            navigate('/');
        } catch (error) {
            toast.error('An error occurred. Please try again.', {autoClose: 2000});
        }
    };

    const handleCloseShowFiredDateModal = () => {
        setShowFiredDateModal(false);
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    if (is404) {
        return <NotFound/>;
    }

    const handleExit = () => {
        setEditing(false);
        const currentPath = window.location.pathname;

        if (currentPath.endsWith('/true')) {
            // Remove the last segment from the URL
            // Navigate to the new URL
            window.location.href = currentPath.substring(0, currentPath.lastIndexOf('/'));
        } else {
            window.location.reload();
        }
    }

    return (<Col xs={10} md={10} style={{marginTop: '30px', marginBottom: '100px'}}>
        {editing && <div className="overlay" onClick={handleDiscard}/>}
        {editing && showModal && (<ConfirmationModal
            message={"Are you sure you want to quit from editing mode?"}
            show={showModal} handleClose={handleCloseModal} handleConfirm={handleExit}/>)}

        {editing ? (<div className={classnames('worker-container', {'editing-mode': editing})}>
            {!loading ? (<WorkerEdit worker={worker} setWorker={setWorker} setEditing={setEditing}
                                     handleFunction={handleEdit}
                                     certificate={certificate} countries={countries}
                                     companySlug={companySlug} handleExit={handleExit}/>) : ('')}
        </div>) : (!loading && <WorkerView worker={worker} handleFunction={handleEdit} certificate={certificate}
                                           removeHandlerFunction={handleRemove}
                                           handleCloseDeleteConfirmation={handleCloseDeleteConfirmation}
                                           handleStatusFunction={handleStatusFunction}
                                           transmigrateHandlerFunction={transmigrateHandlerFunction}
                                           duplicateHandlerFunction={duplicateHandlerFunction}
                                           isAdmin={currentUser.roles.includes("ROLE_ADMIN")}/>)}

        <FiredDateModal
            show={showFiredDateModal}
            workerId={workerId}
            currentUser={currentUser}
            handleClose={handleCloseShowFiredDateModal}
        />
        <ConfirmationModal
            message={"Confirm to remove the worker"}
            show={showDeleteConfirmation}
            handleClose={handleCloseDeleteConfirmation}
            handleConfirm={handleConfirmDelete}
        />
        {!loading && showModalTransmission && (<TransmissionModal show={showModalTransmission}
                                                                  workerId={worker.id}
                                                                  currentCompanyId={worker.company.id}
                                                                  handleClose={handleCloseTransimissionModal}/>)}

        {!loading && showModalDuplicate && (<DuplicateModal show={showModalDuplicate}
                                                               workerId={worker.id}
                                                               currentCompanyId={worker.company.id}
                                                               handleClose={handleCloseDuplicateModal}/>)}
    </Col>);
}
export default withAuth(Worker);
