import React, {useEffect, useState} from 'react';
import {Col, Form, Modal, Row} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../../../services/auth.service';
import Config from '../../../../config';
import {
    distractRequestGetOptions,
    distractRequestHeaders,
    stringToDate,
    transformDate,
} from '../../../../services/utility';
import '../style.css';
import DatePickerWithYear from '../../../date/datePicker/DatePickerWithYear';
import axios from "axios";
import SquareWithText from "../../../square/SquareWithText";
import getMonthAbbreviation from "../../../square/getMonthAddreviation";
import ContractLivePreview from "../../../workerComponents/contract/ContractLivePreview";

const AneksModal = ({workerId, show, success, setSuccess, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();

    const [contract, setContract] = useState(null);
    const [aneksEndDate, setAneksEndDate] = useState(new Date());
    const [isLastOne, setLastOne] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isCheckingAneks, setIsCheckingAneks] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${Config.URL.BASE}/api/workers/${workerId}`, distractRequestGetOptions(currentUser));
            const worker = response.data;
            setContract(worker.contract);
        };
        fetchData();
    }, []);

    useEffect(() => {
        setContract((prevContract) => {
            // Check if prevContract is null or undefined
            if (!prevContract) {
                // Handle the null or undefined case as needed
                return null; // or return an empty contract object or handle the situation differently
            }

            // Find the index of the element with id -1 in the anekses array
            const index = prevContract.anekses.findIndex((aneks) => aneks.id === -1);

            // Create a new copy of the anekses array
            const newAnekses = [...prevContract.anekses];

            if (index !== -1) {
                // If an element with id -1 exists, update it
                newAnekses[index] = {
                    id: -1,
                    aneksEndDate: transformDate(aneksEndDate),
                    lastOne: isLastOne,
                };
            } else {
                // If it doesn't exist, push a new element
                newAnekses.push({
                    id: -1,
                    aneksEndDate: transformDate(aneksEndDate),
                    lastOne: isLastOne,
                });
            }

            // Return a new contract object with the updated anekses array
            return {
                ...prevContract,
                anekses: newAnekses,
            };
        });
    }, [isLastOne, aneksEndDate]);


    useEffect(() => {
        const handleSuccess = async () => {
            if (success) {
                try {
                    await fetch(`${Config.URL.BASE}/api/workers/contracts/aneks?username=${currentUser.username}&workerId=${workerId}&aneksEndDate=${transformDate(aneksEndDate)}&contractId=${contract.id}&isLastOne=${isLastOne}`, {
                        method: 'POST', headers: distractRequestHeaders(currentUser),
                    }).then(response => {
                        if (response.ok) {
                            handleClose();
                            window.location.reload();
                        } else {
                            response.json().then(errorData => {
                                if (errorData.message) {
                                    toast.error(errorData.message);
                                } else {
                                    toast.error('An error occurred. Please try again.');
                                }
                            });
                        }
                    }).catch(() => {
                        toast.error('An error occurred. Please check your internet connection and try again.');
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        };

        handleSuccess();
    }, [success]);

    const handleCheckboxClick = () => {
        setLastOne(prevState => !prevState);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        if ((!contract.contractDateStart && !contract.contractDateEnd) ||
            (contract.contractDateStart === '' && !contract.contractDateEnd === '')) {
            setSuccess(false);
            toast.error('Contract date start and date end are missing');
            return;
        }

        if (stringToDate(contract.contractDateEnd) > aneksEndDate) {
            setSuccess(false);
            toast.error('Aneks data should be ahead');
            return;
        }

        if (isCheckingAneks) {
            return;
        }

        setIsCheckingAneks(true);
        try {
            const response = await fetch(`${Config.URL.BASE}/api/workers/contracts/exists?contractId=${contract.id}`, distractRequestGetOptions(currentUser));
            if (response.ok) {
                if (await response.json() === true) {
                    setSuccess(false);
                    toast.error('There is already the last aneks');
                } else {
                    setSuccess(true);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsCheckingAneks(false);
        }

    };

    let isCurrentDate = true;
    if (!isLastOne) {
        isCurrentDate = new Date() > aneksEndDate;
    }

    return (<>
        <Modal show={show} onHide={handleClose} size="xl" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Dodaj Aneks</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Row style={{justifyContent: "center", alignItems: "center", gap: "30px", padding: '20px'}}>
                        {contract !== null &&
                            <Col md={"5"} className={"modal-contract"}><ContractLivePreview contract={contract}/></Col>}
                        <Col md={"5"}>
                            <div style={{display: "flex", gap: "15px"}}>
                                <SquareWithText isCurrent={isCurrentDate}
                                                text={getMonthAbbreviation(transformDate(aneksEndDate))}
                                                isPaid={false}/>

                                <div>
                                    {isLastOne ? <Form.Group className="mt-1"
                                                             controlId="formCompanyName" disabled>
                                        <Form.Label className="create-worker-label">Ważność do:</Form.Label>
                                        <div className={"custom-datepicker"}
                                             style={{
                                                 minWidth: "280px",
                                                 paddingTop: '10px',
                                                 background: "#eeeeee",
                                                 border: "none"
                                             }}>{contract.contractDateStart}{` - `}<span>nieokreślony</span></div>
                                    </Form.Group> : <Form.Group className="mt-1"
                                                                controlId="formCompanyName">
                                        <Form.Label className="create-worker-label">Ważność do:</Form.Label>
                                        <DatePickerWithYear startDate={aneksEndDate}
                                                            setStartDate={setAneksEndDate}/>
                                    </Form.Group>}
                                    <Form.Check
                                        type="checkbox"
                                        className={'search-checkbox mt-2'}
                                        id="toggleShowPassword"
                                        label={<span
                                            className="search-checkbox-label">Na czas nieokreślony</span>}
                                        checked={isLastOne}
                                        onChange={handleCheckboxClick}
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-reject" onClick={handleClose}>
                        Anuluj
                    </button>
                    <button className="button-submit glow-on-hover" type="submit">
                        Dodaj
                    </button>
                </Modal.Footer>
            </Form>
            <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false}/>
        </Modal>
    </>);
};

export default AneksModal;
