import React from "react";
import "./assets/css/company.css";
import {NavLink} from "react-router-dom";

const WorkerSliderComponent = ({worker, companySlug, navigationLinkBase}) => {

    const getIsActive = (path) => {
        return path === `/${worker.slug}` ? window.location.pathname === path : window.location.pathname.startsWith(path);
    };

    return (<NavLink
        exact
        to={`${navigationLinkBase}/${companySlug}/${worker.slug}`}
        className="worker-notification-button"
        activeClassName="active"
        isActive={() => getIsActive(`/${worker.slug}`)}
        style={{whiteSpace: 'nowrap'}}
    >
        <div>{`${worker.personDetails.firstName} ${worker.personDetails.lastName} `}</div>
    </NavLink>);
};

export default WorkerSliderComponent;

