import AuthService from "../../services/auth.service";
import React, {useEffect, useState} from "react";
import Config from "../../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import CompanyTransmission from "../company/CompanyTransmission";
import Col from "react-bootstrap/Col";
import {fetchCompanies} from "../../services/api-calls";
import {useNavigate} from "react-router-dom";

const DuplicateModal = ({workerId, currentCompanyId, show, handleClose}) => {

    const currentUser = AuthService.getCurrentUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        setLoading(true)
        fetchCompanies(currentUser).then(companies => setCompanies(companies)).finally(() => {
            setLoading(false)
        })
    }, []);

    const handleDuplication = async (companyToCopyId) => {
        try {
            const headers = {
                'X-XSRF-TOKEN': currentUser.csrfToken,
                Authorization: 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json',
            };

            try {
                toast.info('Worker is going to be duplicated, it can take some time!', {autoClose: 2000});
                const response = await fetch(`${Config.URL.BASE}/api/workers/duplicate?companyToCopyId=${companyToCopyId}&workerId=${workerId}&username=${currentUser.username}`, {
                    method: 'POST',
                    headers: headers,
                });

                if (response.ok) {
                    // Worker duplicated successfully

                    // Extract the slug value from the response (assuming it's in JSON format)
                    const responseData = await response.json();
                    const duplicatedWorkerCompany = responseData.company.slug;
                    const duplicatedWorkerSlug = responseData.slug;

                    toast.success('Worker duplicated successfully! You are redirected', {autoClose: 3000});

                    // Navigate to the duplicated worker's slug
                    // window.location.href = `/${duplicatedWorkerCompany}/workers/${duplicatedWorkerSlug}/info`;
                    navigate(`/${duplicatedWorkerCompany}/workers/${duplicatedWorkerSlug}/info`);
                } else {
                    // Handle error cases
                    toast.error('Failed to duplicate worker.');
                }
            } catch (error) {
                // Handle fetch error
                console.error('Error during fetch:', error);
            } finally {
                // Close modal or perform other cleanup
                handleClose();
            }

        } catch (error) {
            toast.error('An error occurred. Please try again.', {autoClose: 2000});
        }
    };

    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">💾 Duplicate worker </Modal.Title>
            </Modal.Header>

            <Container>
                <div className="transmission-modal-container">
                    <h5>Wybierz firmę, do której chcesz <u>skopiować</u> pracownika</h5>
                    {!loading && (<span className={"animate__animated animate__fadeIn animate__delay-0.001s"}>
                                    <Row>
                                        {companies.map((company) => {
                                            if (company.id === currentCompanyId) {
                                                // Render the specific company differently
                                                return (<Col xs={6}><CompanyTransmission
                                                    handleTransmission={handleDuplication}
                                                    isCurrentCompany={true}
                                                    company={company}/></Col>);
                                            } else {
                                                // Render other companies using the default rendering
                                                return (<Col xs={6}><CompanyTransmission
                                                    handleTransmission={handleDuplication}
                                                    isCurrentCompany={false}
                                                    company={company}/></Col>);
                                            }
                                        })}
                                    </Row>
                                </span>)}
                </div>
            </Container>
            <Modal.Footer>
                <button className="button-reject" onClick={handleClose}>
                    Zamknąć
                </button>
            </Modal.Footer>
        </Modal>


    </>)
}

export default DuplicateModal;