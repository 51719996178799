import React, {useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import {checkDateExpiration, unsecuredCopyToClipboard} from "../../../services/utility";

const Certificate = ({certificate}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    if (!certificate) {
        return null; // Return null if certificate is null
    }

    const certificateExpirationColor = checkDateExpiration(certificate.certificateDateEnd);

    return (<div className="certificate">
        <h4>A1</h4>
        <div>
            <h5>Okres ważności</h5>
            <p
                style={{color: certificateExpirationColor}}
                onClick={(event) => unsecuredCopyToClipboard(event, certificate.certificateDateStart + ' - ' + certificate.certificateDateEnd, setOverlayState, handleHide)}
            >
                {certificate.certificateDateStart + ' - ' + certificate.certificateDateEnd}
            </p>
        </div>
        <div>
            <h5>Numer</h5>
            <p style={{cursor: 'pointer'}}
               onClick={(event) => unsecuredCopyToClipboard(event, certificate.certificateNumber, setOverlayState, handleHide)}>
                {certificate.certificateNumber}
            </p>
        </div>
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </div>);
};

export default Certificate;
