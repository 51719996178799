import React, {useState} from 'react';
import Config from '../../config';
import {Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {toast} from 'react-toastify';

const PasswordResetForm = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }

        setIsSpinning(true);

        try {
            const response = await fetch(`${Config.URL.BASE}/api/email/reset-password?token=${token}&newPassword=${password}`, {
                method: 'POST'
            });

            if (response.ok) {
                toast.success('The password is updated successfully');
                setRequestSent(true);
                setTimeout(() => {
                    window.location.href = '/login';
                }, 2000);
            } else {
                setRequestSent(false);
                toast.error('The reset-password link is invalid');
            }
        } catch (error) {
            console.error(error);
            toast.error(error.message);
        } finally {
            setIsSpinning(false);
        }
    };

    return (<div className="login-page">
        <div className="center-block">
            {!requestSent && (<div className="d-flex justify-content-center" style={{padding: '20px'}}>
                {isSpinning ? (<div className="spinner-border" role="status"></div>) : (<Form onSubmit={handleSubmit}>
                    <h3 className="title-login-page">Password reset</h3>
                    <h5 className="subtitle-login-page mt-2">Write down your new password</h5>
                    <Row>
                        <Form.Group as={Col} md={6} className="mb-5 mt-5" style={{width: '360px'}}>
                            <Form.Label className="create-worker-label">Password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                placeholder="xkad-dsax-41"
                                value={password}
                                className="input-field"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">Please enter a
                                password.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md={6} className="mb-5 mt-5" style={{width: '360px'}}>
                            <Form.Label className="create-worker-label">Confirm password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                placeholder="xkad-dsax-41"
                                value={confirmPassword}
                                className="input-field"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">Please enter a confirm
                                password.</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <button className="button-submit glow-on-hover" type="submit"
                            style={{marginTop: '50px', height: '50px', width: '220px'}}>
                        Update password
                    </button>
                </Form>)}
            </div>)}

            {requestSent && (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{padding: '50px', textAlign: 'center'}}>
                        <h3 className="title-login-page">Success</h3>
                        <h5 className="subtitle-login-page mt-2">The password was updated to the mail box</h5>
                    </div>
                    <div style={{marginTop: '280px'}}>
                        <Link style={{textDecoration: 'none', color: '#dedede'}} to="/login">
                            Back to login page
                        </Link>
                    </div>
                </div>)}

            {!requestSent && (<div style={{marginTop: '80px', textAlign: 'center'}}>
                <Link style={{textDecoration: 'none', color: '#dedede'}} to="/login">
                    Back to login page
                </Link>
            </div>)}
        </div>
    </div>);
};

export default PasswordResetForm;
