import React, {useEffect, useRef, useState} from "react";
import AuthService from "../../services/auth.service";
import "./assets/css/login.css";
import "./assets/input.css"
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Spline from "@splinetool/react-spline";

const Login = () => {
    const form = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0); // Number to display
    const [showSpline, setShowSpline] = useState(false); // State to control when to load the Spline object

    useEffect(() => {
        setLoading(true);
        // Simulate a delay of 0.7 seconds
        setTimeout(() => {
            setLoading(false); // Set loading to false after 0.7 seconds
        }, 400);

        // Display numbers from 0 to 100 while waiting
        const interval = setInterval(() => {
            if (count < 100) {
                setCount((prevCount) => prevCount + 1);
            } else {
                clearInterval(interval); // Stop the interval when count reaches 100
            }
        }, 4); // Increment every 7 milliseconds (adjust as needed)

        return () => clearInterval(interval); // Clean up the interval
    }, [count]);

    useEffect(() => {
        // Load Spline object immediately when the component is mounted
        setShowSpline(true);
    }, []);

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        AuthService.login(username, password).then(() => {
            window.location.href = "/";
        }, (error) => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            toast.error(resMessage);
        });
    };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    const listenToScroll = () => {
        let heightToHideFrom = 30;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    return (<div style={{height: "105vh", width: "100%"}}>
        {loading && <div className={"empty-content"}>{count}%</div>}

        {showSpline && (<Spline scene="https://prod.spline.design/opO3S9UslLo8o8nR/scene.splinecode"/>)}

        {!loading && isVisible && (<div className="login-section">
            <Form className={"login-form"} onSubmit={handleLogin} ref={form}>
                <h5>Welcome back! 👋🏻</h5>

                <div>
                    <input className="input_component login__component__input"
                           type="text"
                           name="username"
                           value={username}
                           onChange={onChangeUsername}
                           placeholder="What's your username?"
                           required
                    ></input>
                </div>

                <div>
                    <input className="input_component login__component__input"
                           type="password"
                           placeholder="What is about password?"
                           value={password}
                           onChange={onChangePassword}
                           required
                    ></input>
                </div>

                <div>
                    <button style={{width: "240px", marginLeft: "-0px"}} className={"button-submit glow-on-hover"}>
                        <span>Zaloguj</span>
                    </button>
                </div>
                <Link className={"login-link mt-1"} to={"/forgot-password"}>
                    Forgot password?
                </Link>
            </Form>


        </div>)}
        <div className={"version-marker"}>
            Callidus Group, All rights reserved. <br/>Teraina 1.2
        </div>
    </div>);
};

export default Login;
