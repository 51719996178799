import React, {useEffect, useMemo, useState} from "react";
import AuthService from "../../services/auth.service";
import {useNavigate, useParams} from "react-router-dom";
import CompaniesSlider from "../../components/companies-slider/CompaniesSlider";
import Container from "react-bootstrap/Container";
import {fetchCompanies, fetchWorkersVacationsV2} from "../../services/api-calls";
import {Pagination} from "react-bootstrap";
import {PaginationFunction} from "../../components/workerComponents/workersList/PaginationFunction";
import withAuth from "../../services/hooks/withAuth";

import WorkerVacationBlock from "./WorkerVacationBlock";
import {getPolishMonthName} from "../../services/utility";

const WorkersVacations = () => {
    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);

    const urlParams = new URLSearchParams(window.location.search);
    const year = urlParams.get("year");
    const month = urlParams.get("month");

    const filterValue = urlParams.get("filterValue");

    let page = urlParams.get("page");
    if (page === null || page === undefined) {
        page = 1;
    } else {
        // Parse 'page' as an integer
        page = parseInt(page, 10); // 10 is the radix (base) for parsing decimal numbers
    }

    let perPage = urlParams.get("perPage");
    if (perPage === null || perPage === undefined) {
        perPage = 10;
    } else {
        // Parse 'perPage' as an integer
        perPage = parseInt(perPage, 10);
    }

    const [workersVacations, setWorkersVacations] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [firstnameAndLastname, setFirstnameAndLastname] = useState('');
    const [totalPages, setTotalPages] = useState(1);

    const PAGE_SIZES = [1, 10, 20, 30, 45];
    const [companies, setCompanies] = useState([]);

    const [availableYears, setAvailableYears] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);

    const [selectedYear, setSelectedYear] = useState(null); // Initialize selectedYear to null
    const [currentYear, setCurrentYear] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(0);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {companySlug} = useParams();

    const [availableMonthsInYears, setAvailableMonthsInYears] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchCompanies(currentUser).then((companies) => setCompanies(companies));

        let companyId;
        if (companySlug) {
            companyId = companySlug.split("-").pop();
        }

        if (!year) {
            setSelectedYear(null)
        }

        fetchWorkersVacationsV2(currentUser, companyId, year, month, page, perPage, filterValue).then((data) => {
            setTotalElements(data.workerVacationsResponse.numberOfElements);
            setTotalPages(data.workerVacationsResponse.totalPages);
            setAvailableMonthsInYears(data.availableMonthsInYears)
            setCurrentYear(data.currentYearFiltering);
            setCurrentMonth(data.currentMonthFiltering);

            setAvailableYears(data.availableYears);
            setAvailableMonths(data.availableMonths);
            setWorkersVacations(data.workerVacationsResponse.content);
        });

        setLoading(false);
    }, [year, month, companySlug, page, perPage, filterValue]);

    const handleFirstnameAndLastname = (event) => {
        let firstnameAndLastname = event.target.value;
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("page", 1);
        if (firstnameAndLastname.trim().length === 0) {
            urlSearchParams.delete("filterValue");
            setFirstnameAndLastname('')
        } else {
            setFirstnameAndLastname(firstnameAndLastname)
            urlSearchParams.set("filterValue", event.target.value.trim());
        }

        navigate(`?${urlSearchParams.toString()}`);
    };

    const handlePageChange = (page) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("page", page);
        navigate(`?${urlSearchParams.toString()}`);
    };

    const handlePerPageChange = (event) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("page", 1);
        urlSearchParams.set("perPage", event.target.value);
        navigate(`?${urlSearchParams.toString()}`);
    };

    const changeYearParam = (year) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("year", year);
        urlSearchParams.delete("month");

        navigate(`?${urlSearchParams.toString()}`);
        setSelectedYear(year); // Update selectedYear when a year button is clicked
    };

    const changeMonthParam = (month) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("month", month);
        navigate(`?${urlSearchParams.toString()}`);
    };

    // Function to filter available months based on the selected year
    const filteredAvailableMonths = useMemo(() => {
        if (selectedYear) {
            // If a year is selected, return months for that year from availableMonthsInYears
            return availableMonthsInYears[selectedYear] || [];
        } else {
            // If no year is selected, return all available months
            return availableMonths;
        }
    }, [selectedYear, availableMonths, availableMonthsInYears]);

    return (<Container>
        {!loading && <CompaniesSlider companies={companies} navigationLinkBase={"/vacations"}/>}

        <div className={"scrollable-container"}>
            {availableYears.length > 0 && !loading && (<div className={"mt-3"}>
                {availableYears.map((year) => {
                    return (<button
                        key={year}
                        onClick={() => changeYearParam(year)}
                        className={"animate__animated animate__bounceIn " + (currentYear === year ? "selected" : "select-year-btn")}
                    >
                        {year}
                    </button>);
                })}
            </div>)}
        </div>
        {/* Conditionally render available months based on selectedYear */}
        <div className={"scrollable-container"}>
            {selectedYear && (<div className={"mt-4"}>
                {filteredAvailableMonths.map((month) => {
                    return (<button
                        key={month}
                        onClick={() => changeMonthParam(month)}
                        className={"" + (currentMonth === month ? "selected" : "select-year-btn")}
                    >
                        {getPolishMonthName(month)}
                    </button>);
                })}
            </div>)}
        </div>

        {(workersVacations.length > 0 || filterValue) && (<div className={"mt-4"} style={{marginBottom: '100px'}}>
            <div className={'search'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div>
                    <label>
                        <div className="input-container">
                            <input
                                type="text"
                                id="setFirstnameAndLastname"
                                className={'search-input'}
                                placeholder="Znajdź pracownika"
                                value={firstnameAndLastname}
                                onChange={handleFirstnameAndLastname}
                            />
                        </div>
                    </label>
                </div>
            </div>

            <div className={"mb-4 mt-4"} style={{width: "100%"}}>
                {workersVacations.map((worker, index) => (
                    <WorkerVacationBlock index={(index + 1) + (perPage * (page - 1))}
                                         key={worker.id}
                                         worker={worker} year={year}
                                         month={month}
                                         currentUser={currentUser}/>))}
                {!loading && workersVacations.length === 0 && filterValue && (
                    <div style={{height: "200px"}} className={"empty-content animate__animated animate__fadeIn"}>
                        <p>It looks like there is no '{filterValue}' here 🔍</p>
                    </div>)}
            </div>

            <PaginationFunction
                totalElements={totalElements}
                onClick={handlePageChange}
                onClick1={() => handlePageChange(1)}
                onClick2={() => handlePageChange(page - 1)}
                currentPage={page}
                totalPages={totalPages}
                onClick3={() => handlePageChange(page + 1)}
                onClick4={() => handlePageChange(totalPages)}
                value={perPage}
                onChange={handlePerPageChange}
                numbers={PAGE_SIZES}
                show={false}
                callbackfn1={(size) => (<option key={size} value={size}>
                    {size}
                </option>)}
            />

        </div>)}
        {!loading && workersVacations.length === 0 && !filterValue && (
            <div className={"empty-content animate__animated animate__fadeIn"}>
                <p>It looks like there are no vacations here ⛱️</p>
            </div>)}
    </Container>);
}

export default withAuth(WorkersVacations);