import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import DatePickerWithYear from "../date/datePicker/DatePickerWithYear";
import {showToast, transformDate} from "../../services/utility";

const PersonDetailsForm = ({worker, setWorker, requiredFields}) => {
    const {firstName, lastName, speciality, birthday} = worker.personDetails;

    const [birthdayDate, setBirthdayDate] = useState(null);
    const [toastId, setToastId] = useState(null);

    const handleInputChange = (event) => {
        const {name, value} = event.target;

        if (value !== null) {
            const trimmedValue = value.trim();

            setWorker({
                ...worker,
                personDetails: {
                    ...worker.personDetails,
                    [name]: trimmedValue,
                },
            });
        }
    };

    const handleInputSpecialityChange = (event) => {
        const {name, value} = event.target;
        const maxLength = 64;

        if (value.length <= maxLength) {
            setWorker({
                ...worker,
                personDetails: {
                    ...worker.personDetails,
                    [name]: value
                },
            });
        } else {
            showToast("Speciality cannot contain special characters", toastId, setToastId);
        }
    };
    const setBirthday = (date) => {
        const today = new Date();
        const maxDate = new Date(
            today.getFullYear() - 100,
            today.getMonth(),
            today.getDate()
        );

        if (date > maxDate && date < today) {
            // Date is within the allowed range
            setBirthdayDate(date);
            setWorker((prevWorker) => ({
                ...prevWorker,
                personDetails: {
                    ...prevWorker.personDetails,
                    birthday: transformDate(date),
                },
            }));
        } else {
            showToast(
                "Invalid date. Please select a date which is not ahead and within the last 100 years."
                , toastId, setToastId
            );
        }
    };

    const isFieldRequired = (fieldName) => {
        return requiredFields.includes(fieldName);
    };

    const isFieldValid = (fieldName) => {
        if (isFieldRequired(fieldName)) {
            const value = worker.personDetails[fieldName];
            if (value !== null) {
                const trimmedValue = value.trim();
                const maxLength = 64;
                return (
                    trimmedValue !== "" &&
                    trimmedValue.length <= maxLength &&
                    !trimmedValue.includes(" ")
                );
            }
        }
        return true;
    };

    const isDatePicked = () => {
        return birthday.trim() !== "";
    };

    const renderFeedbackMessage = (fieldName, message) => {
        return (
            isFieldRequired(fieldName) && (
                <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
            )
        );
    };

    return (
        <>
            <Form.Group as={Col} md="3" controlId="formFirstName">
                <Form.Label className="create-worker-label">Imię</Form.Label>
                <Form.Control
                    required={isFieldRequired("firstName")}
                    type="text"
                    placeholder="Jan"
                    name="firstName"
                    value={firstName}
                    className="input-field"
                    onChange={handleInputChange}
                    isInvalid={!isFieldValid("firstName") && firstName !== ""}
                />
                {renderFeedbackMessage("firstName", "Please enter a first name.")}
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formLastName">
                <Form.Label className="create-worker-label">Nazwisko</Form.Label>
                <Form.Control
                    required={isFieldRequired("lastName")}
                    type="text"
                    placeholder="Kowalski"
                    name="lastName"
                    value={lastName}
                    className="input-field"
                    onChange={handleInputChange}
                    isInvalid={!isFieldValid("lastName") && lastName !== ""}
                />
                {renderFeedbackMessage("lastName", "Please enter a last name.")}
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formSpeciality">
                <Form.Label className="create-worker-label">Stanowisko</Form.Label>
                <Form.Control
                    required={isFieldRequired("speciality")}
                    type="text"
                    placeholder="Murarz"
                    name="speciality"
                    value={speciality}
                    className="input-field"
                    onChange={handleInputSpecialityChange}
                />
                {renderFeedbackMessage("speciality", "Please enter a speciality.")}
            </Form.Group>
            <Form.Group
                as={Col}
                md="2"
                style={{width: "190px"}}
                controlId="formCompanyName"
            >
                <Form.Label className={"create-worker-label"}>Data urodzenia</Form.Label>
                <DatePickerWithYear startDate={birthdayDate} setStartDate={setBirthday}/>
                {isFieldRequired("birthday") && !isDatePicked() && (
                    <div style={{color: '#ffa2a2'}}>
                        Wybierz datę
                    </div>
                )}
            </Form.Group>
        </>
    );
};

export default PersonDetailsForm;
