import React, {useEffect, useMemo, useState} from "react";
import AuthService from "../../services/auth.service";
import {useNavigate} from "react-router-dom";
import Container from "react-bootstrap/Container";
import UserFilter from "./UserFilter";
import EditPlace from "../../components/placesToDrag/EditPlace";
import DeletePlace from "../../components/placesToDrag/DeletePlace";
import UserModal from "../../components/forms/modals/strategies/UserModal";
import RemoveUserModal from "../../components/forms/modals/RemoveUserModal";
import withAuth from "../../services/hooks/withAuth";

const Users = () => {
    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);

    const [displayContent, setDisplayContent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser !== null && currentUser.roles.includes("ROLE_ADMIN")) {
            setDisplayContent(true);
        } else {
            navigate(`/`);
        }
    }, [currentUser, navigate]);

    return (<Container style={{maxWidth: "1380px", marginTop: '20px'}}>
        <div style={{display: "flex", gap: "30px"}}>
            <div style={{width: "100%"}}>
                {displayContent && <UserFilter/>}
            </div>
            <div>
                <div className={"animate__animated animate__fadeIn"}>
                    <EditPlace modal={UserModal}/>
                </div>
                <div className={'mt-4 animate__animated animate__fadeIn'}>
                    <DeletePlace modal={RemoveUserModal}/>
                </div>
            </div>
        </div>
    </Container>);
};

export default withAuth(Users);
