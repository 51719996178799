import React, {useRef, useState} from 'react';
import './assets/css/insurance.css';
import {OverlayFun} from '../../Overlay';
import {checkDateExpiration, unsecuredCopyToClipboard} from "../../../services/utility";

const Insurance = ({insurance}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    const renderInsuranceBlock = (title, sub, dateEnd, ekuzNumber) => {
        if (dateEnd !== null || ekuzNumber !== null) {
            const expirationColor = checkDateExpiration(dateEnd);
            return (<div className="block ekuz">
                <h4 className={"mb-3"}>{title}</h4>
                <p
                    style={{color: "#000"}}
                    onClick={(event) => unsecuredCopyToClipboard(event, dateEnd, setOverlayState, handleHide)}
                >
                    <span>Number:</span>
                    {ekuzNumber && ekuzNumber}
                </p>
                <p
                    style={{color: expirationColor}}
                    onClick={(event) => unsecuredCopyToClipboard(event, dateEnd, setOverlayState, handleHide)}
                >
                    <span>{sub}</span>
                    {dateEnd && dateEnd}
                </p>
            </div>);
        }
        return null;
    };


    const renderCFRBlock = (title, sub, dateStart, dateEnd) => {
        if (dateEnd !== null) {
            const expirationColor = checkDateExpiration(dateEnd);
            return (<>
                    <div className="block">
                        <h4>{title}</h4>
                        <p
                            style={{color: expirationColor}}
                            onClick={(event) => unsecuredCopyToClipboard(event, dateStart, setOverlayState, handleHide)}
                        >
                            <span>{sub}</span>
                            {dateStart}
                        </p>
                    </div>
                    <div className="block-edit cfr-date-end">
                        <h4>CFR Date End</h4>
                        <p
                            style={{color: expirationColor}}
                            onClick={(event) => unsecuredCopyToClipboard(event, dateEnd, setOverlayState, handleHide)}
                        >
                            <span>Ważność do:</span>
                            {dateEnd}
                        </p>
                    </div>
                </>

            );
        }
        return null;
    };

    const renderBadanieBlock = (title, sub, dateEnd) => {
        if (dateEnd !== null) {
            const expirationColor = checkDateExpiration(dateEnd);
            return (<div className="block">
                <h4>{title}</h4>
                <p
                    style={{color: expirationColor}}
                    onClick={(event) => unsecuredCopyToClipboard(event, dateEnd, setOverlayState, handleHide)}
                >
                    <span>{sub}</span>
                    {dateEnd}
                </p>
            </div>);
        }
        return null;
    };

    return (<>
        <div className="insurance">
            {renderInsuranceBlock('EKUZ', "Ważność do:", insurance.ekuzDateEnd, insurance.ekuzNumber)}
        </div>
        <div className="insurance">
            {renderCFRBlock('CFR', "Ważność od:", insurance.cfrDateStart, insurance.cfrDateEnd)}
        </div>

        {insurance.badanieStart !== null && insurance.badanieEnd !== null && (renderBadanieBlock('Badanie', "Okres:", insurance.badanieStart + ' - ' + insurance.badanieEnd))}

        <OverlayFun ref={overlayRef} show={overlayState.show} target={overlayState.target} onHide={handleHide}/>
    </>);
};

export default Insurance;
