import React, {useEffect, useMemo, useState} from "react";
import Company from "../company/Company";
import AuthService from "../../services/auth.service";
import CompanyModal from "../forms/modals/strategies/CompanyModal";
import {fetchCompanies} from "../../services/api-calls";

const Companies = () => {
    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [companyId, setCompanyId] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchCompanies(currentUser)
            .then(companies => setCompanies(companies))
            .finally(() => {
                setLoading(false);
            });
    }, [currentUser, updated]);

    const handlerShowModal = (companyId) => {
        setCompanyId(companyId);
        setShowModal(true);
    };

    const handlerCloseModal = () => {
        setShowModal(false);
        window.location.reload();
    };

    return (
        <>
            {companies !== undefined && companies !== null && companies.length > 0 && !loading && (
                <div>
                    <div className="companies-vertical-block">
                        <div>
                            {companies.map((company) => {
                                return (
                                    <Company
                                        handlerShowModal={handlerShowModal}
                                        handleOrderChange={() => setUpdated(prevUpdated => !prevUpdated)}
                                        company={company}
                                        key={company.id}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    {showModal &&
                        <CompanyModal companyId={companyId} handleClose={handlerCloseModal} show={showModal}/>}
                </div>
            )}
        </>
    );
};

export default Companies;
