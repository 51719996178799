import React, {useState} from 'react';
import axios from 'axios';
import Config from "../../config";
import {ReactComponent as RestoreAccount} from "../../assets/svgs/RestoreAccount.svg";
import {ReactComponent as ForgotPasswordIcon} from "../../assets/svgs/ResetPassword.svg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";

const ForgotPassword = () => {
    const [showForm, setShowForm] = useState(false);
    const [username, setUsername] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false); // Add this line

    const handleAnotherAction = async () => {
        setIsSpinning(true); // Start spinning animation

        try {
            await axios.post(`${Config.URL.BASE}/api/email/create-account`);
            setRequestSent(true);
        } catch (error) {
            console.error('Error sending reset password request:', error);
        }

        setIsSpinning(false); // Stop spinning animation
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        try {
            setIsSpinning(true); // Start spinning animation
            await axios.post(`${Config.URL.BASE}/api/email/send-password-reset-username?username=${username}`);
            setRequestSent(true);
            // Redirect after 2 seconds
            setIsSpinning(false); // Stop spinning animation
            setTimeout(() => {
                window.location.href = '/login';
            }, 3000);
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error("Username is not found")
            } else {
                console.error('Error sending reset password request:', error);
            }
        } finally {
            setIsSpinning(false); // Stop spinning animation
        }
    };


    const blockStyle = {
        width: "300px",
        height: "300px",
        background: "#f3f3f3",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transitionDuration: "0.3s",
        cursor: "pointer",
        fontSize: "18px",
        fontWeight: "400",
        color: "#a6a6a6"
    };

    return (<div className={"login-page"}>
            <div className={"center-block"}>
                {!showForm && !requestSent && (<div>
                    <h2>What are you looking for?</h2>
                    <Row>
                        <Col className="mt-5" style={{display: 'flex', gap: '50px', justifyContent: 'center'}}>
                            <div style={blockStyle} className="square-with-text" onClick={() => setShowForm(true)}>
                                <div>
                                    <div>
                                        <ForgotPasswordIcon width={"45px"}/>
                                    </div>
                                    <div className={'mt-4 no-select'}>Forgot password</div>
                                </div>
                            </div>
                            <div style={blockStyle} className="square-with-text" onClick={handleAnotherAction}>
                                <div>
                                    {isSpinning ? (
                                        <div className="spinner-border" role="status"></div>
                                    ) : (
                                        <div>
                                            <div>
                                                <RestoreAccount width={"60px"}/>
                                            </div>
                                            <div className={'mt-4 no-select'}>Restore account</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div
                        style={{marginTop: "70px", textAlign: 'center'}}>
                        <Link className={"login-link"} to={'/login'}>Back to login
                            page</Link>
                    </div>
                </div>)}
                {showForm && !requestSent && (<>
                        <div className="d-flex justify-content-center" style={{padding: '50px'}}>
                            {isSpinning ? (
                                <div className="spinner-border" role="status"></div>
                            ) : (
                                <Form onSubmit={handleSubmitForm}>
                                    <h3 className={'title-login-page'}>Password reset</h3>
                                    <h5 className="subtitle-login-page mt-2">Write down your username and get a
                                        mail</h5>

                                    <Form.Group className="mb-5 mt-5" style={{width: '360px'}}>
                                        <Form.Label className="create-worker-label">Username</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="username"
                                            placeholder={"CreativePerson12"}
                                            value={username}
                                            className="input-field"
                                            onChange={handleUsernameChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a username.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <button className="button-submit glow-on-hover"
                                            type="submit" style={{width: '170px'}}>Submit
                                    </button>
                                </Form>
                            )}
                        </div>
                        <div
                            style={{marginTop: "78px", textAlign: 'center'}}>
                            <Link className={"login-link"} to={'/login'}>Back to login
                                page</Link>
                        </div>
                    </>

                )}
                {requestSent && (<>

                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <div style={{padding: '50px', textAlign: 'center'}}>
                            <h3 className="title-login-page">Success</h3>
                            <h5 className="subtitle-login-page mt-2">The mail was sent to the mail box</h5>
                        </div>
                        <div style={{marginTop: '280px'}}>
                            <Link className={"login-link"} to={'/login'}>Back to login
                                page</Link>
                        </div>
                    </div>
                </>)}
            </div>
        </div>

    );
};

export default ForgotPassword;
