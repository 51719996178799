import React, {useEffect, useMemo, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import './index.css'
import './assets/main.css'
import './assets/animations.css'
import Login from "./pages/auth/Login";
import Home from "./pages/home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthService from "./services/auth.service";
import authService from "./services/auth.service";
import Navbar from "./components/navbar/Navbar";
import CreateCompany from "./pages/create-company/CreateCompany";
import CreateWorker from "./pages/create-worker/CreateWorker";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import NotFound from "./pages/404page/NotFound";
import CreateUser from "./pages/create-user/CreateUser";
import Users from "./pages/users/Users";
import GlobalHandler from "./GlobalHandler";
import {ToastContainer} from "react-toastify";
import PasswordResetForm from "./pages/password-reset/PasswordResetForm";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import Config from './config';
import WorkersVacations from "./pages/all-workers-vacations/WorkersVacations";
import NotificationsPageCombined from "./pages/notifications/NotificationPageCombined";
import WorkerHandler from "./pages/worker/WorkerHandler";
import InactivityLogout from "./InactivityLogout";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("isAuthenticated"));

    useEffect(() => {
        // Check if user is authenticated, e.g. by calling an API
        const checkAuthStatus = async () => {
            try {
                if (AuthService.getCurrentUser() !== null) {
                    const token = AuthService.getCurrentUser().token;
                    const response = await fetch(`${Config.URL.BASE}/api/auth/checkAuthStatus/` + token);
                    const data = await response.json();
                    localStorage.setItem("isAuthenticated", data.result);
                    setIsAuthenticated(data.result);
                } else {
                    authService.logout();
                }
            } catch (error) {
                console.error('Error checking authentication status:', error);
            }
        };

        checkAuthStatus();
    }, []);

    const navbar = useMemo(() => <Navbar/>, []);

    return (<GlobalHandler>
        <InactivityLogout>
            {isAuthenticated && !window.location.href.includes('/users/reset-password') && navbar}

            <Routes>
                <Route path="/login" element={isAuthenticated ? <Navigate to="/"/> : <Login/>}/>
                <Route path="/forgot-password" element={isAuthenticated ? <Navigate to="/"/> : <ForgotPassword/>}/>

                <Route path="/">
                    <Route index element={isAuthenticated ? <Home/> : <Navigate to="/login"/>}/>
                    <Route path="/:companySlug" element={isAuthenticated ? <Home/> : <Navigate to="/login"/>}/>
                </Route>

                <Route path="/notifications">
                    <Route index element={isAuthenticated ? <NotificationsPageCombined/> : <Navigate to="/login"/>}/>
                    <Route path="/notifications/:companySlug"
                           element={isAuthenticated ? <NotificationsPageCombined/> : <Navigate to="/login"/>}/>
                    <Route path="/notifications/:companySlug/:workerSlug"
                           element={isAuthenticated ? <NotificationsPageCombined/> : <Navigate to="/login"/>}/>
                </Route>

                <Route path="/register-user" element={isAuthenticated ? <CreateUser/> : <Navigate to="/login"/>}/>

                <Route path="/users">
                    <Route index
                           element={isAuthenticated ? <DndProvider backend={HTML5Backend}><Users/></DndProvider> :
                               <Navigate to="/login"/>}/>
                    <Route path="reset-password" element={<PasswordResetForm/>}/>
                </Route>

                <Route path="/vacations">
                    <Route index element={isAuthenticated ? <WorkersVacations/> : <Navigate to="/login"/>}/>
                    <Route path="/vacations/:companySlug"
                           element={isAuthenticated ? <WorkersVacations/> : <Navigate to="/login"/>}/>
                    <Route path="/vacations/:companySlug/:workerSlug"
                           element={isAuthenticated ? <WorkersVacations/> : <Navigate to="/login"/>}/>
                </Route>

                <Route path="/:companySlug/workers/:workerSlug/*">
                    <Route index element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="info/:editable"
                           element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="info" element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="vacations" element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="certifications"
                           element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="documents" element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="notifications"
                           element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="contracts"
                           element={isAuthenticated ? <WorkerHandler/> : <Navigate to="/login"/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Route>

                <Route path="/create-company"
                       element={isAuthenticated ? <CreateCompany/> : <Navigate to="/login"/>}/>

                <Route path="/create-worker">
                    <Route index element={isAuthenticated ? <CreateWorker/> : <Navigate to="/login"/>}/>
                    <Route path=":companySlug"
                           element={isAuthenticated ? <CreateWorker/> : <Navigate to="/login"/>}/>
                </Route>

                <Route path="/error/404" element={<NotFound/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </InactivityLogout>
        <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false}/>
    </GlobalHandler>);
};

export default App;