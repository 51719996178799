import React, {useState} from "react";
import DatePicker from "react-datepicker";
import {format, getMonth, getYear, isValid, parse} from "date-fns";
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";

const DatePickerWithRange = ({startDate, setStartDate, endDate, setEndDate, setRangeChange}) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];

    const [isCalendarPicked, setCalendarPicked] = useState(false);
    const [input, setInput] = useState('');

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setInput(format(start, 'dd.MM.yyyy') + " - " + format(end, 'dd.MM.yyyy'))
        }

    };

    const handleCheckboxChange = (event) => {
        setCalendarPicked(prevState => !prevState);
    };

    const handleInputChange = (e) => {

        const inputText = e.target.value;

        setInput(inputText);


        if (inputText.length >= 10) {
            const startDate = inputText.substring(0, 10);
            if (isValidDate(startDate)) {
                const match = inputText.match(/.{10}(\d)/);

                if (match) {
                    const stringFromFirstDigit = inputText.substring(10)
                    if (stringFromFirstDigit.length >= 10) {
                        const endDateMatch = stringFromFirstDigit.match(/\d{2}\.\d{2}\.\d{4}/);
                        if (endDateMatch) {
                            if (isValidDate(endDateMatch)) {
                                let startDateObject = parse(startDate, 'dd.MM.yyyy', new Date());
                                let endDateObject = parse(endDateMatch, 'dd.MM.yyyy', new Date());
                                if (!(startDateObject > endDateObject)) {
                                    setRangeChange(startDateObject, endDateObject)
                                    toast.success("Dates applied");
                                } else {
                                    toast.error("End date cannot be earlier than start date");
                                }
                            } else {
                                toast.error("End date is invalid: " + endDateMatch);
                            }
                        }
                    }
                }
            } else {
                toast.error('Start date is invalid: ' + startDate);
            }
        }
    };

    const isValidDate = (dateString) => {
        try {
            const parsedDate = parse(dateString, 'dd.MM.yyyy', new Date());
            return isValid(parsedDate);
        } catch (error) {
            return false;
        }
    };

    return (<div style={{display: "flex", gap: "10px"}}>
            {isCalendarPicked ? <DatePicker
                calendarClassName="my-calendar-class"
                className="custom-datepicker"
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                renderCustomHeader={({
                                         date,
                                         changeYear,
                                         changeMonth,
                                         decreaseMonth,
                                         increaseMonth,
                                         prevMonthButtonDisabled,
                                         nextMonthButtonDisabled,
                                     }) => (<div style={{margin: 10, display: "flex", justifyContent: "center"}}>
                    <div style={{marginRight: "10px"}}>
                        <Button
                            variant={"dark"}
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                        >
                            {"<"}
                        </Button>
                    </div>
                    <div style={{marginRight: "5px", width: "90px"}}>
                        <Form.Control
                            value={getYear(date)}
                            onChange={({target: {value}}) => changeYear(value)}
                        ></Form.Control>
                    </div>
                    <div style={{marginRight: "10px", width: "140px"}}>
                        <Form.Select
                            value={months[getMonth(date)]}
                            onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
                        >
                            {months.map((option) => (<option key={option} value={option}>
                                {option}
                            </option>))}
                        </Form.Select>
                    </div>
                    <div>
                        <Button
                            variant={"dark"}
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                        >
                            {">"}
                        </Button>
                    </div>
                </div>)}
            /> : <div>
                <input className="input_component"
                       type="text"
                       name="dateRangeTime"
                       value={input}
                       onChange={handleInputChange}
                       placeholder="01.01.2000 - 02.02.2002"
                ></input>
            </div>}

            <div className={"checkbox-wrapper"}>
                <div className="checkbox-wrapper-57">
                    <label className="container">
                        <input type="checkbox"
                               checked={isCalendarPicked}
                               onChange={handleCheckboxChange}
                        />
                        <div className="checkmark"></div>
                    </label>
                </div>
                <span className={isCalendarPicked ? "wrapper-active" : "wrapper-disabled"}>Calendar</span>
            </div>

        </div>
    );

};

export default DatePickerWithRange;