import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {Col, Form} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {getFlagEmoji} from "../../../../services/utility";

const PersonalDetailsEdit = ({worker, setWorker, countries, setIsValidToUpdate}) => {

    const personDetails = worker.personDetails;

    let initCountryIdValue;
    if (personDetails.country !== null) {
        initCountryIdValue = personDetails.country.id || {};
    }

    const [showChangeCountry, setShowChangeCountry] = useState(false)

    const [birthday, setBirthday] = useState(personDetails.birthday);

    const [city, setCity] = useState(personDetails.city);
    const [region, setRegion] = useState(personDetails.region);
    const [address, setAddress] = useState(personDetails.address);
    const [originAddress, setOriginAddress] = useState(personDetails.originAddress);

    const handleBirthdayChange = (e) => {
        setBirthday(e.target.innerText);
    };

    const handleCountryChange = (selected) => {
        const country = selected[0];
        if (country) {
            setWorker({
                ...worker, personDetails: {
                    ...worker.personDetails, countryId: country.id,
                },
            });
        } else {
            toast.error("Country should be chosen");
            setWorker({
                ...worker, personDetails: {
                    ...worker.personDetails, countryId: initCountryIdValue,
                },
            });
        }
    };

    const handleCheckboxClick = () => {
        setShowChangeCountry(prevState => !prevState);
        if (showChangeCountry === false) {
            setWorker({
                ...worker, personDetails: {
                    ...worker.personDetails, countryId: initCountryIdValue,
                },
            });
        }
    };

    const handleCityChange = (e) => {
        setCity(e.target.innerText);
    };

    const handleRegionChange = (e) => {
        setRegion(e.target.innerText);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.innerText);
    };

    const handleOriginAddressChange = (e) => {
        setOriginAddress(e.target.innerText);
    }

    useEffect(() => {
        setWorker({
            ...worker, personDetails: {
                ...personDetails,
                birthday: birthday,
                countryId: personDetails.country ? personDetails.country.id : initCountryIdValue,
                city: city,
                region: region,
                address: address,
                originAddress: originAddress
            },
        });
        setIsValidToUpdate(true);
    }, [birthday, city, region, address, originAddress])

    return (<>
        <div className="personal-details-worker ">
            <div className="worker-card" style={{width: '200px'}}>
                <label>Data urodzenia</label>
                <div
                    style={{width: "110px", display: 'inline-block'}}
                    contentEditable={true}
                    onBlur={handleBirthdayChange}
                    suppressContentEditableWarning={true}>
                    {birthday}
                </div>
            </div>
            <div className="worker-card birthday-place">
                <label>Miejsce urodzenia</label>
                <span style={{color: "#7e7d7d"}}>Country <div
                    style={{minWidth: '40px', display: 'inline-block', color: "black"}}
                >
                        {personDetails.country !== null ? personDetails.country.name : ""}
                    </div></span>
                <div></div>
                <span style={{color: "#7e7d7d"}}>City: <div
                    style={{minWidth: '40px', display: 'inline-block', color: "black"}}
                    contentEditable={true}
                    onBlur={handleCityChange}
                    suppressContentEditableWarning={true}
                >
                        {city}
                    </div></span>
                <div></div>
                <span style={{color: "#7e7d7d"}}>Region: <div
                    style={{minWidth: '40px', display: 'inline-block', color: "black"}}
                    contentEditable={true}
                    onBlur={handleRegionChange}
                    suppressContentEditableWarning={true}>
                        {region}
                        </div>
                    </span>
            </div>
        </div>
        <div className="worker-card pass-document">
            <label>Country of birth</label>
            <div>
                {!showChangeCountry && personDetails.country !== null && getFlagEmoji(personDetails.country.iso) + ' ' + personDetails.country.name}
                {countries.length > 0 && showChangeCountry && (<Form.Group as={Col} md="5" controlId="formCountry">
                    <Typeahead
                        style={{width: '300px', height: '40px'}}
                        labelKey="name"
                        options={countries}
                        placeholder="Country of birth"
                        renderMenuItemChildren={(option, {text}) => (<div>
                            {getFlagEmoji(option.iso)} {option.name}
                        </div>)}
                        onChange={handleCountryChange}
                    />
                </Form.Group>)}
            </div>
            <div style={{marginTop: '5px'}}>
                <Form.Check
                    type="checkbox"
                    className={'search-checkbox'}
                    id="toggleShowCountryBirth"
                    label={<span className="search-checkbox-label">Change Country of birth</span>}
                    checked={showChangeCountry}
                    onChange={handleCheckboxClick}
                />
            </div>
        </div>

        <div className="worker-card">
            <label>Adres w kraju pochodzenia</label>
            <div
                style={{marginTop: '-4px'}}
                contentEditable={true}
                onBlur={handleOriginAddressChange}
                suppressContentEditableWarning={true}>
                {originAddress}
            </div>
        </div>

        <div className="worker-card">
            <label>Adres w Polsce</label>
            <div
                style={{marginTop: '-4px'}}
                contentEditable={true}
                onBlur={handleAddressChange}
                suppressContentEditableWarning={true}>
                {address}
            </div>
        </div>


    </>);
};

export default PersonalDetailsEdit;
