import React, {useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';

function InputNotesArray({worker, setWorker}) {
    const [values, setValues] = useState(worker.notes || []);

    function handleChange(i, field, event) {
        const newValues = [...values];
        let newValue;

        if (event && event.target) {
            newValue = event.target.value;
        } else {
            newValue = event;
        }

        newValues[i][field] = newValue;
        setValues(newValues);

        // Update the notes in the worker object
        const updatedWorker = {...worker};
        updatedWorker.notes = newValues;
        setWorker(updatedWorker);
    }

    function addInput() {
        if (values.length < 3) {
            setValues([
                ...values,
                {
                    key: '',
                    value: '',
                },
            ]);
        } else {
            alert('You can only add a maximum of 3 notes.');
        }
    }

    function removeInput(i) {
        const newValues = [...values];
        newValues.splice(i, 1);
        setValues(newValues);

        // Update the notes in the worker object
        const updatedWorker = {...worker};
        updatedWorker.notes = newValues;
        setWorker(updatedWorker);
    }

    return (
        <div>
            {values.map((value, i) => (
                <div className="mb-3" key={i}>
                    <Row>
                        <Form.Group as={Col} md="4" controlId={`formNoteKey-${i}`}>
                            <Form.Label className="create-worker-label">Key</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Note Key"
                                value={value.key}
                                className="input-field"
                                onChange={(event) => handleChange(i, 'key', event)}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a note key.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId={`formNoteValue-${i}`}>
                            <Form.Label className="create-worker-label">Value</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Note Value"
                                value={value.value}
                                className="input-field"
                                onChange={(event) => handleChange(i, 'value', event)}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a note value.</Form.Control.Feedback>
                        </Form.Group>
                        <Col md="1">
                            <button
                                className="remove-button-style"
                                style={{marginTop: '35px', height: '45px'}}
                                type="button"
                                onClick={() => removeInput(i)}
                            >
                                -
                            </button>
                        </Col>
                    </Row>
                </div>
            ))}
            {values.length < 3 && (
                <button className="add-button-style" type="button" style={{height: '45px'}} onClick={addInput}>
                    +
                </button>
            )}
        </div>
    );
}

export default InputNotesArray;
