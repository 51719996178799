import {useDrop} from "react-dnd";
import React, {useState} from "react";
import './style.css'
import {ReactComponent as Edit} from '../../assets/modal/Edit.svg';

const EditPlace = ({workerId, modal: ModalComponent}) => {

    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [component, setComponent] = useState(false);
    const [componentId, setComponentId] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [, drop] = useDrop({
        accept: ['CERTIFICATION_BLOCK', 'VACATION_BLOCK', 'DOCUMENT_BLOCK', 'USER_BLOCK'], // Accept multiple types
        drop: (item) => {
            if ('certificate' in item) {
                const {certificate} = item;
                setComponentId(certificate.id);
                setShowModal(true);
            } else if ('vacation' in item) {
                const {vacation} = item;
                setComponentId(vacation.id);
                setComponent(vacation)
                setShowModal(true);
                // Perform vacation-specific action
            } else if ('document' in item) {
                const {document} = item;
                setComponentId(document.id);
                setShowModal(true);
                // Perform vacation-specific action
            } else if ('user' in item) {
                const {user} = item;
                setComponentId(user.id);
                setShowModal(true);
                // Perform vacation-specific action
            }
        },

    });


    return (<div ref={drop}>
        <div
            className={"edit-place place-style"}
        >
            <div className={"place-style-icon"}><Edit/>
            </div>
        </div>

        {showModal && (
            <ModalComponent componentId={componentId} component={component} success={success} setSuccess={setSuccess}
                            workerId={workerId} show={showModal} handleClose={handleCloseModal}/>)}

    </div>);
};

export default EditPlace;