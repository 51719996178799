import React, {useState} from 'react';
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import DatePickerWithYear from "../../date/datePicker/DatePickerWithYear";
import {toast} from "react-toastify";
import {stringToDate, transformDate} from "../../../services/utility";

function InputCertificationArray({certifications, setCertifications}) {
    const [values, setValues] = useState(certifications || []);

    function handleChange(i, field, event) {
        const newValues = [...values];
        let newValue;

        if (event && event.target) {
            newValue = event.target.value;
        } else {
            newValue = event;
        }

        if (field === "certificateNumber" && newValue.length > 64) {
            toast.error("First and last names are exceed 64 characters");
            return; // Skip updating the state if the certificate number is too long
        }

        if (field === "certificateDateStart" || field === "certificateDateEnd") {
            const startDate = field === "certificateDateStart" ? stringToDate(newValue) : stringToDate(newValues[i].certificateDateStart);
            const endDate = field === "certificateDateEnd" ? stringToDate(newValue) : stringToDate(newValues[i].certificateDateEnd);

            if (endDate != null && endDate < startDate) {
                toast.error("End date cannot be ahead of the start date");
                return; // Skip updating the state if the end date is ahead of the start date
            }
        }

        if (event && event.target) {
            newValue = event.target.value;
        } else {
            newValue = event;
        }

        newValues[i][field] = newValue;
        setValues(newValues);
        setCertifications(newValues);
    }

    function addInput() {
        setValues([...values, {
            certificateDateStart: "", certificateDateEnd: "", categoryLevel: "A1", certificateNumber: "", isLastOne: false
        }]);
    }

    function removeInput(i) {
        const newValues = [...values];
        newValues.splice(i, 1);
        setValues(newValues);
        setCertifications(newValues);
    }

    return (<div>
        {values.map((value, i) => (<div className="mb-3" key={i}>
            <Row>
                <Form.Group as={Col} md="4" controlId={`formCertificateNumber-${i}`}>
                    <Form.Label className="create-worker-label">Numer A1</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="235689745645"
                        value={value.certificateNumber}
                        className="input-field"
                        onChange={(event) => handleChange(i, "certificateNumber", event)}
                    />
                    <Form.Control.Feedback type="invalid">Please enter a certificate
                        number.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" style={{width: "240px"}}
                            controlId={`formCertificateDateStart-${i}`}>
                    <Form.Label className="create-worker-label">Ważność od:</Form.Label>
                    <DatePickerWithYear
                        startDate={stringToDate(value.certificateDateStart)}
                        setStartDate={(date) => handleChange(i, "certificateDateStart", transformDate(date))}
                    />

                </Form.Group>
                <Form.Group as={Col} md="3" style={{width: "240px"}} controlId={`formCertificateDateEnd-${i}`}>
                    <Form.Label className="create-worker-label">Ważność do:</Form.Label>
                    <DatePickerWithYear
                        startDate={stringToDate(value.certificateDateEnd)}
                        setStartDate={(date) => handleChange(i, "certificateDateEnd", transformDate(date))}
                    />
                </Form.Group>
                <Col md="1">
                    <button
                        className="remove-button-style"
                        style={{marginTop: "35px", height: "45px"}}
                        type="button"
                        onClick={() => removeInput(i)}
                    >
                        -
                    </button>
                </Col>
            </Row>
        </div>))}
        <button className="add-button-style" type="button" style={{height: "45px"}} onClick={addInput}>
            +
        </button>
    </div>);
}

export default InputCertificationArray;
