import {Pagination} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import React from "react";
import {Link} from "react-router-dom";
import {ReactComponent as UserAdd} from '../../../assets/svgs/worker-list/AddWorker.svg';

export function PaginationFunction(props) {
    const generatePaginationLinks = () => {
        const totalPages = props.totalPages;
        const currentPage = props.currentPage;
        const visiblePages = 5; // Number of visible page links

        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > visiblePages) {
            const half = Math.floor(visiblePages / 2);

            startPage = Math.max(currentPage - half, 1);
            endPage = startPage + visiblePages - 1;

            if (endPage > totalPages) {
                endPage = totalPages;
                startPage = endPage - visiblePages + 1;
            }
        }

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => props.onClick(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        if (startPage > 1) {
            pages.unshift(
                <Pagination.Ellipsis key="ellipsis-start"/>
            );
            pages.unshift(
                <Pagination.Item key={1} onClick={() => props.onClick(1)}>
                    1
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            pages.push(
                <Pagination.Ellipsis key="ellipsis-end"/>
            );
            pages.push(
                <Pagination.Item key={totalPages} onClick={() => props.onClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return pages;
    };

    return (<div className="d-flex justify-content-between align-items-center">
        <div>
            <div className={'totalElements no-select'}>
                {props.totalElements}
            </div>
        </div>
        <Pagination style={{margin: "0 auto"}}>
            <Pagination.First onClick={props.onClick1}/>
            <Pagination.Prev onClick={props.onClick2} disabled={props.currentPage === 1}/>
            {generatePaginationLinks()}
            <Pagination.Next onClick={props.onClick3} disabled={props.currentPage === props.totalPages}/>
            <Pagination.Last onClick={props.onClick4}/>
        </Pagination>

        <Form.Select id="perPage" name="perPage" style={{width: "70px"}} value={props.value}
                     onChange={props.onChange}>
            {props.numbers.map(props.callbackfn1)}
        </Form.Select>
        {props.show && <Link to={props.link}>
            <button className={'add-worker-button'} type="button">
                <span>
                     <UserAdd height={'20px'} width={'20px'}/>
                </span>
            </button>
        </Link>}

    </div>);
}