import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import WorkerMenu from "../../components/workerComponents/workerMenu/WorkerMenu";
import {Col} from "react-bootstrap";
import {useParams} from "react-router-dom";
import Vacations from "../vacations/Vacations";
import Certifications from "../certifications/Certifications";
import Documents from "../documents/DocumentsPage";
import './assets/css/workerPage.css';
import WorkerNotifications from "../notifications/WorkerNotifications";
import Worker from "./Worker";
import Contracts from "../contracts/Contracts";

const WorkerHandler = () => {
    const currentPath = window.location.pathname;
    const parts = currentPath.split('/');
    const lastPart = parts[parts.length - 1];

    const {companySlug, workerSlug, editable} = useParams();

    const [pageValue, setPageValue] = useState(null)

    useEffect(() => {
        setPageValue(lastPart)
    }, [currentPath])

    return (<Container style={{maxWidth: '1470px'}}>
            <Row>
                <Col xs={2} md={2} className={'d-none d-md-block'}>
                    <WorkerMenu companySlug={companySlug} workerSlug={workerSlug}/>
                </Col>
                {pageValue !== null && <>
                    {(pageValue === "info" || pageValue === "true") && <Worker/>}
                    {pageValue === "vacations" && <Vacations/>}
                    {pageValue === "certifications" && <Certifications/>}
                    {pageValue === "documents" && <Documents/>}
                    {pageValue === "notifications" && <WorkerNotifications/>}
                    {pageValue === "contracts" && <Contracts/>}
                </>}
            </Row>
        </Container>)
}

export default WorkerHandler;