import React, {useCallback, useEffect, useMemo, useState} from "react";
import Row from "react-bootstrap/Row";
import "./assets/css/notifications.css";
import Notification from "../../components/notification/Notification";
import AuthService from "../../services/auth.service";
import {useParams} from "react-router-dom";
import Config from "../../config";
import {distractRequestGetOptions, distractRequestHeaders, getPaginationFunction} from "../../services/utility";
import Col from "react-bootstrap/Col";
import {ReactComponent as Flash} from "../../assets/svgs/Flash.svg";
import {ReactComponent as FlashSlash} from "../../assets/svgs/FlashSlash.svg";
import axios from "axios";
import {toast} from "react-toastify";
import {ReactComponent as Trash} from "../../assets/modal/TrashIcon.svg";
import ConfirmationModal from "../../components/forms/modals/strategies/ConfirmationModal";
import withAuth from "../../services/hooks/withAuth";

const WorkerNotifications = () => {
    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);

    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [worker, setWorker] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const PAGE_SIZES = [10, 20, 30, 45];
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const {workerSlug, companySlug} = useParams();

    const workerId = workerSlug ? workerSlug.split("-").pop() : null;

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const [workerResponse, responseNotifications] = await Promise.all([axios.get(`${Config.URL.BASE}/api/workers/${workerId}`, distractRequestGetOptions(currentUser)), axios.get(`${Config.URL.BASE}/api/notifications?workerId=${workerId}&page=${currentPage}&perPage=${perPage}`, distractRequestGetOptions(currentUser)),]);
                setWorker(workerResponse.data);
                setNotifications(responseNotifications.data.content);
                setTotalPages(responseNotifications.data.totalPages);
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [workerId, currentPage, perPage]);

    const handleStatusFunction = useCallback(async (isAllowed) => {
        const endpoint = isAllowed ? `${Config.URL.BASE}/api/notifications/subscribe?workerId=${workerId}` : `${Config.URL.BASE}/api/notifications/unsubscribe?workerId=${workerId}`;

        try {
            const response = await fetch(endpoint, {
                method: "POST", headers: distractRequestHeaders(currentUser),
            });

            if (response.ok) {
                window.location.reload();
            } else {
                toast.error("Error");
            }
        } catch (error) {
            console.error(error);
        }
    }, [currentUser, workerId]);

    const removeAllNotifications = async () => {
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await fetch(`${Config.URL.BASE}/api/notifications/${workerId}`, {
                method: 'DELETE', headers: distractRequestHeaders(currentUser),
            });
            toast.success('Worker\'s notifications are removed successfully!', {autoClose: 2000}); // Adjust the autoClose duration as needed
            setNotifications([]);
            setShowDeleteConfirmation(false)
            window.location.reload();
        } catch (error) {
            toast.error('An error occurred. Please try again.', {autoClose: 2000});
            setShowDeleteConfirmation(false)
        }
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setPerPage(event.target.value);
        setCurrentPage(1);
    };

    return (<Col xs={10} md={10} className={'worker-content'}>
            {!loading && (<>
                <Row>
                    <div className="notification-page-title">
                        {`Powiadomienia ${worker.personDetails.firstName}a `}
                        <span style={worker.notificationAllowed ? {} : {
                            backgroundColor: "#ff7070", color: "#fff"
                        }}>
                                    {worker.notificationAllowed ? "Aktywny" : "Nieaktywny"}
                                </span>
                    </div>
                    {notifications.length === 0 ? <div className={"notifications-empty-content"}>
                        <p>No notifications were found</p>
                    </div> : (<div>
                        {notifications.map((notification, index) => (<Notification
                            companySlug={companySlug}
                            workerId={notification.workerId}
                            isSeen={notification.usersId.includes(currentUser.id)}
                            notification={notification}
                            key={index}/>))}
                    </div>)}

                </Row>
                {notifications.length !== 0 && getPaginationFunction(handlePageChange, currentPage, totalPages, perPage, handlePerPageChange, PAGE_SIZES, notifications.length)}

                <Col className="mt-5" style={{display: "flex", gap: "15px"}}>

                    {!worker.notificationAllowed && (<div
                        className="button-block square-with-text"
                        onClick={() => handleStatusFunction(true)}
                    >
                        <div>
                            <Flash/>
                        </div>
                    </div>)}

                    {worker.notificationAllowed && (<div
                        className="button-block square-with-text"
                        onClick={() => handleStatusFunction(false)}
                    >
                        <div>
                            <FlashSlash/>
                        </div>
                    </div>)}

                    <div style={{width: "90px"}} className="button-block delete-square-with-text"
                         onClick={removeAllNotifications}>
                        <div>
                            <Trash/>
                        </div>
                    </div>
                </Col>
            </>)}
            <ConfirmationModal
                message={"Are you sure you want to remove all notifications?"}
                show={showDeleteConfirmation}
                handleClose={handleCloseDeleteConfirmation}
                handleConfirm={handleConfirmDelete}
            />
        </Col>

    );
};

export default withAuth(WorkerNotifications);
