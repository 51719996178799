import React, {useEffect, useMemo, useState} from "react";
import "./assets/css/company.css";
import {NavLink} from "react-router-dom";
import AuthService from "../../services/auth.service";
import {getIsActive, getLogoURL, isLogoPresent} from "../../services/utility";
import {fetchCompanyLogo} from "../../services/api-calls";

const CompanySliderComponent = ({company, navigationLinkBase}) => {
    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);

    const [loading, setLoading] = useState(true);
    const [logoData, setLogoData] = useState(null);

    useEffect(() => {
        setLoading(true);

        fetchCompanyLogo(currentUser, company.id)
            .then((data) => {
                setLogoData(data);
                setLoading(false);
            });

    }, [company.slug, company.id, currentUser]);

    const logoUrl = getLogoURL(logoData);

    return (!loading && (<NavLink
        exact
        to={`${navigationLinkBase}/${company.slug}`}
        className="company-notification-button"
        activeClassName="active"
        isActive={() => getIsActive(`/${company.slug}`, company)}
    >
        <div className="company-container">
            <div className="company-logo-container" style={{paddingTop: "4px"}}>
                {isLogoPresent(logoData) ? (<img
                    className="company-logo"
                    src={logoUrl}
                    alt={`Logo for ${company.companyName}`}
                />) : (<div className="dummy-company-logo"/>)}
            </div>
            <div className="company-text-container" style={{paddingTop: "4px"}}>
                <p>{company.companyName}</p>
            </div>
        </div>
    </NavLink>));
};

export default CompanySliderComponent;
