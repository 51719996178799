import React from 'react';
import '../assets/css/bill.css';
import {formatBillNumber} from "../../../../services/utility";

const BillEdit = ({bill, bills, index, worker, setWorker, setIsValidToUpdate}) => {

    const setBills = (updatedBills) => {
        setWorker({
            ...worker, bills: updatedBills,
        });
        setIsValidToUpdate(true);
    };

    const handleCurrencyChange = (e) => {
        const updatedBill = {...bill, kindOfCurrency: e.target.innerText};
        const updatedBills = [...bills];
        updatedBills[index] = updatedBill;
        setBills(updatedBills);
    };

    const handleNumberChange = (e) => {
        const updatedBill = {...bill, number: e.target.innerText};
        const updatedBills = [...bills];
        updatedBills[index] = updatedBill;
        setBills(updatedBills);
    };

    const handleRemoveBill = () => {
        const updatedBills = [...bills];
        updatedBills.splice(index, 1);
        setBills(updatedBills);
    };

    // Function to format the bill number with 4 integers separated by spaces

    return (<div className="worker-card rachunek-card animate__animated animate__fadeIn">
        <label>Rachunek {bill.kindOfCurrency}</label>
        <div
            style={{marginTop: '-4px'}}
            contentEditable={true}
            onBlur={handleCurrencyChange}
            suppressContentEditableWarning={true}
        >
            {bill.kindOfCurrency}
        </div>

        <label className={'mt-3'}>Number</label>
        <div
            style={{marginTop: '-4px'}}
            contentEditable={true}
            onBlur={handleNumberChange}
        >
            {formatBillNumber(bill.number)}
        </div>
        <button className={"add-button-style mt-1"} type="button" onClick={handleRemoveBill}>
            -
        </button>
    </div>);
};

export default BillEdit;
