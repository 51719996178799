import React, {useRef, useState} from "react";
import {useDrag} from "react-dnd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './certifications.css';
import SquareWithTextCertification from "../../components/square/SquareWithTextCertification";
import formatDate from "../../components/square/formatDate";
import {OverlayFun} from "../../components/Overlay";
import {unsecuredCopyToClipboard} from "../../services/utility";

const CertificationBlock = ({certificate}) => {

    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;


    const [{isDragging}, drag] = useDrag(() => ({
        type: 'CERTIFICATION_BLOCK', item: {certificate}, collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    const endDate = formatDate(certificate.certificateDateEnd);
    const differenceInDays = Math.floor((endDate - new Date()) / (1000 * 60 * 60 * 24));
    const [certificateDate, setCertificateDate] = useState("end");

    const handleClick = () => {
        setCertificateDate(certificateDate === "end" ? "powna" : "end");
    };

    const style = {
        opacity: isDragging ? 0.5 : 1, paddingLeft: "12px", cursor: "pointer"
    };

    return (<div ref={drag} style={style}>
        <Row className={"animate__animated animate__bounceIn"}>
            <SquareWithTextCertification
                differenceInDays={differenceInDays}
                text={certificate.categoryLevel}
                isLastOne={certificate.isLastOne}
            />
            <Col>
                <Row>
                    <Col xs={4} md={4}>
                        <div className="certification-text">
                            <div>
                                <h5>Ważny (dni)</h5>
                                <p>{(differenceInDays < 0) ? "wygasła" : differenceInDays}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={8} md={8}>
                        <div className="certification-text">
                            <div>
                                <h5>Numer</h5>
                                <p
                                    onClick={(event) => unsecuredCopyToClipboard(event, certificate.certificateNumber, setOverlayState, handleHide)}
                                    style={{cursor: "pointer"}}
                                >{certificate.certificateNumber}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12}>
                        <div className="certification-text">
                            <div>
                                <h5 onClick={handleClick} style={{cursor: "pointer"}}>
                                    {certificateDate === "end" ? "Data ważności" : "Data powna"}
                                </h5>
                                <p onClick={(event) => unsecuredCopyToClipboard(event, certificateDate === "end" ? certificate.certificateDateEnd : (certificate.certificateDateStart + ' - ' + certificate.certificateDateEnd), setOverlayState, setOverlayState, handleHide)}>
                                    {certificateDate === "end" ? certificate.certificateDateEnd : (certificate.certificateDateStart + ' - ' + certificate.certificateDateEnd)}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </div>);
};

export default CertificationBlock;
