import React, {useEffect, useState} from 'react';
import {checkDateExpiration} from "../../../../services/utility";

const ContractEdit = ({contract, setContract, setIsValidToUpdate, setContractUpdated}) => {

    const [contractTitle, setContractTitle] = useState(contract ? (contract.contractTitle === '' ? "Umowa o pracę" : contract.contractTitle) : "");
    const [hireDate, setHireDate] = useState(contract ? contract.hireDate : "");
    const [contractDateStart, setContractDateStart] = useState(contract ? contract.contractDateStart : "");
    const [contractDateEnd, setContractDateEnd] = useState(contract ? contract.contractDateEnd : "");
    const [termless, setTermless] = useState(contract ? contract.isTermless : false);

    useEffect(() => {
        setContract({
            ...contract,
            id: contract ? contract.id : "",
            contractTitle: contractTitle,
            hireDate: hireDate,
            contractDateStart: contractDateStart,
            contractDateEnd: contractDateEnd,
            isTermless: termless,
        });
        setIsValidToUpdate(true);
    }, [hireDate, contractDateStart, contractDateEnd, contractTitle, termless]);

    const handleHireDateChange = (e) => {
        setHireDate(e.target.innerText);
        setContractUpdated(true)
    };

    const handleContractDateStartChange = (e) => {
        setContractDateStart(e.target.innerText);
        setContractUpdated(true)
    };

    const handleContractDateEndChange = (e) => {
        setContractDateEnd(e.target.innerText);
        setContractUpdated(true)
    };

    const handleContractTitleChange = (e) => {
        setContractTitle(e.target.innerText);
        setContractUpdated(true)
    };

    const handleTermlessChange = (state) => {
        setTermless(state);
        if (state) {
            setContractDateEnd(null)
        }
        setContractUpdated(true)
    };

    const contractExpirationColor = checkDateExpiration(contractDateEnd);

    return (<div className="contract">
        <h4 contentEditable={true}
            onBlur={handleContractTitleChange}
            suppressContentEditableWarning={true}>{contractTitle}</h4>
        <div>
            <h5>Data zawarcia</h5>
            <p style={{width: '109px'}} contentEditable={true}
               onBlur={handleHireDateChange}
               suppressContentEditableWarning={true}>
                {hireDate}
            </p>
        </div>
        <div style={{color: contractExpirationColor}}>
            <h5>Okres</h5>
            <div
                style={{width: "109px", display: 'inline-block'}}
                contentEditable={true}
                onBlur={handleContractDateStartChange}
                suppressContentEditableWarning={true}
            >{contractDateStart}</div>
            -
            <div
                style={{
                    width: "109px",
                    display: 'inline-block',
                    marginLeft: '10px',
                    marginRight: `${termless ? "28px" : "18px"}`
                }}
                contentEditable={!termless}
                onBlur={handleContractDateEndChange}
                suppressContentEditableWarning={true}
            >{termless ? "nieokreślony" : contractDateEnd}
            </div>
            <div style={{width: "109px", display: 'inline-block', position: "relative", top: "8px"}}>
                <div className={"checkbox-wrapper"}>
                    <div className="checkbox-wrapper-57">
                        <label className="container">
                            <input type="checkbox" checked={termless}
                                   onChange={(e) => handleTermlessChange(!termless)}/>
                            <div className="checkmark"></div>
                        </label>
                    </div>
                    <span className={termless ? "wrapper-active" : "wrapper-disabled"}>Nieokreślony</span>
                </div>
            </div>

        </div>
    </div>);
};

export default ContractEdit;
