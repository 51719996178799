import React from "react";
import {useDrag} from "react-dnd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import compareVacationDates from "../square/compareVacationDates";
import SquareWithText from "../square/SquareWithText";
import getMonthAbbreviations from "../square/getMonthAbbreviations";
import './assets/css/vacations.css'

const DraggableVacationBlock = ({vacation}) => {

    const [{isDragging}, drag] = useDrag(() => ({
        type: 'VACATION_BLOCK', item: {vacation}, collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const {prevStartDate, currentStartDate} = compareVacationDates(vacation, vacation);

    const currentDate = new Date();
    const isCurrentDate = prevStartDate <= currentDate && currentStartDate >= currentDate;

    const style = {
        opacity: isDragging ? 0.5 : 1, paddingLeft: "12px", cursor: "pointer"
    };

    return (<div ref={drag} style={style}>
        <Col xs={4} md={4} className={"mb-3"}>
            <div key={vacation.id}>
                <Row className={"vacation-block animate__animated animate__bounceIn"}>
                    <SquareWithText isCurrent={isCurrentDate}
                                    text={getMonthAbbreviations(vacation.vacationDateStart, vacation.vacationDateEnd)}
                                    isPaid={vacation.paid}/>
                    <Col>
                        <div className={"vacation-text"} key={vacation.id}>
                            <div>
                                <h5>Czas trwania (dni)</h5>
                                <p>{vacation.workingDays}</p>
                            </div>
                            <div>
                                <h5>Okres</h5>
                                <p style={{cursor: "pointer"}}>{vacation.vacationDateStart} - {vacation.vacationDateEnd}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    </div>);
}

export default DraggableVacationBlock;