import React, {useEffect, useState} from 'react';
import {showToast, validatePesel} from '../../../../services/utility';
import {toast} from 'react-toastify';

const PeselEdit = ({worker, setWorker, setIsValidToUpdate}) => {
    const [peselNumber, setPeselNumber] = useState(worker.peselNumber || '');
    const [toastId, setToastId] = useState(null);
    const [peselNumberValid, setPeselNumberValid] = useState(true);

    useEffect(() => {
        const validateAndUpdatePesel = () => {
            let isAllFieldsValid = true;

            if (!/^\d+$/.test(peselNumber) && peselNumber.length > 0) {
                showToast('Pesel should contain only numbers', toastId, setToastId);
                setPeselNumberValid(false);
                isAllFieldsValid = false;
            } else if (peselNumber.length === 11) {
                if (validatePesel(peselNumber.trim())) {
                    toast.success('Pesel is valid');
                    setPeselNumberValid(true);
                } else {
                    showToast('Pesel is not valid', toastId, setToastId);
                    setPeselNumberValid(false);
                    isAllFieldsValid = false;
                }
            } else if (peselNumber.length > 11) {
                showToast('Pesel cannot exceed 11 characters', toastId, setToastId);
                setPeselNumberValid(false);
                isAllFieldsValid = false;
            }

            if (isAllFieldsValid) {
                setWorker({
                    ...worker, peselNumber: peselNumber.trim(),
                });
                setIsValidToUpdate(true);
            } else {
                setIsValidToUpdate(true);
            }
        };

        validateAndUpdatePesel();
    }, [peselNumber]);

    const handlePeselChange = (e) => {
        setPeselNumber(e.target.innerText);
    };

    return (<div className={`worker-card pesel ${!peselNumberValid ? 'invalid' : ''}`}>
        <label>Pesel</label>
        <div
            contentEditable={true}
            onBlur={handlePeselChange}
            suppressContentEditableWarning
        >
            {peselNumber}
        </div>
    </div>);
};

export default PeselEdit;
