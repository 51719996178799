import axios from "axios";
import Config from "../config";
import {distractRequestGetOptions} from "./utility";
import {toast} from "react-toastify";

export const fetchWorkersVacationsV2 = async (currentUser, companyId, currentYear, currentMonth, page, perPage, filterValue) => {
    try {
        // Initialize an empty array to store query parameters
        const queryParams = [];

        if (filterValue !== null && filterValue !== undefined) {
            queryParams.push(`firstnameAndLastname=${filterValue}`);
        }

        if (perPage !== null && perPage !== undefined) {
            queryParams.push(`perPage=${perPage}`);
        }

        if (page !== null && page !== undefined) {
            queryParams.push(`page=${page}`);
        }

        if (companyId !== null && companyId !== undefined) {
            queryParams.push(`companyId=${companyId}`);
        }

        if (currentYear !== null && currentYear !== undefined) {
            queryParams.push(`year=${currentYear}`);
        }

        if (currentMonth !== null && currentMonth !== undefined) {
            queryParams.push(`month=${currentMonth}`);
        }

        const queryString = queryParams.join('&');

        const url = `${Config.URL.BASE}/api/workers/vacations/filter/v2${queryString ? `?${queryString}` : ''}`;

        const response = await axios.get(url, distractRequestGetOptions(currentUser));
        return response.data;
    } catch (error) {
        console.error(error.message);
    }
};

export const fetchWorkersAPI = async (currentUser, companyId, page, perPage, firstnameAndLastname, isFiredFiltering, sortedAttribute) => {
    try {
        const queryParams = [];

        queryParams.push(`companyId=${companyId}`);

        if (page !== null && page !== undefined) {
            queryParams.push(`page=${page}`);
        }

        if (perPage !== null && perPage !== undefined) {
            queryParams.push(`perPage=${perPage}`);
        }

        if (firstnameAndLastname !== null && firstnameAndLastname !== undefined) {
            queryParams.push(`firstnameAndLastname=${firstnameAndLastname}`);
        }

        if (isFiredFiltering !== null && isFiredFiltering !== undefined) {
            queryParams.push(`isFiredFiltering=${isFiredFiltering}`);
        }

        // Add 'year' parameter if 'currentYear' is defined
        if (sortedAttribute !== null && sortedAttribute !== undefined) {
            queryParams.push(`sortedAttribute=${sortedAttribute}`);
        }

        const queryString = queryParams.join('&');

        const url = `${Config.URL.BASE}/api/workers/v3${queryString ? `?${queryString}` : ''}`;

        const response = await axios.get(url, distractRequestGetOptions(currentUser));
        return response.data;
    } catch (error) {
        console.error(error.message);
    }
};

export const fetchAllWorkersAPI = async (currentUser, page, perPage, firstnameAndLastname, isFiredFiltering, sortedAttribute) => {
    try {
        const queryParams = [];

        if (page !== null && page !== undefined) {
            queryParams.push(`page=${page}`);
        }

        if (perPage !== null && perPage !== undefined) {
            queryParams.push(`perPage=${perPage}`);
        }

        if (firstnameAndLastname !== null && firstnameAndLastname !== undefined) {
            queryParams.push(`firstnameAndLastname=${firstnameAndLastname}`);
        }

        if (isFiredFiltering !== null && isFiredFiltering !== undefined) {
            queryParams.push(`isFiredFiltering=${isFiredFiltering}`);
        }

        // Add 'year' parameter if 'currentYear' is defined
        if (sortedAttribute !== null && sortedAttribute !== undefined) {
            queryParams.push(`sortedAttribute=${sortedAttribute}`);
        }

        const queryString = queryParams.join('&');

        const url = `${Config.URL.BASE}/api/workers/all${queryString ? `?${queryString}` : ''}`;

        const response = await axios.get(url, distractRequestGetOptions(currentUser));
        return response.data;
    } catch (error) {
        console.error(error.message);
    }
};


export const fetchDocumentsByWorkerIdAPI = async (currentUser, workerId, filename, sortedAttribute) => {
    try {
        const queryParams = [];

        if (workerId !== null && workerId !== undefined) {
            queryParams.push(`workerId=${workerId}`);
        }

        if (filename !== null && filename !== undefined) {
            queryParams.push(`filename=${filename}`);
        }

        if (sortedAttribute !== null && sortedAttribute !== undefined) {
            queryParams.push(`sortedAttribute=${sortedAttribute}`);
        }

        const queryString = queryParams.join('&');

        const url = `${Config.URL.BASE}/api/workers/documents${queryString ? `?${queryString}` : ''}`;

        const response = await axios.get(url, distractRequestGetOptions(currentUser));
        return response.data;
    } catch (error) {
        console.error(error.message);
    }
};

export const fetchCompanies = async (currentUser) => {
    try {
        const response = await axios.get(`${Config.URL.BASE}/api/companies`, distractRequestGetOptions(currentUser));
        return response.data;
    } catch (error) {
        console.error(error.message);
    }
}

export const fetchDelegations = async (workerId, year, month, currentUser) => {
    const queryParams = [];

    if (year !== null && year !== undefined) {
        queryParams.push(`year=${year}`);
    }

    if (month !== null && month !== undefined) {
        queryParams.push(`month=${month}`);
    }

    queryParams.push(`workerId=${workerId}`);

    const queryString = queryParams.join('&');

    const url = `${Config.URL.BASE}/api/workers/vacations/delegations${queryString ? `?${queryString}` : ''}`;

    try {
        const response = await axios.get(url, distractRequestGetOptions(currentUser));
        return response.data;
    } catch (error) {
        console.error(error.message);
    }
}

export const handleApiRequest = async (url, method, currentUser, h) => {
    const headers = {
        'X-XSRF-TOKEN': currentUser.csrfToken,
        Authorization: 'Bearer ' + currentUser.token,
        'Content-type': 'application/json',
    };

    const response = await fetch(url, {
        method, headers,
    });

    if (method === 'DELETE') {
        if (response.ok) {
            window.location.reload()
        } else {
            toast.error('Error happened');
        }
    }
};

export const fetchCompanyLogo = async (currentUser, companyId) => {
    try {
        const response = await fetch(`${Config.URL.BASE}/api/companies/companyLogo/${companyId}`, distractRequestGetOptions(currentUser));
        return await response.arrayBuffer();
    } catch (error) {
        console.error(error);
    }
}

export const fetchMetaData = async (perPage, currentPage, currentUser) => {
    const response = await axios.get(`${Config.URL.BASE}/api/notifications/meta-data?perPage=${perPage}&page=${currentPage}`, distractRequestGetOptions(currentUser));
    return await response.data.totalPages;
};

export const fetchWorkersV1 = async (companyId, currentUser) => {
    const response = await axios.get(`${Config.URL.BASE}/api/workers/v1?companyId=${companyId}`, distractRequestGetOptions(currentUser));
    return await response.data;
};

export const fetchNotificationsByWorkerId = async (workerId, perPage, currentPage, currentUser) => {
    return await axios.get(`${Config.URL.BASE}/api/notifications?workerId=${workerId}&perPage=${perPage}&page=${currentPage}`, distractRequestGetOptions(currentUser));
};

export const fetchNotificationsByCompany = async (companyId, perPage, currentPage, currentUser) => {
    return await axios.get(`${Config.URL.BASE}/api/notifications/company/${companyId}?perPage=${perPage}&page=${currentPage}`, distractRequestGetOptions(currentUser));
};

export const fetchUsers = async (perPage, currentPage, username, currentUser) => {
    const response = await axios.get(`${Config.URL.BASE}/api/users?perPage=${perPage}&username=${username}&page=${currentPage}`, distractRequestGetOptions(currentUser));
    return response.data;
};