import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import {distractRequestGetOptions} from "../../services/utility";
import AuthService from "../../services/auth.service";
import CertificationBlock from "./DraggableCertificationBlock";
import "animate.css";
import AddComponent from "../../components/forms/modals/AddComponent";
import CertificationModal from "../../components/forms/modals/strategies/CertificationModal";
import EditPlace from "../../components/placesToDrag/EditPlace";
import DeletePlace from "../../components/placesToDrag/DeletePlace";
import {useParams} from "react-router-dom";
import withAuth from "../../services/hooks/withAuth";
import Config from "../../config";
import {toast} from "react-toastify";
import NotFound from "../404page/NotFound";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const Certifications = () => {
    const currentUser = AuthService.getCurrentUser();

    const [certifications, setCertifications] = useState([]);
    const [loading, setLoading] = useState(false)
    const [is404, setIs404] = useState(false);
    const {workerSlug} = useParams();

    let workerId = null;
    if (workerSlug) {
        workerId = workerSlug.split('-').pop();
    }

    useEffect(() => {
        setLoading(true)
        const fetchCertifications = async () => {
            try {
                const response = await axios.get(`${Config.URL.BASE}/api/workers/certifications?workerId=${workerId}`, distractRequestGetOptions(currentUser)).catch(v => {
                    if (v.response.data.statusCode === 404) {
                        setIs404(true);
                    }
                });
                setCertifications(response.data);
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoading(false)
            }
        };

        fetchCertifications();
    }, [workerId]);


    if (is404) {
        return <NotFound/>;
    }

    return (<DndProvider backend={HTML5Backend}>
        <Col style={{paddingLeft: "65px", marginTop: "45px", marginBottom: "100px"}}>
            {certifications.length === 0 && !loading &&
                <div className={"worker-empty-content animate__animated animate__fadeIn"}>
                    <p>No existing certifications were located. Let's introduce a fresh one! 🧠️</p>
                    <div>
                        <AddComponent workerId={workerId} modal={CertificationModal}/>
                    </div>
                </div>}
            {certifications.length > 0 && !loading && <>
                <h3 className="mb-4 worker-page-title" style={{marginLeft: "-12px"}}>
                    A1
                </h3>
                <Row>
                    {certifications.map((certification) => (<Col xs={6} md={6} className="mb-3" key={certification.id}>
                        <CertificationBlock certificate={certification}/>
                    </Col>))}
                    <Col xs={6} md={6} className="mb-3">
                        <div style={{marginLeft: "-12px"}}>
                            <AddComponent workerId={workerId} modal={CertificationModal}/>
                        </div>
                    </Col>
                </Row>

                <Col className="mt-5" style={{marginLeft: "-10px", display: "flex", gap: "20px"}}>
                    <div>
                        <EditPlace workerId={workerId} modal={CertificationModal}/>
                    </div>

                    <div>
                        <DeletePlace workerId={workerId} modal={CertificationModal}/>
                    </div>
                </Col>
            </>}
        </Col></DndProvider>);
};

export default withAuth(Certifications);
