import React, {useEffect, useState} from 'react';
import '../assets/css/contacts.css';

const ContactsEdit = ({worker, setWorker, setIsValidToUpdate}) => {
    const [emailAddress, setEmail] = useState(worker.email);
    const [phonesNumbers, setPhonesNumbers] = useState(worker.phones);
    const [telegramUsername, setTelegramUsername] = useState(worker.telegram);

    useEffect(() => {
        setWorker({...worker, email: emailAddress, phones: phonesNumbers, telegram: telegramUsername});
        setIsValidToUpdate(true);
    }, [emailAddress, phonesNumbers, telegramUsername]);

    const handlePhoneChange = (index, e) => {
        const updatedPhones = [...phonesNumbers];
        updatedPhones[index] = e.target.innerText;
        setPhonesNumbers(updatedPhones);
    };

    const handlePhoneRemove = (index) => {
        const updatedPhones = [...phonesNumbers];
        updatedPhones.splice(index, 1);
        setPhonesNumbers(updatedPhones);
    };

    const handlePhoneAdd = () => {
        setPhonesNumbers([...phonesNumbers, '']);
    };

    const handleInputChange = (setValue) => (e) => {
        setValue(e.target.innerText);
    };

    return (<>
        <div className="worker-card relative-block mt-4">
            <label>Email</label>
            <div
                contentEditable={true}
                onBlur={handleInputChange(setEmail)}
                suppressContentEditableWarning={true}
            >
                {emailAddress}
            </div>
        </div>
        <div className="contacts-phones">
            {phonesNumbers.map((phone, index) => (
                <div className="worker-card contact-block animate__animated animate__fadeIn" key={index}>
                    <label>Telephone</label>
                    <div
                        contentEditable={true}
                        onBlur={(e) => handlePhoneChange(index, e)}
                        suppressContentEditableWarning={true}
                    >
                        {phone}
                    </div>
                    <button
                        className="add-button-style mt-2"
                        onClick={() => handlePhoneRemove(index)}
                    >
                        -
                    </button>
                </div>))}
        </div>
        <button className="add-button-style" style={{marginLeft: "5px"}} onClick={handlePhoneAdd}>
            +
        </button>
        <div className="worker-card relative-block">
            <label>Telegram</label>
            <div
                contentEditable={true}
                onBlur={handleInputChange(setTelegramUsername)}
                suppressContentEditableWarning={true}
            >
                {telegramUsername}
            </div>
        </div>
    </>);
};

export default ContactsEdit;
