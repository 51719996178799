import React, {useEffect, useState} from 'react';
import {Col, Form, Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../../../../services/auth.service';
import '../../style.css';
import Config from '../../../../../config';
import {distractRequestGetOptions} from "../../../../../services/utility";
import axios from "axios";
import DocumentBlock from "../../../../documents/DocumentBlock";
import DocumentLoader from "../loader/DocumentLoader";
import {ReactComponent as Edited} from "../../../../../assets/svgs/magic-pencil.svg";
import {useNavigate} from "react-router-dom";

const DocumentUpdateModal = ({componentId, workerId, show, success, setSuccess, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [modifiedFile, setModifiedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isCheckingDocument, setIsCheckingDocument] = useState(false)
    const navigate = useNavigate();
    const [isRotating, setIsRotating] = useState(false); // State to handle the animation class

    useEffect(() => {
        const handleKeyboardEvent = (event) => {
            if (event.key === 'Enter') {
                // Submit the form when Enter is pressed\
                handleSubmit(event);
            } else if (event.key === 'Escape') {
                // Close the modal when Escape is pressed
                handleClose();
            }
        };

        // Attach the event listener when the modal is shown
        if (show) {
            window.addEventListener('keydown', handleKeyboardEvent);
        }

        // Clean up the event listener when the modal is hidden
        return () => {
            window.removeEventListener('keydown', handleKeyboardEvent);
        };
    }, [show, loading, handleClose, fileName]);

    useEffect(() => {
        const fetchData = async () => {
            if (componentId) {
                try {
                    const response = await axios.get(`${Config.URL.BASE}/api/workers/documents/${componentId}`, distractRequestGetOptions(currentUser));
                    const file = response.data;
                    setFile(file);
                    setFileName(file.filename)
                } catch (error) {
                    console.error(error.message);
                }
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const handleSuccess = async () => {
            if (success) {
                const headers = {
                    'X-XSRF-TOKEN': currentUser.csrfToken, Authorization: 'Bearer ' + currentUser.token
                };

                if (componentId !== undefined) {
                    setLoading(true)
                    await fetch(`${Config.URL.BASE}/api/workers/documents?documentId=${componentId}&filename=${modifiedFile.name}`, {
                        method: 'PUT', headers: headers
                    }).then(response => {
                        if (response.ok) {
                            toast.success('Document is updated successfully!', {autoClose: 2000});
                        } else {
                            response.json().then(errorData => {
                                if (errorData.message) {
                                    toast.error(errorData.message);
                                } else {
                                    toast.error('An error occurred. Please try again.');
                                }
                            });
                        }
                    }).catch(() => {
                        toast.error('An error occurred. Please check your internet connection and try again.');
                    }).finally(() => {
                        setLoading(false);
                        handleClose();
                        window.location.reload();
                    });
                }
            }
        };

        handleSuccess();
    }, [success]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const correctExtension = file.filename.split('.').slice(-1)[0];
        const currentExtension = fileName.split('.').slice(-2, -1)[0];


        let finalFileName;
        if (currentExtension === correctExtension) {
            finalFileName = fileName;
        } else {
            const lastDotIndex = fileName.lastIndexOf('.');
            if (lastDotIndex !== -1) {
                finalFileName = fileName.substring(0, lastDotIndex) + '.' + correctExtension;
            } else {
                finalFileName = fileName + '.' + correctExtension;
            }
        }

        setModifiedFile(new File([file], finalFileName, {
            type: file.type, lastModified: file.lastModified,
        }));

        if (isCheckingDocument) {
            return;
        }
        setIsCheckingDocument(true);

        try {
            const response = await fetch(`${Config.URL.BASE}/api/workers/documents/exists?workerId=${workerId}&filename=${finalFileName}`, distractRequestGetOptions(currentUser));
            const data = await response.json();
            if (response.ok) {
                if (data === false) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                    toast.error('There is the error');
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsCheckingDocument(false);
        }
    };

    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Zaktualizuj dokument</Modal.Title>
            </Modal.Header>
            {loading && <div style={{height: "250px", marginLeft: "-20px"}} className={"empty-content"}>
                <DocumentLoader/>
            </div>}
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div style={{paddingRight: '60px', paddingLeft: '60px', paddingTop: '0px', paddingBottom: '30px'}}>
                        {!loading && file !== null && <>
                            <div style={{fontFamily: "MyInter", marginLeft: "12px"}}><DocumentBlock document={file}
                                                                                                    customFileName={fileName}
                                                                                                    editMode={true}/>
                            </div>
                            <div className={"mt-4"} style={{display: "flex", gap: "15px", alignItems: "center"}}>
                                <Form.Group as={Col} md="6" controlId="formFileNamey">
                                    <Form.Label className="mt-2 create-worker-label">Nazwa</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Murarz"
                                        name="speciality"
                                        className="input-field"
                                        value={fileName}
                                        onChange={(e) => setFileName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Control.Feedback type="invalid">Please enter a file name.</Form.Control.Feedback>
                                <div style={{marginTop: "40px"}}>
                                    {(fileName !== file.filename || isRotating) && <>
                                        <div
                                            className={`animate__animated cursor_pointer ${isRotating ? 'animate__rotateOut' : 'animate__tada'}`}
                                            onClick={() => {
                                                setFileName(file.filename);
                                                setIsRotating(true); // Trigger the animation
                                                setTimeout(() => setIsRotating(false), 1000); // Reset animation class after 1 second
                                            }}>
                                            <Edited width={"35px"} height={"35px"}/>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-reject" onClick={handleClose}>
                        Anuluj
                    </button>
                    <button className="button-submit glow-on-hover" type="submit">
                        Zapisz
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>);
};

export default DocumentUpdateModal;