import React, {useEffect, useState} from 'react';
import {ReactComponent as Search} from '../../../assets/svgs/Search.svg';
import './assets/css/document-square.css';
import {distractRequestGetOptions} from "../../../services/utility";
import AuthService from "../../../services/auth.service";
import Config from "../../../config";
import DocumentLoader from "../../forms/modals/strategies/loader/DocumentLoader";

const DocumentSquare = ({linkPreview, documentId, isVertical}) => {
    const currentUser = AuthService.getCurrentUser();
    const [thumbnail, setThumbnail] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                fetch(`${Config.URL.BASE}/api/workers/documents/thumbnail/${documentId}`, distractRequestGetOptions(currentUser))
                    .then((response) => response.arrayBuffer())
                    .then((data) => setThumbnail(data))
                    .catch((error) => console.error(error));
            } catch (error) {
                console.error(error.message);
            }
        };

        fetchData();
    }, [documentId]);

    const previewPdf = async () => {
        try {
            const response = await fetch(linkPreview, {
                method: 'GET', headers: {
                    'X-XSRF-TOKEN': currentUser.csrfToken, 'Authorization': `Bearer ${currentUser.token}`
                }
            });

            if (response.ok) {
                const blob = await response.blob();

                // Create a URL for the Blob data
                const url = URL.createObjectURL(blob);

                // Open the Blob URL in a new window
                window.open(url, '_blank');
            } else {
                throw new Error('Failed to preview PDF');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const logoUrl = `data:image/png;base64,${btoa(new Uint8Array(thumbnail).reduce((data, byte) => data + String.fromCharCode(byte), ""))}`;

    return (<>

        <div
            style={{
                width: `${isVertical ? '165px' : '225px'}`,
                height: `${isVertical ? '235px' : '155px'}`,
                background: `linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url(${logoUrl}) center/cover no-repeat`,
            }}
            className={"thumbnail-block"}
            onClick={previewPdf} // Attach the download function to the click event>>
        >
            <div className={'thumbnail-download-svg'}>
                {thumbnail && <Search/>}
                {!thumbnail && <div style={{marginTop: "-25px", marginLeft: "-30px"}}><DocumentLoader/></div>}
            </div>
        </div>
    </>);
};


export default DocumentSquare;