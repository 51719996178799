import React, {useState} from "react";

const AddComponent = ({workerId, modal: ModalComponent}) => {
    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleIconClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const blockTextStyle = {
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: "20px",
        fontSize: "18px",
        color: "#797979",
    };

    return (<>
        <div
            className="square-with-text block-style"
            onClick={handleIconClick}
        >
            <div style={blockTextStyle}>
                +
            </div>
        </div>
        <div>
            {showModal && (
                <ModalComponent success={success} setSuccess={setSuccess} workerId={workerId} show={showModal}
                                handleClose={handleCloseModal}/>)}
        </div>

    </>);
};

export default AddComponent