import React from "react";
import {Col, Row} from "react-bootstrap";
import SquareWithText from "../square/SquareWithText";
import getMonthAbbreviations from "../square/getMonthAbbreviations";

// If there is a current vacation, return it
// If not, then find the nearest future vacation
// or Find the nearest past vacation
function WorkerDisplayVacation({vacations}) {
    if (vacations.length === 0) {
        return;
    }

    const currentDate = new Date();

    function formatDate(dateStr) {
        try {
            const [day, month, year] = dateStr.split(".");
            // Ensure day, month, and year are valid numbers
            const parsedDay = parseInt(day, 10);
            const parsedMonth = parseInt(month, 10);
            const parsedYear = parseInt(year, 10);

            if (
                !isNaN(parsedDay) &&
                !isNaN(parsedMonth) &&
                !isNaN(parsedYear) &&
                parsedMonth >= 1 && parsedMonth <= 12 &&
                parsedDay >= 1 && parsedDay <= 31
            ) {
                // Return a Date object if the components are valid
                return new Date(`${parsedYear}-${parsedMonth}-${parsedDay}`);
            } else {
                throw new Error("Invalid date components");
            }
        } catch (error) {
            console.error("Error parsing date:", error.message);
            return null; // Return null in case of an error
        }
    }

    function getMonthAbbreviation(date) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthNames[date.getMonth()].substring(0, 3);
    }

    const currentVacations = vacations.filter(vacation => {
        const startDate = formatDate(vacation.vacationDateStart);
        const endDate = formatDate(vacation.vacationDateEnd);
        return startDate <= currentDate && endDate >= currentDate;
    });

    if (currentVacations.length > 0) {
        const firstVacation = currentVacations[0];

        return (
            <div className={'vacation'}>
                <h4>Urlopy</h4>
                <Row style={{
                    paddingTop: "10px",
                    paddingLeft: '10px',
                    ...(firstVacation.paid && {paddingLeft: "20px", paddingTop: "13px",}), // Add padding bottom if firstVacation.paid is true
                }}>
                    <SquareWithText isCurrent={true}
                                    text={getMonthAbbreviations(firstVacation.vacationDateStart, firstVacation.vacationDateEnd)}
                                    isPaid={firstVacation.paid}/>
                    <Col xs={8} md={8} style={{marginLeft: "10px"}}>
                        <div className="vacation-text">
                            <div>
                                <h5>Czas trwania (dni)</h5>
                                <p>{firstVacation.workingDays}</p>
                            </div>
                            <div>
                                <h5>Okres</h5>
                                <p>{firstVacation.vacationDateStart} - {firstVacation.vacationDateEnd}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }


    const futureVacations = vacations.filter(vacation => formatDate(vacation.vacationDateStart) > currentDate);
    const nearestFutureVacation = futureVacations.reduce((prev, current) => {
        const prevStartDate = formatDate(prev.vacationDateStart);
        const currentStartDate = formatDate(current.vacationDateStart);
        return prevStartDate < currentStartDate ? prev : current;
    }, futureVacations[0]);

    const pastVacations = vacations.filter(vacation => formatDate(vacation.vacationDateEnd) < currentDate);
    const nearestPastVacation = pastVacations.reduce((prev, current) => {
        const prevEndDate = formatDate(prev.vacationDateEnd);
        const currentEndDate = formatDate(current.vacationDateEnd);
        return prevEndDate > currentEndDate ? prev : current;
    }, pastVacations[0]);

    const getVacationElement = (vacation) => {
        const startDate = formatDate(vacation.vacationDateStart);
        const endDate = formatDate(vacation.vacationDateEnd);
        const differenceInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

        return (<div className={'vacation'}>
            <h4>Urlopy</h4>
            <Row style={{
                paddingTop: "10px",
                paddingLeft: '10px',
                ...(vacation.paid && {paddingLeft: "20px", paddingTop: "13px",}), // Add padding bottom if firstVacation.paid is true
            }}>
                <SquareWithText differenceInDays={differenceInDays} text={getMonthAbbreviation(startDate)}
                                isPaid={vacation.paid}/>
                <Col xs={8} md={8} style={{marginLeft: "10px"}}>
                    <div className="vacation-text">
                        <div>
                            <h5>Czas trwania (dni)</h5>
                            <p>{vacation.workingDay}</p>
                        </div>
                        <div>
                            <h5>Okres</h5>
                            <p>{vacation.vacationDateStart} - {vacation.vacationDateEnd}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>);
    };

    if (nearestFutureVacation && nearestPastVacation) {
        const nearestFutureDate = formatDate(nearestFutureVacation.vacationDateStart);
        const nearestPastDate = formatDate(nearestPastVacation.vacationDateEnd);
        const differenceFromFuture = nearestFutureDate - currentDate;
        const differenceFromPast = currentDate - nearestPastDate;

        if (differenceFromFuture < differenceFromPast) {
            return getVacationElement(nearestFutureVacation);
        } else {
            return getVacationElement(nearestPastVacation);
        }
    } else if (nearestFutureVacation) {
        return getVacationElement(nearestFutureVacation);
    } else {
        return getVacationElement(nearestPastVacation);
    }
}

export default WorkerDisplayVacation;
