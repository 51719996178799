import React from "react";
import WorkerSliderComponent from "./WorkerSliderComponent";

const WorkersSlider = ({workers, companySlug, navigationLinkBase}) => {

    return (<div className={"companies-horizontal-block"}>
            <div style={{display: 'flex', flexDirection: 'row', overflowX: 'scroll', alignItems: "center"}}>
                {workers.map((worker, index) => (
                    <WorkerSliderComponent key={index} worker={worker} companySlug={companySlug}
                                           navigationLinkBase={navigationLinkBase}/>))}
            </div>
        </div>

    );
};

export default WorkersSlider;