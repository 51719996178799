import React from "react";
import {useDrag} from "react-dnd";
import Row from "react-bootstrap/Row";
import SquareWithText from "../../components/square/SquareWithText";
import Col from "react-bootstrap/Col";
import getMonthAbbreviation from "../../components/square/getMonthAddreviation";

const AneksBlock = ({aneks}) => {
    const [{isDragging}, drag] = useDrag(() => ({
        type: 'ANEKS_BLOCK', item: {aneks}, collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const style = {
        opacity: isDragging ? 0.5 : 1, paddingLeft: "12px", cursor: "pointer"
    };

    let aneksEndDate = aneks.aneksEndDate;
    let isCurrentDate = true;
    if (aneks.lastOne) {
        aneksEndDate = 'nieokreślony';
    } else {
        isCurrentDate = new Date() > aneks.aneksEndDate;
    }

    return (<div ref={drag} style={style} className={"animate__animated animate__bounceIn"}>
        <Col xs={4} md={4} className={"mb-3"}>
            <Row className={"vacation-block"}>
                <SquareWithText isCurrent={isCurrentDate}
                                text={getMonthAbbreviation(aneks.aneksEndDate)}
                                isPaid={false}/>
                <Col>
                    <div className={"vacation-text"} key={aneks.id}>
                        <div>
                            <h5>Na czas nieokreślony</h5>
                            <p>{aneks.lastOne ? `Tak` : `Nie`}</p>
                        </div>
                        <div>
                            <h5>Ważność do:</h5>
                            <p style={{cursor: "pointer"}}>{aneksEndDate}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    </div>);
};

export default AneksBlock;
