import React from 'react';

const NotFound = () => {
    return (<div style={{textAlign: 'center', paddingTop: '50px'}}>
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <button className="button-submit glow-on-hover mt-2"
                onClick={() => window.location.href = '/'}>
            Go to Home Page
        </button>
    </div>);
};

export default NotFound;
