import React, {useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import {unsecuredCopyToClipboard} from "../../../services/utility";

const Relatives = ({relatives}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    const renderRelativeCard = (title, firstName, lastName) => {
        if (firstName !== null || lastName !== null) {
            return (<div className="worker-card contact-block" key={title}>
                <label>{title}</label>
                <span
                    onClick={(event) => unsecuredCopyToClipboard(event, `${firstName} ${lastName}`, setOverlayState, handleHide)}>
                        {firstName !== null && lastName !== null ? `${firstName} ${lastName}` : (firstName !== null ? firstName : lastName)}

                    </span>
            </div>);
        }
        return null;
    };

    return (<div className="passport-worker">
        {renderRelativeCard('Mama', relatives.motherFirstName, relatives.motherLastName)}
        {renderRelativeCard('Ojciec', relatives.fatherFirstName, relatives.fatherLastName)}
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </div>);
};

export default Relatives;
