import React from "react";
import DatePicker from "react-datepicker";
import {getMonth, getYear} from "date-fns";
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";
import en from 'date-fns/locale/en-GB';
import './style.css';

const DateYearPickerExcludeDates = ({startDate, setStartDate, excludeDates}) => {
    const months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];

    const excludeDateIntervals = excludeDates.map((date, index) => {
        if (index % 2 === 0 && excludeDates[index + 1]) {
            return {start: excludeDates[index], end: excludeDates[index + 1]};
        }
        return null;
    }).filter(interval => interval !== null);

    return (
        <DatePicker
            calendarClassName="my-calendar-class"
            className="custom-datepicker"
            dateFormat="dd.MM.yyyy"
            locale={en}
            excludeDateIntervals={excludeDateIntervals}
            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     changeMonth,
                                     decreaseMonth,
                                     increaseMonth,
                                     prevMonthButtonDisabled,
                                     nextMonthButtonDisabled,
                                 }) => (
                <div style={{margin: 10, display: "flex", justifyContent: "center"}}>
                    <div style={{marginRight: "10px"}}>
                        <Button
                            variant="dark"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                        >
                            {"<"}
                        </Button>
                    </div>
                    <div style={{marginRight: "5px", width: "90px"}}>
                        <Form.Control
                            value={getYear(date)}
                            onChange={({target: {value}}) => changeYear(value)}
                        ></Form.Control>
                    </div>
                    <div style={{marginRight: "10px", width: "140px"}}>
                        <Form.Select
                            value={months[getMonth(date)]}
                            onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div>
                        <Button
                            variant="dark"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                        >
                            {">"}
                        </Button>
                    </div>
                </div>
            )}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
        />
    );
};

export default DateYearPickerExcludeDates;
