import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {getFlagEmoji, showToast, stringToDate, transformDate} from "../../../services/utility";
import DatePickerWithYear from "../../date/datePicker/DatePickerWithYear";
import {toast} from "react-toastify";

const PassportForm = ({worker, setWorker, countries}) => {
    const {passportNumber, passportStartDate, passportEndDate} = worker.passport !== null ? worker.passport : '';

    const [toastId, setToastId] = useState(null);

    const handleCountryChange = (selected) => {
        const country = selected[0];
        if (country) {
            setWorker({
                ...worker,
                passport: {
                    ...worker.passport,
                    countryId: country.id,
                },
            });
        } else {
            toast.error("Country should be chosen");
            setWorker({
                ...worker,
                passport: {
                    ...worker.passport,
                    countryId: "",
                },
            });
        }
    };

    const setPassportNumber = (value) => {
        const specialCharsRegex = /[^\w\s]/; // Regular expression to match special characters

        if (value) {
            if (value.length > 64) {
                showToast("Passport number cannot exceed 64 characters", toastId, setToastId);
                return;
            }
            if (value.includes(" ") || specialCharsRegex.test(value)) {
                showToast("String cannot contain any spaces and any chars", toastId, setToastId);
                return;
            }

            setWorker({
                ...worker,
                passport: {
                    ...worker.passport,
                    passportNumber: value.trim(),
                },
            });
        }


    };

    const setPassportStartDate = (date) => {
        if (passportEndDate !== null && passportEndDate !== undefined && date > stringToDate(passportEndDate)) {
            // Start date cannot be ahead of end date
            toast.error("Start date cannot be ahead of end date")
            return;
        }

        setWorker({
            ...worker,
            passport: {
                ...worker.passport,
                passportStartDate: transformDate(date),
            },
        });
    };

    const setPassportEndDate = (date) => {
        if (passportStartDate !== null && date < stringToDate(passportStartDate)) {
            // End date cannot be earlier than start date
            toast.error("End date cannot be earlier than start date")
            return;
        }

        setWorker({
            ...worker,
            passport: {
                ...worker.passport,
                passportEndDate: transformDate(date),
            },
        });
    };

    return (
        <>
            <Form.Group as={Col} md="4" controlId="formCompanyName">
                <Form.Label className={"create-worker-label"}>
                    Numer paszportu
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="HX421640"
                    className={"input-field"}
                    value={passportNumber}
                    onChange={(event) => setPassportNumber(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                    Please enter a passport number.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
                as={Col}
                md="3"
                style={{width: "240px"}}
                controlId="formCompanyName"
            >
                <Form.Label className={"create-worker-label"}>
                    Ważność od:
                </Form.Label>
                <DatePickerWithYear
                    startDate={stringToDate(passportStartDate)}
                    setStartDate={setPassportStartDate}
                />
            </Form.Group>
            <Form.Group
                as={Col}
                md="3"
                style={{width: "240px"}}
                controlId="formCompanyName"
            >
                <Form.Label className={"create-worker-label"}>
                    Ważność do:
                </Form.Label>
                <DatePickerWithYear
                    startDate={stringToDate(passportEndDate)}
                    setStartDate={setPassportEndDate}
                />
            </Form.Group>
            {countries !== undefined && countries.length > 0 && (
                <Form.Group as={Col} md="2" controlId="formCountry">
                    <Form.Label className={"create-worker-label"}>Kraj</Form.Label>
                    <Typeahead
                        style={{height: '45px'}}
                        labelKey="name"
                        options={countries}
                        placeholder="Poland"
                        renderMenuItemChildren={(option, {text}) => (
                            <div>
                                {getFlagEmoji(option.iso)} {option.name}
                            </div>
                        )}
                        onChange={handleCountryChange}
                    />
                </Form.Group>
            )}
        </>
    );
};

export default PassportForm;
