import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import PassportEdit from "../../components/workerComponents/passport/edit/PassportEdit";
import WorkerDisplayVacationDisabled from "../../components/workerComponents/WorkerDisplayVacationDisabled";
import VisaEdit from "../../components/workerComponents/visa/edit/VisaEdit";
import PeselEdit from "../../components/workerComponents/pesel/edit/PeselEdit";
import PersonalDetailsEdit from "../../components/workerComponents/personalDetails/edit/PersonalDetailsEdit";
import BillEdit from "../../components/workerComponents/bill/edit/BillEdit";
import RelativesEdit from "../../components/workerComponents/relatives/edit/RelativesEdit";
import ContactsEdit from "../../components/workerComponents/contacts/edit/ContactsEdit";
import ContractEdit from "../../components/workerComponents/contract/edit/ContractEdit";
import InsuranceEdit from "../../components/workerComponents/insurance/edit/InsuranceEdit";
import Config from "../../config";
import AuthService from "../../services/auth.service";
import CertificateEdit from "./CertificateEdit";
import {distractRequestHeaders, showToast} from "../../services/utility";
import DetailsEdit from "../../components/workerComponents/details/DetailsEdit";
import NotesEdit from "./extra/NotesEdit";
import NoteContractEdit from "../../components/forms/notesForm/NoteContractEdit";

const WorkerEdit = ({worker, certificate, setEditing, countries, handleExit}) => {
    const currentUser = AuthService.getCurrentUser();

    const [workerToUpdate, setWorkerToUpdate] = useState(worker)
    const [contractToUpdate, setContractToUpdate] = useState(worker.contract)

    const [isContractUpdated, setContractUpdated] = useState(false);
    const [isValidToUpdate, setIsValidToUpdate] = useState(true);
    const [toastId, setToastId] = useState(true);

    const handleSubmit = () => {
        if (!isValidToUpdate) {
            showToast("You need to handle error fields", toastId, setToastId)
            return;
        }

        setEditing(false);

        if (workerToUpdate.personDetails !== null && worker.personDetails !== null && worker.personDetails.country !== null) {
            if (workerToUpdate.personDetails.countryId == null) {
                workerToUpdate.personDetails.countryId = worker.personDetails.country.id;
            }
        }
        if (workerToUpdate.passport !== null && worker.passport !== null && worker.passport.country !== null) {
            if (workerToUpdate.passport.countryId == null) {
                workerToUpdate.passport.countryId = worker.passport.country.id;
            }
        }

        if (isContractUpdated) {
            fetch(`${Config.URL.BASE}/api/workers/contracts?username=${currentUser.username}&workerId=${worker.id}`, {
                method: 'PUT', body: JSON.stringify(contractToUpdate), headers: distractRequestHeaders(currentUser)
            })
        }
        fetch(`${Config.URL.BASE}/api/workers?username=${currentUser.username}`, {
            method: 'PUT', body: JSON.stringify(workerToUpdate), headers: distractRequestHeaders(currentUser)
        })
            .then(response => response.json())
            .then(data => {
                setEditing(false);
                const currentPath = window.location.pathname;
                if (currentPath.endsWith('/true')) {
                    window.location.href = currentPath.substring(0, currentPath.lastIndexOf('/'));
                } else {
                    window.location.reload();
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    function handleCreateBill() {
        // Create a new bill object
        const newBill = {
            kindOfCurrency: "", // Set the initial currency value
            number: "" // Set the initial number value
        };

        // Add the new bill to the worker's bills array
        setWorkerToUpdate(prevWorker => ({
            ...prevWorker, bills: [...prevWorker.bills, newBill]
        }));

        // Update other relevant state variables if needed
        setIsValidToUpdate(true);
    }

    function handleCreateContractNote() {
        // Create a new bill object
        const newNote = {
            key: "", // Set the initial currency value
            value: "" // Set the initial number value
        };

        // Add the new bill to the worker's bills array
        if (contractToUpdate.contractNotes !== undefined) {
            setContractToUpdate(prevContract => ({
                ...prevContract, contractNotes: [...prevContract.contractNotes, newNote]
            }));
        } else {
            setContractToUpdate(prevContract => ({
                ...prevContract, contractNotes: [newNote]
            }));
        }
        // Update other relevant state variables if needed
        setIsValidToUpdate(true);
    }


    return (<Row style={{marginBottom: '60px'}}>
        <Col xs={6} md={6} style={{paddingLeft: '65px'}} className={'worker-page'}>
            <DetailsEdit worker={workerToUpdate} setWorker={setWorkerToUpdate} setIsValidToUpdate={setIsValidToUpdate}/>
            <div
                className="personalData animate__animated animate__fadeIn animate__delay-0.02s">
                <span className={'worker-subtitle mb-2'}>Dane osobowe</span>
                <PassportEdit worker={workerToUpdate} setWorker={setWorkerToUpdate} countries={countries}
                              setIsValidToUpdate={setIsValidToUpdate}/>
                <VisaEdit worker={workerToUpdate} setWorker={setWorkerToUpdate}
                          setIsValidToUpdate={setIsValidToUpdate}/>
                <PeselEdit worker={workerToUpdate} setWorker={setWorkerToUpdate}
                           setIsValidToUpdate={setIsValidToUpdate}/>
                <PersonalDetailsEdit worker={workerToUpdate} countries={countries} setWorker={setWorkerToUpdate}
                                     setIsValidToUpdate={setIsValidToUpdate}/>
            </div>
            <div className="bills">
                <span className={'worker-subtitle mb-3'} style={{zIndex: '-100'}}>Rachunki</span>
                {workerToUpdate.bills.map((bill, index) => (<BillEdit
                    bill={bill}
                    bills={workerToUpdate.bills}
                    key={index}
                    index={index}
                    worker={workerToUpdate}
                    setWorker={setWorkerToUpdate}
                    setIsValidToUpdate={setIsValidToUpdate}
                />))}
                <button className={"add-button-style"} style={{marginLeft: "5px"}} type="button"
                        onClick={handleCreateBill}>
                    +
                </button>
            </div>
            <div
                className="relatives animate__animated animate__fadeIn">
                <span className={'worker-subtitle mb-1'}>Rodzice</span>
                <RelativesEdit worker={workerToUpdate} setWorker={setWorkerToUpdate}
                               setIsValidToUpdate={setIsValidToUpdate}/>
            </div>
            <div
                className="contacts animate__animated animate__fadeIn">
                <span className={'worker-subtitle mb-1'}>Kontakt</span>
                <ContactsEdit worker={workerToUpdate} setWorker={setWorkerToUpdate}
                              setIsValidToUpdate={setIsValidToUpdate}/>
            </div>
        </Col>
        <Col xs={6} md={6} style={{paddingLeft: '30px'}} className={'worker-page-second'}>
            <div className={'animate__animated animate__fadeIn'}>
                <ContractEdit contract={contractToUpdate} setContract={setContractToUpdate}
                              setContractUpdated={setContractUpdated}
                              setIsValidToUpdate={setIsValidToUpdate}/>
            </div>

            <div className="animate__animated animate__fadeIn mt-2 mb-3">
                <span className={'worker-subtitle mb-2'} style={{marginLeft: "6px"}}>Contract notes</span>
                {contractToUpdate && <>
                    {contractToUpdate.contractNotes !== null && contractToUpdate.contractNotes !== undefined && <>
                        {contractToUpdate.contractNotes.map((note, index) => (<NoteContractEdit
                            note={note}
                            notes={contractToUpdate.contractNotes}
                            key={index}
                            index={index}
                            contract={contractToUpdate}
                            setContract={setContractToUpdate}
                            setIsValidToUpdate={setIsValidToUpdate}
                            setContractUpdated={setContractUpdated}
                        />))}
                    </>}
                    {((contractToUpdate.contractNotes !== undefined && contractToUpdate.contractNotes.length) || (contractToUpdate.contractNotes === undefined)) < 5 &&
                        <button className={"add-button-style"} style={{marginLeft: "5px"}} type="button"
                                onClick={handleCreateContractNote}>
                            +
                        </button>}
                </>}
            </div>

            <div className="vacation-edit animate__animated animate__fadeIn"
                 style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                <h4>Urlopy</h4>
                <WorkerDisplayVacationDisabled vacations={worker.vacations}/>
            </div>


            <div className="animate__animated animate__fadeIn mt-2 mb-3">
                <NotesEdit
                    notes={workerToUpdate.certificateNotes}
                    worker={workerToUpdate}
                    setWorker={setWorkerToUpdate}
                    setIsValidToUpdate={setIsValidToUpdate}
                    kindOfNote={"certificateNotes"}
                    title={"A1 notes"}
                />
            </div>

            <div className="animate__animated animate__fadeIn">
                <CertificateEdit certificate={certificate}/>
            </div>
            <div className="animate__animated animate__fadeIn">
                <InsuranceEdit worker={workerToUpdate} setWorker={setWorkerToUpdate}
                               setIsValidToUpdate={setIsValidToUpdate}/>
            </div>

            <div className="animate__animated animate__fadeIn mt-2">
                <NotesEdit
                    notes={workerToUpdate.notes}
                    worker={workerToUpdate}
                    setWorker={setWorkerToUpdate}
                    setIsValidToUpdate={setIsValidToUpdate}
                    kindOfNote={"notes"}
                    title={"Worker notes"}
                />
            </div>
        </Col>
        <div style={{marginLeft: '53px', marginTop: '30px'}}>
            <button className="button-submit glow-on-hover" onClick={handleSubmit}>
                Zapisz
            </button>
            <button className="button-reject" onClick={handleExit}>
                Anuluj
            </button>
        </div>
    </Row>)
}

export default WorkerEdit;