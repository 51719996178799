import React, {useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import {unsecuredCopyToClipboard} from "../../../services/utility";

const PersonalDetails = ({personDetails}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;


    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    const renderBirthday = () => personDetails.birthday !== null && (
        <div className="worker-card" style={{width: '200px'}}>
            <label>Data urodzenia</label>
            <span
                onClick={(event) => unsecuredCopyToClipboard(event, personDetails.birthday, setOverlayState, handleHide)}>
                    {personDetails.birthday}
                </span>
        </div>);

    const renderBirthdayPlace = () => personDetails.country !== null && (<div className="worker-card birthday-place">
        <label>Miejsce urodzenia</label>
        <span
            onClick={(event) => unsecuredCopyToClipboard(event, personDetails.country.name + ', ' + (personDetails.region === '' || personDetails.region === null ? '' : personDetails.region + ', ') + (personDetails.city === null ? '' : personDetails.city), setOverlayState, handleHide)}
        >
                    {personDetails.country.name + ', ' + (personDetails.region === '' || personDetails.region === null ? '' : personDetails.region + ', ') + (personDetails.city === null ? '' : personDetails.city)}
                </span>
    </div>);

    const renderAddress = () => personDetails.address !== null && personDetails.address !== '' && (<div className="worker-card">
        <label>Adres w Polsce</label>
        <span onClick={(event) => unsecuredCopyToClipboard(event, personDetails.address, setOverlayState, handleHide)}>
                    {personDetails.address}
                </span>
    </div>);

    const renderOriginAddress = () => personDetails.originAddress !== null && personDetails.originAddress !== '' && (<div className="worker-card">
        <label>Adres w kraju pochodzenia</label>
        <span
            onClick={(event) => unsecuredCopyToClipboard(event, personDetails.originAddress, setOverlayState, handleHide)}>
                    {personDetails.originAddress}
                </span>
    </div>);

    return (<>
        <div className="personal-details-worker">
            {renderBirthday()}
            {renderBirthdayPlace()}
        </div>
        {renderOriginAddress()}
        {renderAddress()}
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </>);
};

export default PersonalDetails;
