import React from 'react';
import './squareStyle.css'

const SquareWithTextCertification = ({differenceInDays, text, isLastOne}) => {

    let backgroundColor;
    if (differenceInDays < 0) {
        backgroundColor = '#F1F1F1';
    } else if (differenceInDays < 10) {
        backgroundColor = '#FFEFC7';
    } else {
        backgroundColor = '#EFF3FF';
    }

    return (<div
        style={{
            width: '125px',
            height: '125px',
            background: backgroundColor,
            borderRadius: '10px',
            position: "relative",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transitionDuration: '0.3s'
        }}
        className={"square-with-text"}
    >
        {isLastOne && (<span
            style={{
                display: 'inline-block',
                width: '50px',
                height: '50px',
                lineHeight: '50px',
                textAlign: 'center',
                background: backgroundColor,
                borderRadius: '10px',
                position: "absolute",
                top: "-11px",
                left: "-18px",
                filter: "drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.05))"
            }}
        >
                <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
                  Sk
                </div>
              </span>)}
        <div style={{
            width: '35px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: "20px",
            fontSize: "18px"
        }}>{text}</div>
    </div>);


}

export default SquareWithTextCertification;