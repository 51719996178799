import React from "react";
import {Col, Row} from "react-bootstrap";
import getMonthAbbreviations from "../square/getMonthAbbreviations";
import SquareWithTextDisabled from "../square/SquareWithTextDisabled";

// If there is a current vacation, return it
// If not, then find the nearest future vacation
// or Find the nearest past vacation
function WorkerDisplayVacationDisabled({vacations}) {
    if (vacations.length === 0) {
        return <div></div>;
    }

    const currentDate = new Date();

    function formatDate(dateStr) {
        const [day, month, year] = dateStr.split(".");
        return new Date(`${year}-${month}-${day}`);
    }

    function getMonthAbbreviation(date) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthNames[date.getMonth()].substring(0, 3);
    }

    const currentVacations = vacations.filter(vacation => {
        const startDate = formatDate(vacation.vacationDateStart);
        const endDate = formatDate(vacation.vacationDateEnd);
        return startDate <= currentDate && endDate >= currentDate;
    });

    if (currentVacations.length > 0) {
        return (<Row style={{paddingTop: "10px", paddingLeft: '10px'}}>
            <Col xs={3} md={3}>
                <span><SquareWithTextDisabled isCurrent={true}
                                              text={getMonthAbbreviations(currentVacations[0].vacationDateStart, currentVacations[0].vacationDateEnd)}/></span>
            </Col>
            <Col xs={8} md={8} style={{marginLeft: "10px"}}>
                {currentVacations.map(vacation => {
                    return (<div className="vacation-text-edit" key={vacation.vacationDateStart}>
                        <div>
                            <h5>Czas trwania (dni)</h5>
                            <p>{vacation.workingDay}</p>
                        </div>
                        <div>
                            <h5>Okres</h5>
                            <p>{vacation.vacationDateStart} - {vacation.vacationDateEnd}</p>
                        </div>
                    </div>);
                })}
            </Col>
        </Row>);
    }

    const futureVacations = vacations.filter(vacation => formatDate(vacation.vacationDateStart) > currentDate);
    const nearestFutureVacation = futureVacations.reduce((prev, current) => {
        const prevStartDate = formatDate(prev.vacationDateStart);
        const currentStartDate = formatDate(current.vacationDateStart);
        return prevStartDate < currentStartDate ? prev : current;
    }, futureVacations[0]);

    const pastVacations = vacations.filter(vacation => formatDate(vacation.vacationDateEnd) < currentDate);
    const nearestPastVacation = pastVacations.reduce((prev, current) => {
        const prevEndDate = formatDate(prev.vacationDateEnd);
        const currentEndDate = formatDate(current.vacationDateEnd);
        return prevEndDate > currentEndDate ? prev : current;
    }, pastVacations[0]);

    const getVacationElement = (vacation) => {
        const startDate = formatDate(vacation.vacationDateStart);
        const endDate = formatDate(vacation.vacationDateEnd);
        const differenceInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

        return (<Row style={{paddingTop: "10px", paddingLeft: '10px'}}>
            <SquareWithTextDisabled differenceInDays={differenceInDays} text={getMonthAbbreviation(startDate)}/>
            <Col xs={8} md={8}>
                <div className="vacation-text-edit">
                    <div>
                        <h5>Czas trwania (dni)</h5>
                        <p>{vacation.workingDay}</p>
                    </div>
                    <div>
                        <h5>Okres</h5>
                        <p>{vacation.vacationDateStart} - {vacation.vacationDateEnd}</p>
                    </div>
                </div>
            </Col>
        </Row>);
    };

    if (nearestFutureVacation && nearestPastVacation) {
        const nearestFutureDate = formatDate(nearestFutureVacation.vacationDateStart);
        const nearestPastDate = formatDate(nearestPastVacation.vacationDateEnd);
        const differenceFromFuture = nearestFutureDate - currentDate;
        const differenceFromPast = currentDate - nearestPastDate;

        if (differenceFromFuture < differenceFromPast) {
            return getVacationElement(nearestFutureVacation);
        } else {
            return getVacationElement(nearestPastVacation);
        }
    } else if (nearestFutureVacation) {
        return getVacationElement(nearestFutureVacation);
    } else {
        return getVacationElement(nearestPastVacation);
    }
}

export default WorkerDisplayVacationDisabled;
