import React, {useEffect, useState} from "react";
import './workers-vacations.css'
import {useNavigate} from "react-router-dom";
import {ReactComponent as ShowMore} from '../../assets/svgs/ShowMore.svg';
import {ReactComponent as CloseMore} from '../../assets/svgs/CloseMore.svg';
import {ReactComponent as ShowMoreWhite} from '../../assets/svgs/ShowMoreWhite.svg';
import {ReactComponent as CloseMoreWhite} from '../../assets/svgs/CloseMoreWhite.svg';
import {ReactComponent as Fired} from '../../assets/svgs/FiredWorkerWhite.svg';
import {fetchDelegations} from "../../services/api-calls";
import {getPolishMonthName} from "../../services/utility";

const WorkerVacationBlock = ({worker, index, year, month, currentUser}) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [showAllVacations, setShowAllVacations] = useState(false); // State for toggling overflow
    const [showAllDelegations, setShowAllDelegations] = useState(false); // State for toggling overflow
    const [delegations, setDelegations] = useState([]);
    const [delegationsSize, setDelegationsSize] = useState(0);
    const [showAllDelegationsFully, setShowAllDelegationsFully] = useState(false); // State for toggling overflow

    useEffect(() => {
        if (showAllDelegations) {
            setLoading(true)
            fetchDelegations(worker.id, year, month, currentUser).then(data => {
                setDelegations(data.delegations)
                setDelegationsSize(data.size)
            }).finally(() => setLoading(false))
        }
    }, [showAllDelegations, worker.id, year, month])
    const toggleVacations = () => {
        setShowAllVacations(!showAllVacations);
    };

    const toggleDelegations = () => {
        setShowAllDelegations(!showAllDelegations);
    };

    const toggleDelegationsFully = () => {
        setShowAllDelegationsFully(!showAllDelegationsFully);
    };

    const appliedStyles = {
        borderBottomLeftRadius: showAllDelegations ? 0 : null,
        borderBottomRightRadius: showAllDelegations ? 0 : null,
        backgroundColor: worker.vacations.length === 0 ? '#ececec' : 'white',
    };

    return (<div style={{marginBottom: "10px"}}>
        <div key={worker.id} className="worker-vacation-block"
             style={appliedStyles}>
            {worker.fired ?
                <div className={"fired-indicator"}>
                    <Fired height={"22px"} width={"22px"}/>
                </div> : <div className={"empty-indicator"}>
                </div>}
            <div className="worker-index">{index}</div>
            <div className="worker-name"
                 onClick={() => navigate(`/${worker.companySlug}/workers/${worker.workerSlug}/info`)}>
                {worker.workerLastname + " " + worker.workerFirstname}
            </div>
            <div className={!showAllVacations ? "worker-vacations" : "show-all"}>
                {!showAllVacations ? <div className="vacations-scrollable">
                    {worker.vacations.map((vacation, i) => (<div className={"worker-vacation-presentation"} key={i}>
                        {vacation.vacationDateStart} - {vacation.vacationDateEnd}
                    </div>))}
                </div> : worker.vacations.map((vacation, i) => (<div className={"worker-vacation-presentation"} key={i}>
                    {vacation.vacationDateStart} - {vacation.vacationDateEnd}
                </div>))}
            </div>

            <div className="action-button">
                <div style={{display: "flex", gap: "18px"}}>
                    {worker.vacations.length !== 0 && <div className="action-button" style={{marginTop: "7px"}}
                                                           onClick={toggleVacations}>
                        {showAllVacations ? <CloseMore width={"22px"} height={"22px"}/> :
                            <ShowMore width={"22px"} height={"22px"}/>}
                    </div>}

                    <button className={'button-submit glow-on-hover no-select'} style={{
                        paddingTop: "5px",
                        paddingRight: '6px',
                        paddingLeft: "6px",
                        paddingBottom: "5px",
                        fontSize: "12px"
                    }} onClick={toggleDelegations} type="button">
                        Delegacje
                    </button>
                </div>
            </div>
        </div>
        {showAllDelegations && !loading && <div className={"delegations animate__animated animate__fadeIn"}>
            {delegations.length === 0 ? <>No delegations were found 🫥</> : <>
                <div className={"delegation-title"}>
                    Delegations {showAllDelegationsFully && <>
                    <br/>{getPolishMonthName(month)}: {delegationsSize}</>}
                </div>
                <div className={!showAllDelegationsFully ? "worker-vacations" : "show-all"}>
                    {!showAllDelegationsFully ? <div className="vacations-scrollable">
                        {delegations.map((delegation, i) => (<div className={"worker-vacation-presentation"} key={i}>
                            {delegation.startDate} - {delegation.endDate}
                        </div>))}
                    </div> : delegations.map((vacation, i) => (<div className={"worker-vacation-presentation"} key={i}>
                        {vacation.startDate} - {vacation.endDate}
                    </div>))}
                </div>
                <div className="action-button"
                     onClick={toggleDelegationsFully}>
                    {showAllDelegationsFully ? <CloseMoreWhite width={"22px"} height={"22px"}/> :
                        <ShowMoreWhite width={"22px"} height={"22px"}/>}
                </div>
            </>}
        </div>}
    </div>)
};

export default WorkerVacationBlock;
