import React from "react";
import "./assets/css/company.css";
import {NavLink, useLocation} from "react-router-dom";

const CompanyDefaultComponent = ({navigationLinkBase}) => {
    const location = useLocation();
    const isActive = location.pathname === navigationLinkBase;

    return (
        <NavLink
            exact
            to={navigationLinkBase}
            className={`company-reset-button ${isActive ? "active-reset-button" : ""}`}
        >
            All
        </NavLink>
    );
};

export default CompanyDefaultComponent;