import React, {useEffect, useState} from "react";

const DetailsEdit = ({worker, setWorker, setIsValidToUpdate}) => {

    const [firstName, setFirstName] = useState(worker.personDetails.firstName)
    const [lastName, setLastName] = useState(worker.personDetails.lastName)
    const [speciality, setSpeciality] = useState(worker.personDetails.speciality)

    const handleFirstName = (e) => {
        setFirstName(e.target.innerText);
    };

    const handleLastName = (e) => {
        setLastName(e.target.innerText);
    };

    const handleSpeciality = (e) => {
        setSpeciality(e.target.innerText);
    };

    useEffect(() => {
        setWorker({
            ...worker,
            personDetails: {
                ...worker.personDetails,
                firstName: firstName,
                lastName: lastName,
                speciality: speciality,
            },
        });
        setIsValidToUpdate(true);
    }, [firstName, lastName, speciality])

    return (<>
        <div className="titleData">
            <div>
                <div>
                    <span
                        contentEditable={true}
                        onBlur={handleLastName}
                        suppressContentEditableWarning={true}
                    >{lastName}
                    </span>
                    {` `}
                    <span
                        contentEditable={true}
                        onBlur={handleFirstName}
                        suppressContentEditableWarning={true}
                    >{firstName}
                    </span>
                </div>
            </div>
            <span
                contentEditable={true}
                style={{width: '90px'}}
                onBlur={handleSpeciality}
            >{speciality}
            </span>
        </div>
    </>);
};

export default DetailsEdit;
