import React, {useEffect, useState} from 'react';
import '../assets/css/passport.css';
import {checkDateExpiration, getFlagEmoji, showToast} from "../../../../services/utility";
import {Col, Form} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {toast} from "react-toastify";

const PassportEdit = ({worker, setWorker, countries, setIsValidToUpdate}) => {
    const passport = worker.passport || {};

    let initCountryValue;
    if (passport.country !== undefined && passport.country !== null) {
        initCountryValue = passport.country.id;
    }

    const [newCountryValue, setNewCountryValue] = useState(null);

    const [passportNumber, setPassportNumber] = useState(passport.passportNumber || '');
    const [passportStartDate, setPassportStartDate] = useState(passport.passportStartDate || '');
    const [passportEndDate, setPassportEndDate] = useState(passport.passportEndDate || '');
    const [toastId, setToastId] = useState(null);
    const [showChangeCountry, setShowChangeCountry] = useState(false)

    const [passportNumberValid, setPassportNumberValid] = useState(true);
    const [passportStartDateValid, setPassportStartDateValid] = useState(true);
    const [passportEndDateValid, setPassportEndDateValid] = useState(true);

    useEffect(() => {
        const specialCharsRegex = /[^\w\s]/; // Regular expression to match special characters
        const dateFormatRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/; // Regular expression to match dd.MM.yyyy format

        let isAllFieldsValid = true;

        if (passportNumber.length > 64) {
            showToast('Passport number cannot exceed 64 characters', toastId, setToastId);
            setPassportNumberValid(false);
            isAllFieldsValid = false;
        } else if (passportNumber.includes(' ') || specialCharsRegex.test(passportNumber)) {
            showToast('String cannot contain any spaces and any chars', toastId, setToastId);
            setPassportNumberValid(false);
            isAllFieldsValid = false;
        } else {
            setPassportNumberValid(true);
        }

        let startDateToPut = passportStartDate;
        let endDateToPut = passportEndDate;
        if (passportStartDate && passportEndDate) {
            if (!dateFormatRegex.test(passportStartDate)) {
                showToast('Passport start date should be in dd.MM.yyyy format', toastId, setToastId);
                setPassportStartDateValid(false);
                isAllFieldsValid = false;
            } else {
                setPassportStartDateValid(true);
            }

            if (!dateFormatRegex.test(passportEndDate)) {
                showToast('Passport end date should be in dd.MM.yyyy format', toastId, setToastId);
                setPassportEndDateValid(false);
                isAllFieldsValid = false;
            } else {
                setPassportEndDateValid(true);
            }

            // Check if the start date is not ahead of the end date
            const startTimestamp = new Date(passportStartDate).getTime();
            const endTimestamp = new Date(passportEndDate).getTime();

            if (startTimestamp > endTimestamp) {
                showToast('Passport start date cannot be ahead of the end date', toastId, setToastId);
                setPassportStartDateValid(false);
                setPassportEndDateValid(false);
                isAllFieldsValid = false;
            }
        } else {
            startDateToPut = null;
            endDateToPut = null;
        }


        if (isAllFieldsValid) {
            setWorker({
                ...worker, passport: {
                    ...passport,
                    passportNumber: passportNumber.length === 0 ? null : passportNumber,
                    passportStartDate: startDateToPut,
                    passportEndDate: endDateToPut,
                    countryId: showChangeCountry ? newCountryValue : initCountryValue
                },
            });
            setIsValidToUpdate(true);
        } else {
            setIsValidToUpdate(false);
        }

    }, [passportNumber, passportStartDate, passportEndDate]);


    const handlePassportNumberChange = (e) => {
        setPassportNumber(e.target.innerText.trim());
    };

    const handlePassportStartDateChange = (e) => {
        setPassportStartDate(e.target.innerText.trim());
    };

    const handlePassportEndDateChange = (e) => {
        setPassportEndDate(e.target.innerText.trim());
    };

    const handleCountryChange = (selected) => {
        const country = selected[0];
        if (country) {
            setNewCountryValue(country.id)
            setWorker({
                ...worker, passport: {
                    ...worker.passport, countryId: country.id,
                },
            });
        } else {
            toast.error("Country should be chosen");
            setWorker({
                ...worker, passport: {
                    ...worker.passport, countryId: initCountryValue,
                },
            });
        }
    };

    const handleCheckboxClick = () => {
        setShowChangeCountry(prevState => !prevState);
        if (showChangeCountry === false) {
            setWorker({
                ...worker, passport: {
                    ...worker.passport, countryId: initCountryValue,
                },
            });
        }
    };

    const passportExpirationColor = checkDateExpiration(passportEndDate);

    return (<>
        <div className="passport-worker">
            <div className="worker-card pass-document">
                <label>Passport {passport.country !== undefined && passport.country !== null && getFlagEmoji(passport.country.iso)}
                </label>
                <div contentEditable={true} onBlur={handlePassportNumberChange}
                     className={!passportNumberValid ? 'invalid' : ''}>
                    {passportNumber}
                </div>
            </div>
            <div className="worker-card passport-date">
                <label>Okres ważności</label>
                <div style={{color: passportExpirationColor}}>
                    <div
                        style={{width: "109px", display: 'inline-block'}}
                        contentEditable={true}
                        onBlur={handlePassportStartDateChange}
                        suppressContentEditableWarning={true}
                        className={!passportStartDateValid ? 'invalid' : ''}
                    >
                        {passportStartDate}
                    </div>
                    {' - '}
                    <div
                        style={{width: "109px", display: 'inline-block', marginLeft: '3px'}}
                        contentEditable={true}
                        onBlur={handlePassportEndDateChange}
                        suppressContentEditableWarning={true}
                        className={!passportEndDateValid ? 'invalid' : ''}
                    >
                        {passportEndDate}
                    </div>
                </div>
            </div>
        </div>
        <div className="worker-card pass-document">
            <label>Passport country</label>
            <div>
                {!showChangeCountry && passport.country !== undefined && passport.country !== null && getFlagEmoji(passport.country.iso) + ' ' + passport.country.name}
                {countries.length > 0 && showChangeCountry && (<Form.Group as={Col} md="5" controlId="formCountry">
                    <Typeahead
                        style={{width: '300px', height: '40px'}}
                        labelKey="name"
                        options={countries}
                        placeholder="Passport kraj"
                        renderMenuItemChildren={(option, {text}) => (<div>
                            {getFlagEmoji(option.iso)} {option.name}
                        </div>)}
                        onChange={handleCountryChange}
                    />
                </Form.Group>)}
                <div style={{marginTop: '5px'}}>
                    <Form.Check
                        type="checkbox"
                        className={'search-checkbox'}
                        id="toggleShowPassword"
                        label={<span className="search-checkbox-label">Change Passport kraj</span>}
                        checked={showChangeCountry}
                        onChange={handleCheckboxClick}
                    />
                </div>
            </div>
        </div>
    </>);
};

export default PassportEdit;
