import React, {useState} from 'react';
import Col from "react-bootstrap/Col";
import {Form, InputGroup} from 'react-bootstrap';
import InputPhonesForm from "../inputPhonesForm/InputPhonesForm";

const ContactForm = ({worker, setWorker}) => {

    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState(worker.email || "");
    const [telegram, setTelegram] = useState(worker.telegram || "");

    const handleCheckboxClick = () => {
        setChecked(prevState => !prevState);
    };


    const handleEmailChange = (event) => {
        const emailValue = event.target.value
        setEmail(emailValue)
        setWorker((prevWorker) => ({
            ...prevWorker, email: emailValue,
        }));
    };


    const handleTelegramChange = (event) => {
        const telegramValue = event.target.value;
        setTelegram(telegramValue);

        if (checked) {
            setWorker((prevWorker) => ({
                ...prevWorker, telegram: telegramValue
            }));
        }
    };

    return (<>
        <Form.Group as={Col} md="4" style={{marginBottom: '-27px'}}>
            <Form.Label className="create-worker-label">Telegram</Form.Label>
            <InputGroup className="mb-2">
                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                {checked && (<Form.Control
                    type="text"
                    placeholder="Trankiv"
                    value={telegram}
                    className={"input-field"}
                    onChange={handleTelegramChange}
                />)}
                {!checked && (<Form.Control
                    type="text"
                    disabled
                    className={"input-field"}
                    placeholder="Trankiv"
                />)}
            </InputGroup>

            <div
                className={'group checkbox'}
                onClick={handleCheckboxClick}
                style={{marginLeft: "3px", marginTop: "5px"}}
            >
                <input type="checkbox" checked={checked} readOnly={true}/>
                <label htmlFor="rememberMe">Uwzględnij telegram</label>
            </div>
        </Form.Group>
        <Form.Group as={Col} md="4">
            <Form.Label className="create-worker-label">Email</Form.Label>
            <Form.Control
                type="text"
                placeholder="global.research@gmail.com"
                value={email}
                className={"input-field"}
                onChange={handleEmailChange}
            />
        </Form.Group>

        <div style={{marginTop: "-15px"}} className={'mt-3'}>
            <InputPhonesForm values={worker.phones} setValues={phones => setWorker(prevWorker => ({
                ...prevWorker, phones: phones
            }))}/>
        </div>
    </>);
}

export default ContactForm;
