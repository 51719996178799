import React, {useState} from 'react';
import Col from "react-bootstrap/Col";
import {Form} from 'react-bootstrap';
import {showToast} from "../../../services/utility";

const RelativeForm = ({worker, setWorker}) => {
    const [toastId, setToastId] = useState(null);

    const handleInputChange = (event, field) => {
        const value = event.target.value;

        // Check if the value exceeds the character limit
        if (value.trim().length > 30) {
            showToast("First and last names should not exceed 30 characters", toastId, setToastId);
            return; // Skip updating the state if the value is too long
        }

        const containsNumbers = /\d/.test(value);

        if (containsNumbers) {
            showToast("First and last names should not contain numbers or special characters", toastId, setToastId);
            return; // Skip updating the state if the value is invalid
        }

        const updatedWorker = {
            ...worker,
            personDetails: {
                ...worker.personDetails,
                [field]: value.trim()
            }
        };

        setWorker(updatedWorker);
    };

    return (
        <>
            {/* MOM */}
            <Form.Group as={Col} md="4" controlId="formMotherFirstName">
                <Form.Label className="create-worker-label">Imię matki</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder={"Julia"}
                    value={worker.personDetails.motherFirstName}
                    onChange={(event) => handleInputChange(event, 'motherFirstName')}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formMotherLastName">
                <Form.Label className="create-worker-label">Nazwisko matki</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder={"Kaminśka"}
                    value={worker.personDetails.motherLastName}
                    onChange={(event) => handleInputChange(event, 'motherLastName')}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid last name.</Form.Control.Feedback>
            </Form.Group>

            <Col xs={12} md={12} className={'mt-3'}></Col>
            {/* DAD */}
            <Form.Group as={Col} md="4" controlId="formFatherFirstName">
                <Form.Label className="create-worker-label">Imię ojca</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder={"Jacek"}
                    value={worker.personDetails.fatherFirstName}
                    onChange={(event) => handleInputChange(event, 'fatherFirstName')}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formFatherLastName">
                <Form.Label className="create-worker-label">Nazwisko ojca</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder={"Górecki"}
                    value={worker.personDetails.fatherLastName}
                    onChange={(event) => handleInputChange(event, 'fatherLastName')}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid last name.</Form.Control.Feedback>
            </Form.Group>
        </>
    );
}

export default RelativeForm;
