import {useDrop} from "react-dnd";
import React, {useState} from "react";
import './style.css'
import {ReactComponent as Trash} from '../../assets/modal/TrashIcon.svg';
import RemoveModal from "../forms/modals/RemoveModal";
import RemoveUserModal from "../forms/modals/RemoveUserModal";

const DeletePlace = ({workerId}) => {

    const [showModal, setShowModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [componentId, setComponentId] = useState(false);
    const [item, setItem] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCloseUserModal = () => {
        setShowUserModal(false);
    };


    const [, drop] = useDrop({
        accept: ['CERTIFICATION_BLOCK', 'VACATION_BLOCK', 'DOCUMENT_BLOCK', 'USER_BLOCK', 'ANEKS_BLOCK'], // Accept multiple types
        drop: (item) => {
            setItem(item)
            if ('certificate' in item) {
                const {certificate} = item;
                setComponentId(certificate.id);
                setShowModal(true);
            } else if ('vacation' in item) {
                const {vacation} = item;
                setComponentId(vacation.id);
                setShowModal(true);
            } else if ('document' in item) {
                const {document} = item;
                setComponentId(document.id);
                setShowModal(true);
            } else if ('user' in item) {
                const {user} = item;
                setComponentId(user.id);
                setShowUserModal(true);
            } else if ('aneks' in item) {
                const {aneks} = item;
                setComponentId(aneks.id);
                setShowModal(true);
            }
        },

    });


    return (<div ref={drop}>
        <div
            style={{
                width: '300px',
                height: '125px',
                background: '#fff',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transitionDuration: '0.3s',
            }}
            className={"delete-place"}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: "center",
                fontSize: "16px",
                color: "#dadada"
            }}><Trash/>
            </div>
        </div>

        {showModal && (
            <RemoveModal componentId={componentId} show={showModal} workerId={workerId} item={item} success={success}
                         setSuccess={setSuccess} handleClose={handleCloseModal}/>)}

        {showUserModal && (<RemoveUserModal componentId={componentId} show={showUserModal} item={item} success={success}
                                            setSuccess={setSuccess} handleClose={handleCloseUserModal}/>)}
    </div>);
};

export default DeletePlace;