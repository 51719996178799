import React from 'react';
import Config from "./config";

class GlobalHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, isServerAvailable: true};
    }

    componentDidMount() {
        this.checkServerAvailability();
    }

    checkServerAvailability() {
        fetch(`${Config.URL.BASE}/api/health-check`)
            .then(response => {
                if (response.ok) {
                    // Server is available
                    this.setState({isServerAvailable: true});
                } else {
                    // Server is not available
                    this.setState({isServerAvailable: false});
                }
            })
            .catch(error => {
                console.log(error)
                // Error occurred during the request (e.g., network error)
                this.setState({isServerAvailable: false});
            });
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        if (error.message === 'Server unavailable') {
            console.log('Server is not available. Performing actions...');
        }
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        if (!this.state.isServerAvailable) {
            return <h1>Server is not available.</h1>;
        }

        // Check for unauthorized access error
        if (this.props.error && this.props.error.status === 403) {
            return <h1>Access Denied. You are not authorized to view this page.</h1>;
        }

        return this.props.children;
    }
}

export default GlobalHandler;
