import {Overlay, Popover} from "react-bootstrap";
import React from "react";

export function OverlayFun(props) {
    return <div ref={props.ref}>
        <Overlay
            show={props.show}
            target={props.target}
            container={props.ref}
            onHide={props.onHide}
        >
            <Popover id="popover-contained">
                <Popover.Body>
                    Copied!
                </Popover.Body>
            </Popover>
        </Overlay>

    </div>;
}