import React from 'react';
import {contractReducer} from "./ContractReducer";

const Contract = ({contract}) => {

    const [contractTitle,
        contractDateStart,
        contractDateEnd,
        contractHireDate,
        aneksDateEnd,
        contractColor,
        aneksColor] = contractReducer(contract);

    return (<div className="contract">
        <h4>{contractTitle}</h4>
        {contractHireDate && <div>
            <h5>Data zawarcia</h5>
            <p>
                {contractHireDate}
            </p>
        </div>}
        {contractDateStart && contractDateEnd && (<div>
            <h5>Okres:</h5>
            <p style={{color: contractColor}}>
                {contractDateStart + ' - ' + contractDateEnd}
            </p>
        </div>)}
        {contract.anekses.length > 0 && <div>
            <h5>Aneksy: {contract.anekses.length}</h5>
            {aneksDateEnd !== null && <p style={{color: aneksColor}}>{aneksDateEnd}</p>}
        </div>}
    </div>);
};

export default Contract;
