import React, {useState} from "react";
import {useDrag} from "react-dnd";
import {formatDatetime, formatTimePeriod} from "../../services/utility";

const User = ({user}) => {
    const [dateFormat, setDateFormat] = useState("DD.MM.YYYY");

    const handleClick = () => {
        const newDate = dateFormat === "DD.MM.YYYY" ? "HH:mm" : "DD.MM.YYYY";
        setDateFormat(newDate);
    };

    const [{isDragging}, drag] = useDrag(() => ({
        type: 'USER_BLOCK', item: {user}, // Include the 'item' property
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const style = {
        opacity: isDragging ? 0.5 : 1, cursor: "pointer", backgroundColor: 'white', borderRadius: '8px'
    };

    const roleName = user.roles[0].name.replaceAll('_', ' ').replaceAll('ROLE', '').trim();

    return (<tr ref={drag} key={user.id} style={style}>
        <td style={{paddingLeft: "15px"}}>
            <span>{user.index}</span>
        </td>
        <td>
            <div>{user.username}</div>
        </td>
        <td>
            <div style={{fontWeight: '500'}}>{user.firstname}</div>
        </td>
        <td>
            <div style={{fontWeight: '500'}}>{user.lastname}</div>
        </td>
        <td>
            <div style={{fontWeight: '500'}}>{roleName}</div>
        </td>
        <td onClick={handleClick}>
            <div style={{fontWeight: '500'}}>
                {dateFormat === "DD.MM.YYYY" ? formatDatetime(user.authorizationTime) : formatTimePeriod(user.authorizationTime)}
            </div>
        </td>
    </tr>);
};

export default User;
