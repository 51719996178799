import AuthService from "../../services/auth.service";
import React, {useEffect, useState} from "react";
import Config from "../../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import CompanyTransmission from "../company/CompanyTransmission";
import Col from "react-bootstrap/Col";
import {fetchCompanies} from "../../services/api-calls";

const TransmissionModal = ({workerId, currentCompanyId, show, handleClose}) => {

    const currentUser = AuthService.getCurrentUser();
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        setLoading(true)
        fetchCompanies(currentUser).then(companies => setCompanies(companies)).finally(() => {
            setLoading(false)
        })
    }, []);

    const handleTransmission = async (companyId) => {
        try {
            const headers = {
                'X-XSRF-TOKEN': currentUser.csrfToken,
                Authorization: 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json',
            };

            await fetch(`${Config.URL.BASE}/api/workers/transmigrate?companyId=${companyId}&workerId=${workerId}&username=${currentUser.username}`, {
                method: 'POST', headers: headers,
            })
                .finally(() => {
                    toast.success('Worker transmigrated successfully!', {autoClose: 2000}); // Adjust the autoClose duration as needed
                    handleClose()
                    window.location.reload();
                });
        } catch (error) {
            toast.error('An error occurred. Please try again.', {autoClose: 2000});
        }
    };

    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">✈️ Transmigrate worker</Modal.Title>
            </Modal.Header>

            <Container>
                <div className="transmission-modal-container">
                    <h5>Wybierz firmę, z której chcesz <u>przenieść</u> pracownika</h5>
                    {!loading && (<span className={"animate__animated animate__fadeIn animate__delay-0.001s"}>
                                    <Row>
                                        {companies.map((company) => {
                                            if (company.id === currentCompanyId) {
                                                // Render the specific company differently
                                                return (<Col xs={6}><CompanyTransmission
                                                    handleTransmission={handleTransmission}
                                                    isCurrentCompany={true}
                                                    company={company}/></Col>);
                                            } else {
                                                // Render other companies using the default rendering
                                                return (<Col xs={6}><CompanyTransmission
                                                    handleTransmission={handleTransmission}
                                                    isCurrentCompany={false}
                                                    company={company}/></Col>);
                                            }
                                        })}
                                    </Row>
                                </span>)}
                </div>
            </Container>
            <Modal.Footer>
                <button className="button-reject" onClick={handleClose}>
                    Zamknąć
                </button>
            </Modal.Footer>
        </Modal>


    </>)
}

export default TransmissionModal;