import NoteEdit from "../../../components/forms/notesForm/NoteEdit";
import React from "react";

const NotesEdit = ({
                       notes,
                       worker,
                       setWorker,
                       setIsValidToUpdate,
                       kindOfNote,
                       title
                   }) => {

    const setNotes = (updatedNotes) => {
        setWorker({
            ...worker,
            [kindOfNote]: updatedNotes,
        });
        setIsValidToUpdate(true);
    };


    function handleCreateNote() {
        // Create a new bill object
        const newNote = {
            key: "", // Set the initial currency value
            value: "" // Set the initial number value
        };

        // Add the new bill to the worker's bills array
        setWorker(prevWorker => ({
            ...prevWorker, [kindOfNote]: [...prevWorker[kindOfNote], newNote]
        }));

        // Update other relevant state variables if needed
        setIsValidToUpdate(true);
    }


    return (
        <div>
            <span className={'worker-subtitle mb-2'}
                  style={{zIndex: '-100', marginLeft: "6px"}}>{title}</span>
            {notes.map((note, index) => (<NoteEdit
                note={note}
                notes={notes}
                key={index}
                index={index}
                worker={worker}
                setWorker={setWorker}
                setIsValidToUpdate={setIsValidToUpdate}
                setNotes={setNotes}
            />))}
            {notes.length < 5 &&
                <button className={"add-button-style"} style={{marginLeft: "5px"}} type="button"
                        onClick={handleCreateNote}>
                    +
                </button>}
        </div>
    )
};

export default NotesEdit;
