import React from "react";
import {Col, Form} from "react-bootstrap";
import DatePickerWithYear from "../../date/datePicker/DatePickerWithYear";
import {toast} from "react-toastify";
import {stringToDate, transformDate} from "../../../services/utility";

const VisaForm = ({worker, setWorker}) => {
    const {visaNumber, visaStartDate, visaEndDate} = worker.visa !== null ? worker.visa : '';

    const setVisaNumber = (value) => {
        if (value.length > 64) {
            toast.error("Visa number cannot exceed 64 characters");
            return;
        }

        setWorker({
            ...worker, visa: {
                ...worker.visa, visaNumber: value.trim(),
            },
        });
    };

    const setVisaStartDate = (date) => {

        if (visaEndDate !== null && visaEndDate !== undefined && date > stringToDate(visaEndDate)) {
            // Start date cannot be ahead of end date
            toast.error("Start date cannot be ahead of end date")
            return;
        }

        setWorker({
            ...worker, visa: {
                ...worker.visa, visaStartDate: transformDate(date),
            },
        });
    };

    const setVisaEndDate = (date) => {

        if (visaStartDate !== null && date < stringToDate(visaStartDate)) {
            // End date cannot be earlier than start date
            toast.error("End date cannot be earlier than start date")
            return;
        }


        setWorker({
            ...worker, visa: {
                ...worker.visa, visaEndDate: transformDate(date),
            },
        });
    };

    return (<>
        <Form.Group as={Col} md="4" controlId="formCompanyName">
            <Form.Label className={"create-worker-label"}>Numer Wizy/Karty Pobytu</Form.Label>
            <Form.Control
                type="text"
                placeholder="VS5437890"
                value={visaNumber}
                className={"input-field"}
                onChange={(event) => setVisaNumber(event.target.value)}
            />
            <Form.Control.Feedback type="invalid">
                Please enter a passport number.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
            as={Col}
            md="3"
            style={{width: "240px"}}
            controlId="formCompanyName"
        >
            <Form.Label className={"create-worker-label"}>
                Ważność od:
            </Form.Label>
            <DatePickerWithYear
                startDate={stringToDate(visaStartDate)}
                setStartDate={setVisaStartDate}
            />
        </Form.Group>
        <Form.Group
            as={Col}
            md="3"
            style={{width: "240px"}}
            controlId="formCompanyName"
        >
            <Form.Label className={"create-worker-label"}>
                Ważność do:
            </Form.Label>
            <DatePickerWithYear
                startDate={stringToDate(visaEndDate)}
                setStartDate={setVisaEndDate}
            />
        </Form.Group>
    </>);
};

export default VisaForm;
