import React, {useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import './assets/css/passport.css';
import {checkDateExpiration, getFlagEmoji, unsecuredCopyToClipboard} from '../../../services/utility';

const Passport = ({passport}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    const passportExpirationColor = checkDateExpiration(passport.passportEndDate);

    const renderPassportDocument = () => passport.passportNumber !== null && (
        <div className="worker-card pass-document">
            <label>
                Passport {passport.country !== null && getFlagEmoji(passport.country.iso)}
            </label>
            <span
                onClick={(event) => unsecuredCopyToClipboard(event, passport.passportNumber, setOverlayState, handleHide)}>
                    {passport.passportNumber}
                </span>
        </div>);

    const renderPassportDate = () => passport.passportStartDate !== null && passport.passportEndDate !== null && (
        <div className="worker-card passport-date">
            <label>Okres ważności</label>
            <span
                style={{color: passportExpirationColor}}
                onClick={(event) => unsecuredCopyToClipboard(event, `${passport.passportStartDate} - ${passport.passportEndDate}`, setOverlayState, handleHide)}
            >
                    {`${passport.passportStartDate} - ${passport.passportEndDate}`}
                </span>
        </div>);

    return (<>
        <div className="passport-worker">
            {renderPassportDocument()}
            {renderPassportDate()}
        </div>
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </>);
};

export default Passport;
