import formatDate from "./formatDate";

function getMonthAbbreviations(dateStrStart, dateStrEnd) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date1 = formatDate(dateStrStart);
    const date2 = formatDate(dateStrEnd);

    const monthDate1 = monthNames[date1.getMonth()].substring(0, 3);
    const monthDate2 = monthNames[date2.getMonth()].substring(0, 3);

    if (date1.getMonth() === date2.getMonth()) {
        return monthDate1;
    } else {
        return `${monthDate1} - ${monthDate2}`;
    }
}

export default getMonthAbbreviations;