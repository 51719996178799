import React, {useEffect, useState} from 'react';
import {Col, Form, Modal, Row, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../../../services/auth.service';
import authService from '../../../../services/auth.service';
import '../style.css';
import {Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import Config from "../../../../config";
import {distractRequestGetOptions, distractRequestHeaders} from "../../../../services/utility";
import {ReactComponent as PasswordReset} from "../../../../assets/svgs/ResetPassword.svg";

const UserModal = ({componentId, show, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();

    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isMessageSending, setMessageSending] = useState(false);
    const [user, setUser] = useState(null);

    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const [roles, setRoles] = useState('');
    const [rolesFetched, setRolesFetched] = useState([]);
    const [showLogoInput, setShowLogoInput] = useState(false);

    const [validated, setValidated] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Config.URL.BASE}/api/auth/roles`, distractRequestGetOptions(currentUser));
                setRolesFetched(response.data);
            } catch (error) {
                console.error(error.message);
            }
            try {
                const response = await axios.get(`${Config.URL.BASE}/api/users/${componentId}`, distractRequestGetOptions(currentUser));
                setUser(response.data);
                setUsername(response.data.username)
                setFirstname(response.data.firstname)
                setLastname(response.data.lastname)
                setRoles(response.data.roles.map(role => role.name))
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);

    const handleCheckboxClick = () => {
        setChecked(prevState => !prevState);
    };

    const handleRoleChange = (selected) => {
        if (selected.length > 0) {
            setRoles([selected[0].value]);
        } else {
            setRoles([]);
        }
    }

    const handleResetPassword = async (userId) => {
        setMessageSending(true)

        fetch(`${Config.URL.BASE}/api/email/send-password-reset-email?userId=${userId}`, {
            method: 'POST', headers: distractRequestHeaders(currentUser),
        })
            .then(response => {
                if (response.ok) {
                    toast.success("The email was sent successfully"); // Show success message using the appropriate method
                    return response.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
            })
            .finally(() => {
                setMessageSending(false); // Make sure to handle this state change properly
            });

    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            const requestData = {
                'id': componentId, 'username': username, 'firstname': firstname, 'lastname': lastname
            };

            if (roles.length > 0) {
                requestData.roles = roles;
            }

            await fetch(`${Config.URL.BASE}/api/users`, {
                method: 'PUT', headers: distractRequestHeaders(currentUser), body: JSON.stringify(requestData),
            }).finally(() => {
                if (currentUser.username !== username) {
                    toast.info("You'll be logout in 5 seconds")
                    setTimeout(() => {
                        handleClose();
                        authService.logout();
                    }, 5000);
                }
                window.location.reload();
            });
        } catch (error) {
            console.error(error.message);
            toast.error('Error updating user');
        }
    };

    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Stwórz użytkownika</Modal.Title>
            </Modal.Header>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    {!loading && (<Row style={{padding: '15px'}}>
                        <Form.Group className="mb-4 mt-1" as={Col} md="2" controlId="formCompanyName">
                            <Form.Label className="create-worker-label">Id</Form.Label>
                            <Form.Control
                                type="text"
                                value={user.id}
                                className="input-field"
                                disabled={!(componentId === undefined)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4 mt-1" as={Col} md="6" controlId="formCompanyName">
                            <Form.Label className="create-worker-label">username</Form.Label>
                            {checked && (<Form.Control
                                type="text"
                                placeholder={user.username}
                                value={username}
                                className="input-field"
                                onChange={(event) => setUsername(event.target.value)}
                                required
                            />)}
                            {!checked && (<Form.Control
                                type="text"
                                placeholder={user.username}
                                value={username}
                                className="input-field"
                                disabled
                            />)}
                            <Form.Check
                                type="checkbox"
                                className={'search-checkbox mt-1'}
                                id="toggleShowPassword"
                                label={<span
                                    className="search-checkbox-label">Change username (requires re-login)</span>}
                                checked={checked}
                                onChange={handleCheckboxClick}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a username.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Col xs={12} md={12}></Col>
                        <Form.Group className="mb-4 mt-1" as={Col} md="6" controlId="formCompanyName">
                            <Form.Label className="create-worker-label">Imię</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={user.firstname}
                                value={firstname}
                                className="input-field"
                                onChange={(event) => setFirstname(event.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a firstname.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} md="6" controlId="formCompanyName">
                            <Form.Label className="create-worker-label">Nazwisko</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={user.lastname}
                                value={lastname}
                                className="input-field"
                                onChange={(event) => setLastname(event.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a lastname.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Col xs={12} md={12}></Col>
                    </Row>)}

                    <Row style={{marginBottom: '10px', padding: '15px'}}>
                        <Form.Group controlId="showLogoInputCheckbox">
                            <Form.Check
                                type="checkbox"
                                className={'search-checkbox'}
                                label={<span className="search-checkbox-label" style={{top: '4px'}}>Show advanced settings</span>}
                                checked={showLogoInput}
                                onChange={(e) => setShowLogoInput(e.target.checked)}
                            />
                        </Form.Group>

                        {showLogoInput && (<div className={'mt-4'}>
                            <Form.Group as={Col} md="4" controlId="formCountry">
                                <Form.Label className={"create-worker-label"}>Rola <span style={{color: "#c9c9c9"}}>(requires re-login)</span></Form.Label>
                                {loading && <Spinner animation="border" variant="dark"/>}
                                {!loading && (<Typeahead
                                    id="my-typeahead"
                                    inputProps={{style: {height: '45px'}}}
                                    options={rolesFetched.map((role) => ({
                                        label: role.name.replaceAll("_", " ").replaceAll("ROLE", " ").trim(),
                                        value: role.name,
                                    }))}
                                    onChange={handleRoleChange}
                                />)}
                            </Form.Group>

                            <div className={'mt-4'}>
                                <Form.Label className="create-worker-label">Reset Password</Form.Label>
                                <div style={{width: "90px"}}
                                     className="button-block square-with-text"
                                     onClick={() => handleResetPassword(componentId)}>
                                    {isMessageSending ? <div className="spinner-border" role="status"></div> :
                                        <PasswordReset width={'18px'}/>}
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className={"button-reject"} variant="secondary" onClick={handleClose}>
                        Zamknąć
                    </button>
                    <button className="button-submit glow-on-hover" type="submit" variant="primary">
                        Ratować
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>

    </>);
};

export default UserModal;
