import React, {useEffect, useState} from 'react';
import {Form, Spinner} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import './assets/css/createWorker.css';
import Row from 'react-bootstrap/Row';
import {useNavigate, useParams} from 'react-router-dom';
import AuthService from '../../services/auth.service';
import Config from '../../config';
import {distractRequestGetOptions, distractRequestHeaders,} from '../../services/utility';
import Col from 'react-bootstrap/Col';
import 'react-datepicker/dist/react-datepicker.css';
import RelativeForm from '../../components/forms/relativesForm/RelativeForm';
import PersonDetailsForm from '../../components/persondetailsForm/PersonDetailsForm';
import PassportForm from '../../components/forms/passportForm/PassportForm';
import VisaForm from '../../components/forms/visaForm/VisaForm';
import InputCertificationArray from '../../components/forms/inputCertificationArrayForm/InputCertificationArrayForm';
import InsuranceForm from '../../components/forms/insuranceForm/InsuranceForm';
import ContractForm from '../../components/forms/contractForm/ContractForm';
import axios from 'axios';
import ContactForm from '../../components/forms/contactForm/ContactForm';
import AddressForm from '../../components/forms/addressForm/AddressForm';
import {Typeahead} from "react-bootstrap-typeahead";
import InputBillArray from "../../components/forms/inputBillArray/InputBillArray";
import PeselForm from "../../components/forms/peselForm/PeselForm";
import {toast} from "react-toastify";
import withAuth from "../../services/hooks/withAuth";
import NotesForm from "../../components/forms/notesForm/NotesForm";

const CreateWorker = () => {
        const currentUser = AuthService.getCurrentUser();

        const [loading, setLoading] = useState(true);

        const {companySlug} = useParams();
        const [companies, setCompanies] = useState([]);
        const [companyToDisplay, setCompanyToDisplay] = useState(null);
        const [countries, setCountries] = useState();
        const navigation = useNavigate();

        let companyId = null;
        if (companySlug) {
            companyId = companySlug.split('-').pop();
        }

        const [certifications, setCertifications] = useState(null);

        const [worker, setWorker] = useState({
            passport: null, visa: null, peselNumber: null, personDetails: {
                firstName: null,
                lastName: null,
                speciality: null,
                birthday: null,
                countryId: null,
                region: null,
                city: null,
                address: null,
                motherFirstName: null,
                motherLastName: null,
                fatherFirstName: null,
                fatherLastName: null
            }, bills: [], phones: [], email: null, insurance: {
                ekuzDateEnd: null, cfrDateEnd: null, badanieStart: null, badanieEnd: null
            }, contract: null, isFired: false, companyId: companyId
        });

        const [validated, setValidated] = useState(false);

        useEffect(() => {
            const fetchData = async () => {
                setLoading(true);
                try {

                    const responseCompanies = await axios.get(`${Config.URL.BASE}/api/companies`, distractRequestGetOptions(currentUser));
                    setCompanies(responseCompanies.data);

                    if (companySlug) {
                        const response = await axios.get(`${Config.URL.BASE}/api/companies/${companySlug.split('-').pop()}`, distractRequestGetOptions(currentUser));
                        setCompanyToDisplay(response.data);
                    }

                    const responseCountries = await axios.get(`${Config.URL.BASE}/api/countries`, distractRequestGetOptions(currentUser));
                    setCountries(responseCountries.data);
                } catch (error) {
                    console.error(error.message);
                }
                setLoading(false);
            };

            fetchData();
        }, [companySlug]);

        function isValidEmail(email) {
            // Regular expression for basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }

        const handleCompanyChange = (selected) => {
            if (selected.length > 0) {
                const selectedCompanyId = selected[0].value;
                setWorker({
                    ...worker, companyId: selectedCompanyId
                });
            } else {
                setWorker({
                    ...worker, companyId: null
                });
            }
        }

        const handleSubmit = async (event) => {
            event.preventDefault();

            const form = event.currentTarget;

            if (!worker.companyId) {
                toast.error("Please select a company for a worker");
                return;
            }


            if (worker.email && !isValidEmail(worker.email)) {
                // The email is valid
                toast.error("The email is invalid: " + worker.email);
                return;
            }


            if (form.checkValidity() === false) {
                event.stopPropagation();
                setValidated(true);
                return;
            }
            let workerSlug = null;
            try {

                const response = await fetch(`${Config.URL.BASE}/api/workers?username=${currentUser.username}`, {
                    method: 'POST',
                    headers: distractRequestHeaders(currentUser),
                    body: JSON.stringify(worker)
                });

                if (response.ok) {
                    const workerResponseDTO = await response.json();
                    const workerId = workerResponseDTO.id;
                    workerSlug = workerResponseDTO.slug;
                    // Loop through certifications and send requests for each one
                    if (certifications !== null) {
                        for (const certification of certifications) {
                            if (certification !== null) {
                                await fetch(`${Config.URL.BASE}/api/workers/certifications?username=${currentUser.username}&workerId=${workerId}`, {
                                    method: 'POST',
                                    body: JSON.stringify(certification),
                                    headers: distractRequestHeaders(currentUser),
                                });
                            }
                        }
                    }
                    if (worker.contract !== null) {
                        const contractBody = {
                            contractTitle: worker.contract.contractTitle,
                            hireDate: worker.contract.hireDate,
                            contractDateStart: worker.contract.contractDateStart,
                            contractDateEnd: worker.contract.contractDateEnd,
                            contractNotes: worker.contract.contractNotes,
                            isTermless: worker.contract.isTermless,
                        }
                        await fetch(`${Config.URL.BASE}/api/workers/contracts?username=${currentUser.username}&workerId=${workerId}`, {
                            method: 'POST',
                            body: JSON.stringify(contractBody),
                            headers: distractRequestHeaders(currentUser),
                        });
                    }
                    toast.success("Worker created successfully", {autoClose: 1500})
                } else {
                    const errorData = await response.json();
                    if (errorData.message) {
                        toast.error(errorData.message);
                    } else {
                        toast.error('An error occurred. Please try again.');
                    }
                }
            } catch
                (error) {
                toast.error('An error occurred. Please check your internet connection and try again.');
            } finally {
                if (workerSlug) {
                    navigation(`/${companySlug}/workers/${workerSlug}/info`)
                }
            }

        };

        return (<div className={"create-worker-container"}>
            <Container>
                <div className={"create-worker-section"}>
                    <header className={"mb-5"}>Dodaj pracownika</header>
                    {loading ? (<div className="spinner"/>) : (
                        <Form validated={validated} onSubmit={handleSubmit}>
                            {/* Worker details */}
                            <span className={"title mb-4"}>Dane osobowe</span>
                            <Row className="mb-5">
                                <PersonDetailsForm
                                    worker={worker}
                                    setWorker={setWorker}
                                    requiredFields={['firstName', 'lastName', 'speciality']}
                                />
                            </Row>
                            {/* Passport details */}
                            <span className={"title mb-4"}>Dokumenty</span>
                            <Row className="mb-4">
                                <PassportForm
                                    worker={worker}
                                    setWorker={setWorker}
                                    countries={countries}
                                />
                            </Row>

                            {/* Visa details */}
                            <Row className="mb-5">
                                <VisaForm
                                    worker={worker}
                                    setWorker={setWorker}
                                />
                                <PeselForm worker={worker} setWorker={setWorker}/>
                            </Row>

                            <span className={"title mb-4"}>Adres</span>
                            <Row>
                                {/* Address details */}
                                <AddressForm
                                    worker={worker}
                                    setWorker={setWorker}
                                    countries={countries}
                                />
                            </Row>

                            <span className={"title mb-3 mt-5"}>Rodzice</span>
                            {/* Relatives */}
                            <Row className="mb-5">
                                <RelativeForm worker={worker} setWorker={setWorker}/>
                            </Row>

                            <span className={"title mb-4"}>A1</span>
                            {/*Certifications */}
                            <InputCertificationArray
                                certifications={certifications}
                                setCertifications={setCertifications}
                            />

                            <span className={"title mb-4 mt-4"}>Rachunki</span>
                            <InputBillArray
                                worker={worker}
                                setWorker={setWorker}
                            />

                            <span className={"title mb-4 mt-4"}>Ubezpieczenie</span>
                            <Row>
                                {/* Insurance */}
                                <InsuranceForm worker={worker} setWorker={setWorker}/>
                            </Row>


                            <span className={"title mb-4 mt-4"}>Umowa</span>
                            <Row>
                                {/* Contract */}
                                <ContractForm worker={worker} setWorker={setWorker}/>
                            </Row>


                            <span className={"title mb-4 mt-4"}>Kontakt</span>
                            {/* Contact details */}
                            <ContactForm
                                worker={worker}
                                setWorker={setWorker}
                            />

                            <span className={"title mb-4 mt-4"}>Notes</span>
                            {/* Contact details */}
                            <NotesForm
                                worker={worker}
                                setWorker={setWorker}
                            />

                            <Form.Group className={"mt-4"} as={Col} md="2" controlId="formCountry">
                                <Form.Label className={"create-worker-label"}>Firma</Form.Label>
                                {loading && <Spinner animation="border" variant="dark"/>}
                                {!loading && (<Typeahead
                                    id="my-typeahead"
                                    inputProps={{style: {height: '45px'}}}
                                    options={companies.map((company) => ({
                                        label: company.companyName, value: company.id,
                                    }))}
                                    defaultSelected={(companyToDisplay !== null) ? [{
                                        label: companyToDisplay.companyName, value: companyToDisplay.id
                                    }] : []}
                                    onChange={handleCompanyChange}
                                />)}
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid country.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <button type="submit" className="button-submit glow-on-hover color mt-5">
                                Dodaj
                            </button>
                            <button type={"button"}
                                    onClick={() => window.location.href = (companyId === undefined ? `/` : `/${companySlug}`)}
                                    className={"button-reject"}>
                                Anuluj
                            </button>
                        </Form>)}
                </div>
            </Container></div>);
    }
;

export default withAuth(CreateWorker);

