import React, {useEffect, useState} from "react";
import './assets/css/company.css';
import {Spinner} from "react-bootstrap";
import Config from "../../config";
import {distractRequestGetOptions} from "../../services/utility";
import AuthService from "../../services/auth.service";

const CompanyTransmission = ({company, handleTransmission, isCurrentCompany}) => {
    const currentUser = AuthService.getCurrentUser();

    const [loading, setLoading] = useState(true);
    const [logoData, setLogoData] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                fetch(`${Config.URL.BASE}/api/companies/companyLogo/${company.id}`, distractRequestGetOptions(currentUser))
                    .then(response => response.arrayBuffer())
                    .then(data => setLogoData(data))
                    .catch(error => console.error(error));
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    const logoUrl = `data:image/png;base64,${btoa(new Uint8Array(logoData).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`;

    const isLogoPresent = (logoData) => {
        return !(logoData === null || logoData.byteLength === 0);
    };

    return (<span>
        {loading && <Spinner animation="border" variant="dark"/>}
        {!loading && (
            isCurrentCompany ?
                <div className={`company-block-disabled`} style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                    <div className="company-logo-disabled">
                        {isLogoPresent(logoData) ? <img className={"company-logo-disabled"} src={logoUrl}/> :
                            <div className={"square-logo"}><p>{company.companyName.slice(0,2)}</p></div>}
                    </div>
                    <div className="company-text-block ">
                        <p>{company.companyName}</p>
                    </div>
                    {company.workersCount}
                </div> :
                <div className={`company-block`}
                     onClick={() => handleTransmission(company.id)}>
                    <div className="company-logo-block">
                        {isLogoPresent(logoData) ? <img className={"company-logo"} src={logoUrl}/> :
                            <div className={"square-logo"}><p>{company.companyName.slice(0,2)}</p></div>}
                    </div>
                    <div className="company-text-block">
                        <p>{company.companyName}</p>
                    </div>
                    {company.workersCount}
                </div>
        )}
        </span>);
};

export default CompanyTransmission;