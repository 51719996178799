import React, {useState} from 'react';
import Col from "react-bootstrap/Col";
import {Form} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import {getFlagEmoji} from "../../../services/utility";
import {toast} from "react-toastify";

const AddressForm = ({worker, setWorker, countries}) => {
    const [checked, setChecked] = useState(false);

    const handleCheckboxClick = () => {
        setChecked(prevState => !prevState);
    };

    const handleCountryChange = (selected) => {
        const country = selected[0];
        if (country) {
            setWorker({
                ...worker,
                personDetails: {
                    ...worker.personDetails,
                    countryId: country.id,
                },
            });
        } else {
            toast.error("Country should be chosen");
            setWorker({
                ...worker,
                personDetails: {
                    ...worker.personDetails,
                    countryId: "",
                },
            });
        }
    };
    const handleRegionChange = (event) => {
        setWorker({
            ...worker, personDetails: {
                ...worker.personDetails,
                region: event.target.value
            }
        });
    };

    const handleCityChange = (event) => {
        setWorker({
            ...worker, personDetails: {
                ...worker.personDetails,
                city: event.target.value
            }
        });
    };

    const handleStreetChange = (event) => {
        const value = event.target.value;
        if (value.length > 86) {
            toast.error("Street cannot exceed 86 characters");
            return;
        }
        if (event.target.value.replaceAll(" ", "").length === 0) {
            toast.error("Street cannot be empty");
            return;
        }
        setWorker({
            ...worker, personDetails: {
                ...worker.personDetails,
                address: value
            }
        });
    };

    const handleOriginStreetChange = (event) => {
        const value = event.target.value;
        if (value.length > 86) {
            toast.error("Street cannot exceed 86 characters");
            return;
        }
        if (event.target.value.replaceAll(" ", "").length === 0) {
            toast.error("Street cannot be empty");
            return;
        }
        setWorker({
            ...worker, personDetails: {
                ...worker.personDetails,
                originAddress: value
            }
        });
    };

    return (
        <>
            <Form.Group as={Col} md="2" controlId="formCountry">
                <Form.Label className="create-worker-label">Kraj urodzenia</Form.Label>
                <Typeahead
                    inputProps={{style: {height: '45px'}}}
                    labelKey="name"
                    options={countries}
                    allowNew // Enable adding custom options
                    newSelectionPrefix="Add new country: "
                    placeholder="Poland"
                    renderMenuItemChildren={(option, {text}) => (
                        <div>
                            {getFlagEmoji(option.iso)} {option.name}
                        </div>
                    )}
                    onChange={handleCountryChange}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid country.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="2" controlId="formCity">
                <Form.Label className="create-worker-label">Miasto</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Kraków"
                    value={worker.personDetails.city}
                    className={"input-field"}
                    onChange={handleCityChange}
                />
                <Form.Control.Feedback type="invalid">Please enter a city.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formStreet">
                <Form.Label className="create-worker-label">Region</Form.Label>
                {checked && (
                    <Form.Control
                        type="text"
                        placeholder="Małopolskie"
                        value={worker.personDetails.region}
                        className={"input-field"}
                        onChange={handleRegionChange}
                    />
                )}
                {!checked && (
                    <Form.Control
                        type="text"
                        disabled
                        className={"input-field"}
                        placeholder="Małopolskie"
                    />
                )}
                <div className={'group checkbox'} onClick={handleCheckboxClick}
                     style={{marginLeft: "2px", marginTop: "7px"}}>
                    <input type="checkbox" checked={checked} readOnly={true}/>
                    <label htmlFor="rememberMe">Uwzględnij region</label>
                </div>

            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formStreet">
                <Form.Label className="create-worker-label">Adres w kraju pochodzenia</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder="ul. Kvitki Cisuk 54"
                    value={worker.personDetails.originAddress}
                    onChange={handleOriginStreetChange}
                />
                <Form.Control.Feedback type="invalid">Please enter a street.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" style={{marginTop: "-50px"}} controlId="formStreet">
                <Form.Label className="create-worker-label">Adres w Polsce</Form.Label>
                <Form.Control
                    type="text"
                    className={"input-field"}
                    placeholder="96 Tarkiln Hill St."
                    value={worker.personDetails.address}
                    onChange={handleStreetChange}
                />
                <Form.Control.Feedback type="invalid">Please enter a street.</Form.Control.Feedback>
            </Form.Group>

        </>
    );
}

export default AddressForm;
