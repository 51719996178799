import React from 'react';

const NoteEdit = ({note, notes, index, worker, setWorker, setIsValidToUpdate, setNotes}) => {
    // const updateNotes = (updatedNotes) => {
    //     // setWorker({
    //     //     ...worker,
    //     //     notes: updatedNotes,
    //     // });
    //     // setIsValidToUpdate(true);
    // };

    const handleKeyChange = (e) => {
        const updatedNote = {...note, key: e.target.innerText};
        const updatedNotes = [...notes];
        updatedNotes[index] = updatedNote;
        setNotes(updatedNotes);
    };

    const handleValueChange = (e) => {
        const updatedNote = {...note, value: e.target.innerText};
        const updatedNotes = [...notes];
        updatedNotes[index] = updatedNote;
        setNotes(updatedNotes);
    };

    const handleRemoveNote = () => {
        const updatedNotes = [...notes];
        updatedNotes.splice(index, 1);
        setNotes(updatedNotes);
    };

    return (
        <>
            <div className="note animate__animated animate__fadeIn">
        <span>
          Key:
          <div
              style={{minWidth: '40px', display: 'inline-block', color: 'black', marginLeft: '5px'}}
              contentEditable={true}
              onBlur={handleKeyChange}
              suppressContentEditableWarning={true}
          >
            {note.key}
          </div>
        </span>

                <div></div>
                <span>
          Value:
          <div
              style={{minWidth: '40px', display: 'inline-block', color: 'black', marginLeft: '5px'}}
              contentEditable={true}
              onBlur={handleValueChange}
              suppressContentEditableWarning={true}
          >
            {note.value}
          </div>
        </span>
            </div>
            <button className={'add-button-style'} style={{marginBottom: '20px'}} type="button"
                    onClick={handleRemoveNote}>
                -
            </button>
        </>
    );
};

export default NoteEdit;
