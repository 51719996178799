import React from "react";
import CompanySliderComponent from "./CompanySliderComponent";
import CompanyDefaultComponent from "./CompanyDefaultComponent";

const CompaniesSlider = ({companies, navigationLinkBase}) => {

    return (<div className={'companies-horizontal-block'}>
            <div>
                <CompanyDefaultComponent navigationLinkBase={navigationLinkBase}/>
            </div>
            <div className={'vertical-slider animate__animated animate__bounceIn'}>
                {companies.map((company, index) => (
                    <CompanySliderComponent key={index} company={company} navigationLinkBase={navigationLinkBase}/>))}
            </div>
        </div>

    );
};

export default CompaniesSlider;