import React, {useState} from "react";
import {ReactComponent as FireIcon} from "../../../assets/svgs/FiredWorkerWhite.svg";
import {ReactComponent as Relax} from "../../../assets/svgs/OnVacation.svg";
import {ReactComponent as Cocktail} from "../../../assets/svgs/cocktail.svg";
import {ReactComponent as EditIcon} from "../../../assets/svgs/worker-list/Edit.svg";
import {ReactComponent as LookIcon} from "../../../assets/svgs/worker-list/ViewWorker.svg";
import {checkDateExpiration, unsecuredCopyToClipboard} from "../../../services/utility";
import ContractColumn from "./ContractColumn";

const WorkerRow = ({worker, navigate, companySlug, setOverlayState, handleHide}) => {
    const isFired = worker.fired;
    const isOnVacation = worker.onVacation;
    const hasPassport = worker.passport && worker.passport.passportNumber !== null;
    const hasVisa = worker.visa && worker.visa.visaNumber !== null;
    const hasCertificate = worker.certificate && worker.certificate.certificateDateStart && worker.certificate.certificateDateEnd;

    const applyStyles = () => {
        const styles = {
            borderStartStartRadius: "7px", borderEndStartRadius: "7px", width: "110px",
        };
        if (isFired) {
            styles.background = "#ec6161";
        } else if (isOnVacation) {
            styles.background = "#497fda";
        }
        return styles;
    };

    const [isPressed, setIsPressed] = useState(false);

    const handlePress = () => {
        setIsPressed(true);
    };

    const handleRelease = () => {
        setIsPressed(false);
    };


    return (<tr key={worker.id}>
        <td style={applyStyles()}>
            <div style={{display: "flex", gap: "18px", paddingLeft: "15px", marginTop: "11px"}}>
                {isFired && (<div className={"fired-block"}>
                    <FireIcon width={"26px"} height={"26px"}/>
                </div>)}
                {!isFired && isOnVacation && (<span className={isPressed && "animate__animated animate__tada"}
                                                    key={isPressed ? 'cocktail' : 'relax'}
                                                    onMouseDown={handlePress}
                                                    onMouseUp={handleRelease}>
                    {isPressed ? (<Cocktail width="22px"
                                            height="22px"/>) : (<Relax
                        width="22px"
                        height="22px"
                        style={{cursor: 'pointer'}}
                    />)}
                </span>)}
                <div className={"id"}>
                    <span style={isFired || isOnVacation ? {color: "#FFF"} : {}}>{worker.index}</span>
                </div>
            </div>
        </td>
        <td>
            <div
                onClick={(event) => unsecuredCopyToClipboard(event, `${worker.personDetails.firstName} ${worker.personDetails.lastName}`, setOverlayState, handleHide)}>
                {`${worker.personDetails.lastName} ${worker.personDetails.firstName}`}
            </div>
            <span>
          {hasPassport && (<span
              onClick={(event) => unsecuredCopyToClipboard(event, worker.passport.passportNumber, setOverlayState, handleHide)}>
              {worker.passport.passportNumber}
            </span>)}
                {hasVisa && (<>
                    {" / "}
                    <span
                        onClick={(event) => unsecuredCopyToClipboard(event, worker.visa.visaNumber, setOverlayState, handleHide)}>
                {worker.visa.visaNumber}
              </span>
                </>)}
        </span>
        </td>
        <td className={'parsel'}>
        <span onClick={(event) => unsecuredCopyToClipboard(event, worker.peselNumber, setOverlayState, handleHide)}
              style={{cursor: 'pointer'}}>
          {worker.peselNumber}
        </span>
        </td>
        <ContractColumn contract={worker.contract} worker={worker} setOverlayState={setOverlayState}
                        handleHide={handleHide}/>
        <td>
            {hasCertificate && (<>
                <div style={{color: checkDateExpiration(worker.certificate.certificateDateEnd)}}
                     onClick={(event) => unsecuredCopyToClipboard(event, `${worker.certificate.certificateDateStart} - ${worker.certificate.certificateDateEnd}`, setOverlayState, handleHide)}>
                    {`${worker.certificate.certificateDateStart} - ${worker.certificate.certificateDateEnd}`}
                </div>
                <span
                    onClick={(event) => unsecuredCopyToClipboard(event, worker.certificate.certificateNumber, setOverlayState, handleHide)}>
                    {worker.certificate.certificateNumber}</span>
            </>)}
        </td>
        <td className={'actions'}>
            <div style={{display: "flex", gap: "35px"}}>
                <div onClick={() => navigate(`/${companySlug}/workers/${worker.slug}/info/true`)}>
                    <EditIcon/>
                </div>
                <div onClick={() => navigate(`/${companySlug}/workers/${worker.slug}/info`)}>
                    <LookIcon/>
                </div>
            </div>
        </td>
    </tr>);
};

export default WorkerRow;
