import React, {forwardRef} from "react";

const CustomNotificationContextMenu = forwardRef(({
                                                      onRemove,
                                                      onUnseen,
                                                      onClose,
                                                      isSeenAlready,
                                                      currentUserId,
                                                      notificationId
                                                  }, ref) => {
    return (<div ref={ref} className="context-menu" onClick={onClose}>
        {isSeenAlready &&
            <div className="context-menu-item" onClick={() => onUnseen(currentUserId, notificationId)}>
                Mark as unseen
            </div>}
        <div className="context-menu-item" onClick={() => onRemove(notificationId)}>
            Remove
        </div>
    </div>);
})

export default CustomNotificationContextMenu;