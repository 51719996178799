import React, {useRef, useState} from 'react';
import {OverlayFun} from '../../Overlay';
import {unsecuredCopyToClipboard} from "../../../services/utility";

const Pesel = ({pesel}) => {
    const overlayRef = useRef(null);
    const [overlayState, setOverlayState] = useState({
        show: false, target: null,
    });

    const {show, target} = overlayState;

    const handleHide = () => {
        setTimeout(() => {
            setOverlayState({show: false, target: null});
        }, 380);
    };

    return (<>
        <div className="worker-card pesel">
            <label>Pesel</label>
            <span
                onClick={(event) => unsecuredCopyToClipboard(event, pesel, setOverlayState, handleHide)}>{pesel}</span>
        </div>
        <OverlayFun ref={overlayRef} show={show} target={target} onHide={handleHide}/>
    </>);
};

export default Pesel;
