import React, {useEffect, useState} from 'react';
import '../assets/css/insurance.css';
import {checkDateExpiration, stringToDate, transformDate} from "../../../../services/utility";

const InsuranceEdit = ({worker, setWorker, setIsValidToUpdate}) => {

    const [ekuzNumber, setEkuzNumber] = useState(worker.insurance.ekuzNumber);
    const [ekuzDateEnd, setEkuzDateEnd] = useState(worker.insurance.ekuzDateEnd);
    const [cfrDateStart, setCfrDateStart] = useState(worker.insurance.cfrDateStart);
    const [cfrDateEnd, setCfrDateEnd] = useState(null);
    const [badanieStart, setBadanieStart] = useState(worker.insurance.badanieStart);
    const [badanieEnd, setBadanieEnd] = useState(worker.insurance.badanieEnd);

    useEffect(() => {

        if (!cfrDateStart) {
            setCfrDateEnd(null);
            setWorker({
                ...worker, insurance: {
                    ...worker.insurance, "cfrDateEnd": null,
                }
            })
        } else if (cfrDateStart.length > 9) {
            const startDate = stringToDate(cfrDateStart);
            const endDate = new Date(startDate);
            endDate.setFullYear(endDate.getFullYear() + 1);

            // Now, update the cfrDateEnd state with the new endDate
            setCfrDateEnd(transformDate(endDate));
            setWorker({
                ...worker, insurance: {
                    ...worker.insurance, "cfrDateEnd": transformDate(endDate),
                }
            })
        }

        setWorker({
            ...worker, insurance: {
                "id": worker.insurance.id,
                "ekuzNumber": ekuzNumber,
                "ekuzDateEnd": ekuzDateEnd,
                "cfrDateStart": cfrDateStart,
                "cfrDateEnd": cfrDateEnd,
                "badanieStart": badanieStart,
                "badanieEnd": badanieEnd
            }
        })
        setIsValidToUpdate(true)
    }, [ekuzDateEnd, cfrDateStart, cfrDateEnd, badanieStart, badanieEnd, ekuzNumber]);

    const handleEkuzNumberChange = (e) => {
        setEkuzNumber(e.target.innerText);
    };

    const handleEkuzDateEndChange = (e) => {
        setEkuzDateEnd(e.target.innerText);
    };

    const handleCfrDateStartChange = (e) => {
        setCfrDateStart(e.target.innerText);
    };

    const handleBadanieStartChange = (e) => {
        setBadanieStart(e.target.innerText);
    };

    const handleBadanieEndChange = (e) => {
        setBadanieEnd(e.target.innerText);
    };

    const ekuzExpirationColor = checkDateExpiration(ekuzDateEnd);
    const cfrExpirationColor = checkDateExpiration(cfrDateEnd);
    const badanieExpirationColor = checkDateExpiration(badanieEnd);

    return (<>
        <div className="insurance">
            <div className="block-edit ekuz">
                <h4 className={"mb-3"}>EKUZ</h4>
                <p style={{color: "#000"}}>
                    <span>Number:</span>
                    <div
                        style={{width: "103px", display: 'inline-block'}}
                        contentEditable
                        onBlur={handleEkuzNumberChange}
                        suppressContentEditableWarning
                    >
                        {ekuzNumber}
                    </div>
                </p>
                <p style={{color: ekuzExpirationColor}}>
                    <span>Ważność do:</span>
                    <div
                        style={{width: "103px", display: 'inline-block'}}
                        contentEditable
                        onBlur={handleEkuzDateEndChange}
                        suppressContentEditableWarning
                    >
                        {ekuzDateEnd}
                    </div>
                </p>
            </div>
            <div className="block-edit">
                <h4>CFR</h4>
                <p style={{color: cfrExpirationColor}}>
                    <span>Ważność od:</span>
                    <div
                        style={{width: "103px", display: 'inline-block'}}
                        contentEditable
                        onBlur={handleCfrDateStartChange}
                        suppressContentEditableWarning
                    >{cfrDateStart}</div>
                </p>
            </div>
            {cfrDateStart && cfrDateStart.length > 9 &&
                <div className="block-edit cfr-date-end animate__animated animate__tada"><h4>CFR Date End</h4>
                    <p style={{color: cfrExpirationColor}}>
                        <span>Ważność do:</span>
                        <div
                            style={{width: "103px", display: 'inline-block'}}
                        >{cfrDateEnd && cfrDateEnd}</div>
                    </p>
                </div>}
            <div className="block-edit badanie">
                <h4>Badanie</h4>
                <p style={{color: badanieExpirationColor}}>
                    <span>Okres:</span>
                    <div
                        style={{width: "100px", display: 'inline-block', marginLeft: '3px'}}
                        contentEditable
                        onBlur={handleBadanieStartChange}
                        suppressContentEditableWarning
                    >{badanieStart}</div>
                    {' - '}
                    <div
                        style={{width: "100px", display: 'inline-block', marginLeft: '3px'}}
                        contentEditable
                        onBlur={handleBadanieEndChange}
                        suppressContentEditableWarning
                    >{badanieEnd}</div>
                </p>
            </div>
        </div>
    </>);
};

export default InsuranceEdit;
