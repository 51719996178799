import React from "react";
import {useParams} from "react-router-dom";
import VacationsFilter from "../../components/vacations/VacationsFilter";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import withAuth from "../../services/hooks/withAuth";
import {Col} from "react-bootstrap";

const Vacations = () => {
    const {workerSlug, companySlug} = useParams();

    return (
        <Col style={{paddingLeft: "65px", marginTop: "45px", marginBottom: "100px"}}>
            <DndProvider backend={HTML5Backend}>
                <VacationsFilter workerSlug={workerSlug} companySlug={companySlug}/>
            </DndProvider>
        </Col>
    );
};

export default withAuth(Vacations);
