import React, {useEffect, useState} from "react";
import "../documents/css/download-button.css";
import {distractRequestGetPDFOptions} from "../../services/utility";
import AuthService from "../../services/auth.service";

const DownloadButton = ({link, filename}) => {
    const currentUser = AuthService.getCurrentUser();
    const filenameInit = filename;
    const [isRunning, setIsRunning] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let interval;

        if (isRunning) {
            interval = setInterval(() => {
                // Update the progress value (0% to 100%)
                setProgress((prevProgress) => {
                    const newProgress = prevProgress + 1;
                    if (newProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => {
                            setIsRunning(false);
                        }, 7000); // Delay before marking as "done"
                    }
                    return newProgress;
                });
            }, 25); // Adjust the interval duration as needed
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    const downloadPdf = () => {
        fetch(`${link}`, distractRequestGetPDFOptions(currentUser))
            .then(response => {
                if (response.ok) {
                    // Extract the filename from the Content-Disposition header
                    const filename = filenameInit ? filenameInit : 'document.pdf';

                    return response.blob().then(blob => {
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = filename;
                        link.click();
                        URL.revokeObjectURL(url);

                        // Update the progress to 100%
                        setProgress(80);

                        // Mark the download as "done" after a delay
                        setTimeout(() => {
                            setIsRunning(false);
                        }, 1600); // Delay before marking as "done"
                    });
                } else {
                    throw new Error('Failed to download PDF');
                }
            })
            .catch(error => {
                console.error(error);
            });
    };


    const handleClick = () => {
        if (!isRunning) {
            setIsRunning(true);
            downloadPdf()
            setProgress(0); // Reset progress when starting a new download
        }
    };

    const dlClass = `dl ${isRunning ? "run" : ""} ${isRunning ? "done" : ""}`;

    return (
        <div className={dlClass} onClick={handleClick}>
            <div className="dl-box">
                <div className="dl-button">
                    <div className="arrow"></div>
                    <div className="basket">
                        <div className="basket-top"></div>
                    </div>
                </div>
            </div>
            <div className="dl-status">
                <div className="text text-file">Status</div>
                <div className="text text-number">{`${progress}%`}</div>
            </div>
        </div>
    );
};

export default DownloadButton;
