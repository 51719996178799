import React, {useEffect, useState} from 'react';
import {Col, Form, Modal, Row} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../../../services/auth.service';
import Config from '../../../../config';
import {distractRequestHeaders, stringToDate, transformDate} from '../../../../services/utility';
import '../style.css';
import SquareWithText from "../../../square/SquareWithText";
import getMonthAbbreviations from "../../../square/getMonthAbbreviations";
import DateYearPickerExcludeDates from "../../../date/datePicker/DateYearPickerExcludeDates";

const VacationUpdateModal = ({component, workerId, show, success, setSuccess, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();
    const vacation = component;

    const componentId = vacation.id;
    const [vacationDateStart, setVacationDateStart] = useState(stringToDate(vacation.vacationDateStart));
    const [vacationDateEnd, setVacationDateEnd] = useState(stringToDate(vacation.vacationDateEnd));
    const [validated, setValidated] = useState(false);
    const [isPaid, setIsPaid] = useState(vacation.paid);
    const [quantity, setQuantity] = useState(vacation.workingDays); // Initialize with 1 day
    const [isDefaultValueProvided, setDefaultValueProvided] = useState(true); // Initialize with 1 day

    const handleIncreaseQuantity = () => {
        setQuantity(quantity + 1);
        setDefaultValueProvided(false)
    };

    const handleDecreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            setDefaultValueProvided(false)
        }
    };


    const handleCheckboxClick = () => {
        setIsPaid(prevState => !prevState);
    };


    useEffect(() => {
        const handleSuccess = async () => {
            if (success) {

                const vacationDTO = JSON.stringify({
                    "vacationId": componentId,
                    "workerId": workerId,
                    "vacationDateStart": transformDate(vacationDateStart),
                    "vacationDateEnd": transformDate(vacationDateEnd),
                    "workingDays": quantity,
                    "paid": isPaid
                });

                try {
                    await fetch(`${Config.URL.BASE}/api/workers/vacations?username=${currentUser.username}`, {
                        method: 'PUT',
                        body: vacationDTO,
                        headers: distractRequestHeaders(currentUser),
                        redirect: 'follow',
                    });

                    handleClose();
                    window.location.reload();
                } catch (error) {
                    console.error(error);
                }
            }
        };

        handleSuccess();
    }, [success]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        if (vacationDateStart > vacationDateEnd) {
            setSuccess(false);
            toast.error('Data rozpoczęcia nie może być późniejsza niż data zakończenia');
            return;
        }

        const minDate = new Date('1900-01-01');
        if (vacationDateStart < minDate || vacationDateEnd < minDate) {
            setSuccess(false);
            toast.error('Daty nie mogą być wcześniejsze niż 1900');
            return;
        }

        setSuccess(true)
    };

    const currentDate = new Date();
    const isCurrentDate = (vacationDateStart <= currentDate && vacationDateEnd >= currentDate)

    return (<>
        <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
            <Modal.Header closeButton style={{paddingRight: '22px'}}>
                <Modal.Title className="modal-form-title">Zaktualizuj urlop</Modal.Title>
            </Modal.Header>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Row style={{padding: '10px', marginLeft: '43px'}}>
                        <Col md="3" style={{width: '125px', marginRight: '40px', marginTop: '10px'}}>
                            <SquareWithText isCurrent={isCurrentDate}
                                            text={getMonthAbbreviations(transformDate(vacationDateStart), transformDate(vacationDateEnd))}
                                            isPaid={isPaid}/>
                        </Col>
                        <Col md={"9"}>
                            <Row>
                                <Form.Group as={Col} md="3" className="mt-1" style={{width: '240px', paddingTop: '8px'}}
                                            controlId="formCompanyName">
                                    <Form.Label className="create-worker-label">Rozpoczęcie:</Form.Label>
                                    <DateYearPickerExcludeDates
                                        startDate={vacationDateStart}
                                        setStartDate={setVacationDateStart}
                                        excludeDates={[]}
                                    />

                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mt-1" style={{width: '240px', paddingTop: '8px'}}
                                            controlId="formCompanyName">
                                    <Form.Label className="create-worker-label">Zakończenie:</Form.Label>
                                    <DateYearPickerExcludeDates startDate={vacationDateEnd}
                                                                setStartDate={setVacationDateEnd}
                                                                excludeDates={[]}
                                    />
                                </Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    className={'search-checkbox mt-2'}
                                    style={{marginLeft: "11px"}}
                                    id="toggleShowPassword"
                                    label={<span
                                        className="search-checkbox-label">Urlop wypoczynkowy</span>}
                                    checked={isPaid}
                                    onChange={handleCheckboxClick}
                                />
                                <Form.Group as={Col} className={'mt-3'} md="4" controlId="quantitySelector">
                                    <Form.Label>Czas trwania (dni)</Form.Label>
                                    <div className="quantity-selector">
                                        <button
                                            type="button"
                                            style={{width: "20px", height: "20px", padding: "5px"}}
                                            className="button-submit glow-on-hover"
                                            onClick={handleDecreaseQuantity}
                                        >
                                            -
                                        </button>
                                        <Form.Control
                                            type="text"
                                            style={{width: "80px", height: "40px"}}
                                            placeholder="1"
                                            value={quantity}
                                            onChange={(e) => {
                                                const parsedValue = parseInt(e.target.value);
                                                if (!isNaN(parsedValue)) {
                                                    setQuantity(parsedValue);
                                                    setDefaultValueProvided(false)
                                                }
                                            }}
                                        />
                                        <button
                                            type="button"
                                            style={{width: "20px", height: "20px", padding: "5px"}}
                                            className="button-submit glow-on-hover"
                                            onClick={handleIncreaseQuantity}
                                        >
                                            +
                                        </button>
                                    </div>
                                </Form.Group>
                                {isDefaultValueProvided &&
                                    <div className={"mt-3"}>Nie znam wszystkich wydarzeń specjalnych, ale dni potrafię
                                        liczyć ogólnie</div>}

                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-reject" onClick={handleClose}>
                        Zamknij
                    </button>
                    <button className="button-submit glow-on-hover" type="submit">
                        Zapisz
                    </button>
                </Modal.Footer>
            </Form>
            <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false}/>
        </Modal>
    </>);
};

export default VacationUpdateModal;
