import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {Form, Modal} from "react-bootstrap";
import Config from '../../../../config';
import AuthService from "../../../../services/auth.service";
import {ReactComponent as Trash} from "../../../../assets/modal/TrashIcon.svg";
import ConfirmationModal from "./ConfirmationModal";

const CompanyModal = ({companyId, show, handleClose}) => {
    const currentUser = AuthService.getCurrentUser();

    const [updatedCompanyName, setUpdatedCompanyName] = useState('');
    const [updatedLogo, setUpdatedLogo] = useState(null);
    const [showLogoInput, setShowLogoInput] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        setUpdatedLogo(file);
    };

    const handleUpdateCompany = async (headers) => {
        try {
            const requestOptions = {
                method: "PUT", headers: headers
            };

            const response = await fetch(`${Config.URL.BASE}/api/companies/${companyId}?companyName=${updatedCompanyName}`, requestOptions);
            const data = await response.json();

            if (response.ok) {
                toast.success(data.message);
                handleClose();
            } else {
                toast.error(data.error);
            }
        } catch (error) {
            toast.error("An error occurred while updating the company data.");
            console.error(error);
        }
    };

    const handleUpdateCompanyLogo = async (headers) => {
        try {
            const formData = new FormData();
            formData.append("file", updatedLogo);


            const requestOptions = {
                method: "PUT", body: formData, headers: headers
            };

            const response = await fetch(`${Config.URL.BASE}/api/companies/logo/${companyId}`, requestOptions);
            const data = await response.json();

            if (response.ok) {
                toast.success(data.message);
                handleClose();
            } else {
                toast.error(data.error);
            }
        } catch (error) {
            toast.error("An error occurred while updating the company logo.");
            console.error(error);
        }
    };

    const handleSubmit = async () => {

        if (updatedLogo) {
            if (updatedLogo.type !== "image/png" && updatedLogo.type !== "image/jpeg") {
                toast.error("Please select a PNG or JPG image.");
                return;
            }
        }

        const headers = {
            'X-XSRF-TOKEN': currentUser.csrfToken, Authorization: 'Bearer ' + currentUser.token
        };

        try {
            if (showLogoInput && updatedLogo) {
                handleUpdateCompanyLogo(headers)
            }
            if (updatedCompanyName.trim().length > 0) {
                handleUpdateCompany(headers);
            }

        } catch (error) {
            toast.error("An error occurred while updating the company data.");
            console.error(error);
        }
    };

    const blockStyle = {
        width: "130px",
        height: "80px",
        background: "#f8f8f8",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transitionDuration: "0.3s",
        cursor: "pointer",
    };

    const handleRemove = async () => {
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const headers = {
                'X-XSRF-TOKEN': currentUser.csrfToken,
                Authorization: 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json',
            };

            await fetch(`${Config.URL.BASE}/api/companies?companyId=${companyId}`, {
                method: 'DELETE', headers: headers,
            });

            toast.success('Company deleted successfully!', {autoClose: 2000});

            window.location.href = '/';
        } catch (error) {
            toast.error('An error occurred. Please try again.', {autoClose: 2000});
        }
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };
    return (<>
            <Modal show={show} onHide={handleClose} size="lg" className="modal-form">
                <Modal.Header closeButton style={{paddingRight: "22px"}}>
                    <Modal.Title className="modal-form-title">Edytuj firmę</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: "30px"}}>
                    <Form>
                        <Form.Group controlId="companyName">
                            <Form.Label className="create-worker-label">Nazwa firmy</Form.Label>
                            <Form.Control
                                type="text"
                                style={{width: '300px'}}
                                className="input-field"
                                placeholder="Wpisz nową nazwę"
                                value={updatedCompanyName}
                                onChange={(e) => setUpdatedCompanyName(e.target.value)}
                            />
                        </Form.Group>

                        <div style={{marginTop: '15px', marginBottom: '25px'}}>
                            <Form.Group controlId="showLogoInputCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    className={'search-checkbox'}
                                    label={<span className="search-checkbox-label" style={{top: '4px'}}>Show advanced settings</span>}
                                    checked={showLogoInput}
                                    onChange={(e) => setShowLogoInput(e.target.checked)}
                                />
                            </Form.Group>
                        </div>

                        {showLogoInput && (<>

                                <Form.Group controlId="companyLogo">
                                    <Form.Label className="create-worker-label">Company Logo</Form.Label>
                                    <Form.Control
                                        type="file"
                                        style={{width: '500px'}}
                                        onChange={handleLogoChange}/>
                                </Form.Group>

                                {currentUser !== null && currentUser.roles.includes("ROLE_ADMIN") &&
                                    <div style={{marginTop: '30px'}}>
                                        <Form.Label className="create-worker-label">Functions</Form.Label>
                                        <div style={{...blockStyle, width: "90px"}}
                                             className="delete-square-with-text"
                                             onClick={handleRemove}>
                                            <div>
                                                <Trash/>
                                            </div>
                                        </div>
                                    </div>}

                            </>)}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-reject" onClick={handleClose}>
                        Anuluj
                    </button>
                    <button className="button-submit glow-on-hover"
                            onClick={handleSubmit}>
                        Zamknij
                    </button>
                </Modal.Footer>
                <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false}/>
            </Modal>

            <ConfirmationModal
                message={"Confirm to remove company"}
                show={showDeleteConfirmation}
                handleClose={handleCloseDeleteConfirmation}
                handleConfirm={handleConfirmDelete}
            />
        </>);
};

export default CompanyModal;
