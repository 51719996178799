import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {Link, useNavigate} from 'react-router-dom';
import AuthService from '../../services/auth.service';
import withAuth from '../../services/hooks/withAuth';
import './assets/css/createCompany.css';
import Config from "../../config";
import {distractRequestHeadersForCompany} from "../../services/utility";
import {toast} from "react-toastify";

const CreateCompany = () => {
    const currentUser = AuthService.getCurrentUser();
    const [companyName, setCompanyName] = useState('');
    const [file, setFile] = useState(null);
    const [isAdminOrHigher, setAdminOrHigher] = useState(false);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            setAdminOrHigher(currentUser.roles.includes("ROLE_ADMIN"));
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;

        if (!form.checkValidity()) {
            event.stopPropagation();
            setValidated(true);
            return;
        }


        if (file) {
            if (file.type !== "image/png" && file.type !== "image/jpeg") {
                toast.error("Please select a PNG or JPG image.");
                return;
            }
        }

        const formData = new FormData();
        formData.append('company', JSON.stringify({companyName}));
        formData.append('file', file);

        try {
            const response = await fetch(`${Config.URL.BASE}/api/companies`, {
                method: 'POST',
                headers: distractRequestHeadersForCompany(currentUser),
                body: formData,
            });

            if (response.ok) {
                toast.success("Company is created successfully");
                navigate('/');
            } else {
                const errorData = await response.json();
                const errorMessage = errorData.message;

                toast.error(errorMessage);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (<div className="create-company-container">
        <Container>
            <div className="create-company-section">
                <header style={{marginBottom: '30px'}}>Dodaj firmę</header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-4">
                        <Form.Group controlId="formCompanyName">
                            <Form.Label className={"input-field-label"}>Nazwa firmy</Form.Label>
                            <Form.Control
                                required
                                className={"input-field"}
                                type="text"
                                placeholder="Enter company name"
                                value={companyName}
                                onChange={(event) => setCompanyName(event.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a company name.</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-5">
                        <Form.Group controlId="formFile">
                            <Form.Label className={"input-field-label"}>Logo firmy</Form.Label>
                            <Form.Control
                                type="file"
                                className="file-input"
                                onChange={(event) => setFile(event.target.files[0])}
                            />
                            <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <div>
                        <button type="submit" className={"button-submit glow-on-hover"}>
                            <span>Dodaj firmę</span>
                        </button>

                        <Link to="/" style={{textDecoration: 'none'}}>
                            <button className="button-reject">
                                <span>Anuluj</span>
                            </button>
                        </Link>
                    </div>

                </Form>
            </div>
        </Container>
    </div>);
}

export default withAuth(CreateCompany);
