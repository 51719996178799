import React, {forwardRef} from "react";

const CustomContextMenu = forwardRef(({onEdit, onClose, onUp, onDown}, ref) => {
    return (
        <div ref={ref} className="context-menu" onClick={onClose}>
            <div className="context-menu-item" onClick={onEdit}>
                Edit
            </div>
            <div className="context-menu-item" onClick={onUp}>
                Up
            </div>
            <div className="context-menu-item" onClick={onDown}>
                Down
            </div>
        </div>
    );
})

export default CustomContextMenu;