import React, {useEffect, useState} from 'react';
import {Form, Spinner} from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import AuthService from "../../services/auth.service";
import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import './assets/css/createUser.css'
import {Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import Config from "../../config";
import {distractRequestGetOptions} from "../../services/utility";
import {toast} from "react-toastify";
import withAuth from "../../services/hooks/withAuth";

const CreateUser = () => {
    const currentUser = AuthService.getCurrentUser();

    const [loading, setLoading] = useState(true);

    const [validated, setValidated] = useState(false);
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [rolesFetched, setRolesFetched] = useState([]);
    const [roles, setRoles] = useState([]);

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Config.URL.BASE}/api/auth/roles`, distractRequestGetOptions(currentUser));
                setRolesFetched(response.data);
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            // Trim inputs and ensure password length is not more than 128 characters
            const trimmedUsername = username.trim();
            const trimmedFirstname = firstname.trim();
            const trimmedLastname = lastname.trim();
            const trimmedPassword = password.trim().slice(0, 128);
            const trimmedConfirmPassword = confirmPassword.trim().slice(0, 128);

            // Check if password and confirm password match
            if (trimmedPassword !== trimmedConfirmPassword) {
                toast.error('Passwords do not match');
                return;
            }

            if (trimmedUsername.length <= 2) {
                toast.error('Username should be longer than 2 characters');
                return;
            }

            // Create the user object to be sent in the request
            const user = {
                username: trimmedUsername,
                firstname: trimmedFirstname,
                lastname: trimmedLastname,
                password: trimmedPassword,
                roles: roles, // Extract the selected role values
            };

            try {
                // Check if the username already exists
                const existsResponse = await fetch(`${Config.URL.BASE}/api/users/exists?username=${trimmedUsername}`, distractRequestGetOptions(currentUser));
                if (await existsResponse.json() === true) {
                    toast.error('Username already exists');
                } else {
                    const headers = {
                        'X-XSRF-TOKEN': currentUser.csrfToken,
                        Authorization: 'Bearer ' + currentUser.token,
                        'Content-type': 'application/json',
                    };

                    const response = await fetch(`${Config.URL.BASE}/api/auth/signup`, {
                        method: 'POST', headers: headers, body: JSON.stringify(user),
                    });

                    if (response.ok) {
                        // Handle the success response
                        window.location.href = `/users`;
                    } else {
                        // Handle the error response
                        toast.error('Error creating user');
                    }
                }
            } catch (error) {
                console.error(error.message);
                // Handle the error response
                toast.error('Error creating user');
            }
        }

        setValidated(true);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRoleChange = (selected) => {
        if (selected.length > 0) {
            setRoles([selected[0].value]);
        } else {
            setRoles([]);
        }
    }

    return (<div className={"create-worker-container"}>
        <Container>
            <div className={"create-user-section"}>
                <header className={"mb-5"}>Stworzyć pracownika</header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="formCompanyName">
                            <Form.Label className={"create-worker-label"}>Username</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Username"
                                className={"input-field"}
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a passport
                                number.</Form.Control.Feedback>
                        </Form.Group>
                        <Col xs={12} md={12}></Col>
                        <Form.Group className={"mt-4"} as={Col} md="6" controlId="formCompanyName">
                            <Form.Label className={"create-worker-label"}>Imię</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Jakub"
                                className={"input-field"}
                                value={firstname}
                                onChange={(event) => setFirstname(event.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a passport
                                number.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={"mt-4"} as={Col} md="6" controlId="formCompanyName">
                            <Form.Label className={"create-worker-label"}>Nazwisko</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Adamowicz"
                                className={"input-field"}
                                value={lastname}
                                onChange={(event) => setLastname(event.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a passport
                                number.</Form.Control.Feedback>
                        </Form.Group>
                        <Col xs={12} md={12}></Col>
                        <Form.Group className={"mt-4"} as={Col} md="6" controlId="formPassword">
                            <Form.Label className={"create-worker-label"}>Password</Form.Label>
                            <div className="password-field">
                                <Form.Control
                                    required
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    className={"input-field"}
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                <Form.Check
                                    type="checkbox"
                                    className={'mt-2 show-password-checkbox'}
                                    id="toggleShowPassword"
                                    label={<span className="show-password-label">Show password</span>}
                                    checked={showPassword}
                                    onChange={toggleShowPassword}
                                />
                            </div>
                            <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={"mt-4"} as={Col} md="6" controlId="formConfirmPassword">
                            <Form.Label className={"create-worker-label"}>Confirm password</Form.Label>
                            <div className="password-field">
                                <Form.Control
                                    required
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    className={"input-field"}
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                />
                            </div>
                            <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
                        </Form.Group>
                        <Col xs={12} md={12}></Col>
                        <Form.Group as={Col} md="4" controlId="formCountry" className={"mt-3"}>
                            <Form.Label className={"create-worker-label"}>Rola</Form.Label>
                            {loading && <Spinner animation="border" variant="dark"/>}
                            {!loading && (<Typeahead
                                id="my-typeahead"
                                inputProps={{style: {height: '45px'}}}
                                options={rolesFetched.map((role) => ({
                                    label: role.name.replaceAll("_", " ").replaceAll("ROLE", " ").trim(),
                                    value: role.name,
                                }))}
                                onChange={handleRoleChange}
                            />)}
                            <Form.Control.Feedback type="invalid">Please enter a valid
                                country.</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <button type="submit" className={"dark-btn-company glow-on-hover color mt-5"}>
                        <span>Tworzyć</span>
                    </button>
                    <Link to={"/"} style={{textDecoration: "none"}}>
                        <button className={"button-reject"}>
                            <span className={"grey-btn-company-span"}>Anulować</span>
                        </button>
                    </Link>
                </Form>
            </div>
        </Container>
    </div>);
};

export default withAuth(CreateUser);
