import withAuth from "../../services/hooks/withAuth";
import Container from "react-bootstrap/Container";
import CompaniesSlider from "../../components/companies-slider/CompaniesSlider";
import WorkersSlider from "../../components/companies-slider/WorkersSlider";
import Row from "react-bootstrap/Row";
import Notification from "../../components/notification/Notification";
import {getPaginationFunction} from "../../services/utility";
import React, {useEffect, useMemo, useRef, useState} from "react";
import AuthService from "../../services/auth.service";
import {useParams} from "react-router-dom";
import {
    fetchCompanies,
    fetchMetaData,
    fetchNotificationsByCompany,
    fetchNotificationsByWorkerId,
    fetchWorkersV1
} from "../../services/api-calls";
import Config from "../../config";

const NotificationsPageCombined = () => {
    const currentUser = useMemo(() => AuthService.getCurrentUser(), []);

    const {workerSlug} = useParams();
    const {companySlug} = useParams();

    const [companies, setCompanies] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const PAGE_SIZES = [10, 20, 30, 45];

    const socketRef = useRef(null);

    useEffect(() => {
        fetchCompanies(currentUser).then(companies => setCompanies(companies))
    })

    useEffect(() => {
        setLoading(true)

        if (workerSlug !== undefined) {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
            fetchNotificationsByWorkerId(workerSlug.split('-').pop(), perPage, currentPage, currentUser).then((response => {
                setNotifications(response.data.content);
                setTotalPages(response.data.totalPages);
            }));

        } else if (companySlug !== undefined) {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
            fetchWorkersV1(companySlug.split('-').pop(), currentUser).then(data => setWorkers(data))

            fetchNotificationsByCompany(companySlug.split('-').pop(), perPage, currentPage, currentUser)
                .then((response) => {
                    setNotifications(response.data.content);
                    setTotalPages(response.data.totalPages);
                }).catch(error => {
                console.log(error)
            })

        } else {
            setNotifications([])
            const fetchData = async () => {
                fetchMetaData(perPage, currentPage, currentUser).then(metadata => setTotalPages(metadata));
                if (socketRef.current === null) {
                    socketRef.current = new WebSocket(`${Config.URL.SOCKET}/notification-websocket?page=${currentPage}&size=${perPage}`);

                    socketRef.current.addEventListener("message", (event) => {
                        const notification = JSON.parse(event.data);
                        setNotifications(prevNotifications => [...prevNotifications, notification]);
                    });
                }
            };

            fetchData();
        }

        setLoading(false)
    }, [currentPage, perPage, companySlug, workerSlug]);

    const handlePageChange = (page) => {
        if (currentPage !== page) {
            setCurrentPage(page);
            setNotifications([]);
        }
    };

    const handlePerPageChange = (event) => {
        setPerPage(event.target.value);
        setCurrentPage(1);
        setNotifications([]);
    };

    return (!loading && <Container className={'mb-5'}>
        {companies && <CompaniesSlider companies={companies} navigationLinkBase={"/notifications"}/>}
        {workers && socketRef.current == null &&
            <WorkersSlider workers={workers} companySlug={companySlug} navigationLinkBase={"/notifications"}/>}

        <Row>
            <div>
                {notifications.length === 0 && !loading ? <div className={"notifications-empty-content"}>
                    <p>There's no notifications 🔍</p>
                </div> : <>
                    <div className="notification-page-title">
                        Powiadomienia
                    </div>
                    <div>
                        {notifications.map((notification, index) => (<Notification
                            workerId={notification.workerId}
                            isSeen={notification.usersId.includes(currentUser.id)}
                            notification={notification}
                            key={index}
                        />))}
                    </div>
                </>}

            </div>
        </Row>

        <div className="mt-3">
            {notifications.length !== 0 && !loading && getPaginationFunction(handlePageChange, currentPage, totalPages, perPage, handlePerPageChange, PAGE_SIZES, notifications.length)}
        </div>
    </Container>);

}

export default withAuth(NotificationsPageCombined);