import React, {useRef, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Passport from "../../components/workerComponents/passport/Passport";
import Visa from "../../components/workerComponents/visa/Visa";
import Pesel from "../../components/workerComponents/pesel/Pesel";
import PersonalDetails from "../../components/workerComponents/personalDetails/PersonalDetails";
import Bill from "../../components/workerComponents/bill/Bill";
import Relatives from "../../components/workerComponents/relatives/Relatives";
import Contacts from "../../components/workerComponents/contacts/Contacts";
import Contract from "../../components/workerComponents/contract/Contract";
import WorkerDisplayVacation from "../../components/workerComponents/WorkerDisplayVacation";
import Certificate from "../../components/workerComponents/certificate/Certificate";
import Insurance from "../../components/workerComponents/insurance/Insurance";
import {OverlayFun} from "../../components/Overlay";
import {ReactComponent as Trash} from "../../assets/modal/TrashIcon.svg";
import {ReactComponent as Edit} from "../../assets/svgs/worker-list/Edit.svg";
import {ReactComponent as Copy} from "../../assets/svgs/worker-list/Copy.svg";
import {ReactComponent as Fire} from "../../assets/svgs/FiredWorker.svg";
import {ReactComponent as UnFire} from "../../assets/svgs/Unfire.svg";
import {ReactComponent as AirPlane} from "../../assets/svgs/TransmigrateWorker.svg";
import {formatTimestamp} from "../../services/utility";
import Note from "../../components/forms/notesForm/Notes";
import CompanyWorkerDisplayBlock from "../../components/company/CompanyWorkerDisplayBlock";

const WorkerView = ({
                        worker,
                        certificate,
                        handleFunction,
                        removeHandlerFunction,
                        handleStatusFunction,
                        transmigrateHandlerFunction,
                        duplicateHandlerFunction,
                        isAdmin
                    }) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleHide = () => {
        setTimeout(() => {
            setShow(false);
        }, 380);
    };

    const blockStyle = {
        width: "130px",
        height: "80px",
        background: "#f8f8f8",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transitionDuration: "0.3s",
        cursor: "pointer",
    };

    return (<Row>
        <Col xs={12} md={6} style={{paddingLeft: "65px"}} className={"worker-page"}>
            <div className="titleData">
                <div className={"worker_company_display"}>
                    <CompanyWorkerDisplayBlock company={worker.company} />
                </div>
                <div>
                    {`${worker.personDetails.lastName} ${worker.personDetails.firstName}`}
                    <span className={"no-select"}
                          style={worker.fired ? {backgroundColor: "#ff7070", color: "#fff"} : {}}>
                            {worker.fired ? "Zwolniony" : "Zatrudniony"}
                    </span>
                    {worker.fired && <span className={"no-select"} style={worker.fired ? {
                        backgroundColor: "#111111",
                        color: "#fff",
                        marginLeft: '3px'
                    } : {}}>
                        {worker.firedDate}
                    </span>}
                </div>
                <p>
                    {worker.personDetails.speciality}
                </p>

            </div>
            <div className="personalData ">
                <span className={"worker-subtitle mb-2"}>Dane osobowe</span>
                {worker.passport !== null && <Passport passport={worker.passport}/>}
                {worker.visa !== null && <Visa visa={worker.visa}/>}
                {worker.peselNumber !== null && <Pesel pesel={worker.peselNumber}/>}
                <PersonalDetails personDetails={worker.personDetails}/>
            </div>
            <div className="bills">
                <span className={"worker-subtitle mb-3"}>Rachunki</span>
                {worker.bills.map((bill) => (<Bill bill={bill} key={bill.id}/>))}
            </div>
            <div className="relatives">
                <span className={"worker-subtitle mb-2"}>Rodzice</span>
                <Relatives relatives={worker.personDetails}/>
            </div>
            <div className="contacts">
                <span className={"worker-subtitle mb-2"}>Kontakt</span>
                <Contacts contacts={{phones: worker.phones, email: worker.email, telegram: worker.telegram}}/>
            </div>
        </Col>
        <Col xs={12} md={6} style={{paddingLeft: "30px"}} className={"worker-page-second"}>
            <div>
                {worker.contract !== null && <Contract contract={worker.contract}/>}
            </div>
            <div className="animate__animated animate__fadeIn">
                {worker.contract !== null && worker.contract.contractNotes != null && worker.contract.contractNotes.map((note) => (<Note
                    note={note}
                />))}
            </div>
            <div className="d-none d-md-block">
                <WorkerDisplayVacation vacations={worker.vacations}/>
            </div>

            <div className="animate__animated animate__fadeIn">
                {worker.certificateNotes != null && worker.certificateNotes.map((note) => (<Note
                    note={note}
                />))}
            </div>

            <div className="animate__animated animate__fadeIn">
                {certificate !== null && <Certificate certificate={certificate}/>}
            </div>
            <div className="animate__animated animate__fadeIn">
                {worker.insurance !== null && <Insurance insurance={worker.insurance}/>}
            </div>
            <div className="animate__animated animate__fadeIn">
                {worker.notes != null && worker.notes.map((note) => (<Note
                    note={note}
                />))}
            </div>
        </Col>


        <Col className="mt-5" style={{position: "relative", paddingLeft: "65px", display: "flex", gap: "15px"}}>
            <div style={blockStyle} className="square-with-text" onClick={handleFunction}>
                <div>
                    <Edit/>
                </div>
            </div>
            {!worker.fired &&
                <div style={blockStyle} className="square-with-text" onClick={() => handleStatusFunction(true)}>
                    <div>
                        <Fire/>
                    </div>
                </div>}

            {worker.fired &&
                <div style={blockStyle} className="square-with-text" onClick={() => handleStatusFunction(false)}>
                    <div>
                        <UnFire/>
                    </div>
                </div>}

            {isAdmin && <div style={{...blockStyle}} className="square-with-text"
                                                              onClick={transmigrateHandlerFunction}>
                <div>
                    <AirPlane/>
                </div>
            </div>}

            {worker.fired && isAdmin &&
                <div style={{...blockStyle, width: "90px"}} className="delete-square-with-text"
                     onClick={removeHandlerFunction}>
                    <div>
                        <Trash/>
                    </div>
                </div>}

            <div style={blockStyle} className="square-with-text" onClick={duplicateHandlerFunction}>
                <div>
                    <Copy/>
                </div>
            </div>
            <div style={{cursor: 'text'}} className={"last-updated"}>Last updated
                by {worker.lastUpdatedByUsername} at {formatTimestamp(worker.lastUpdated)}
            </div>
        </Col>


        <OverlayFun ref={ref} show={show} target={target} onHide={handleHide}/>
    </Row>);
};

export default WorkerView;
